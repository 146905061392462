import React from "react";
import { useNavigate } from "react-router-dom";
import "../Login/Login.css";

import CustomButton from "../../Components/Atoms/CustomButton";

import AuthLayout from "../../Components/Layout/AuthLayout";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import { useGetStaticPagesQuery } from "../../services/StaticPages/StaticPageApis";

const TermsAndConditions = ({ showModal, setModal }) => {
  const navigate = useNavigate();

  const { data: staticPage } = useGetStaticPagesQuery("register_terms", {
    refetchOnFocus: true,
  });

  return (
    <CustomDialog
      open={showModal}
      handleClose={() => setModal(false)}
      maxWidth="md"
    >
      <div className="lgnFormContainer regWrapper mx-auto mt-4 staticPageContainer">
        <div className="frmHdr mb-2">
          <h4 className="mb-4">Terms and Conditions</h4>
        </div>
        <div dangerouslySetInnerHTML={{ __html: staticPage?.content }}></div>
        {/* <p>
            Gutbiomics <span>Maximize your Potential</span>
          </p> */}
        <div className="row">
          {/* <div className="col-md-12 mb-4">
            <h5>Introduction</h5>
            <p className="text-muted">
              These Website Standard Terms and Conditions written on this
              webpage shall manage your use of our website, Webiste Name
              accessible at Website.com.
            </p>
            <p className="text-muted">
              These Terms will be applied fully and affect to your use of this
              Website. By using this Website, you agreed to accept all terms and
              conditions written in here. You must not use this Website if you
              disagree with any of these Website Standard Terms and Conditions.
            </p>
            <p className="text-muted">
              Minors or people below 18 years old are not allowed to use this
              Website.
            </p>
          </div>
          <div className="col-md-12 mb-4">
            <h5>Intellectual Property Rights</h5>
            <p className="text-muted">
              Other than the content you own, under these Terms, Company Name
              and/or its licensors own all the intellectual property rights and
              materials contained in this Website.
            </p>
            <p className="text-muted">
              You are granted limited license only for purposes of viewing the
              material contained on this Website.
            </p>
          </div>
          <div className="col-md-12 mb-4">
            <h5>Variation of Terms</h5>
            <p className="text-muted">
              Company Name is permitted to revise these Terms at any time as it
              sees fit, and by using this Website you are expected to review
              these Terms on a regular basis.
            </p>
          </div> */}

          <div className="col-md-12 text-center">
            <CustomButton label="Go Back" onClick={() => setModal(false)} />
          </div>
        </div>
      </div>
    </CustomDialog>
  );
};

export default TermsAndConditions;
