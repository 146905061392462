import React from "react";
import { useNavigate } from "react-router-dom";
import "../../Login/Login.css";

import CustomButton from "../../../Components/Atoms/CustomButton";

import AuthLayout from "../../../Components/Layout/AuthLayout";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import { useGetStaticPagesQuery } from "../../../services/StaticPages/StaticPageApis";

const IntakeReportDetails = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const { data: staticPage } = useGetStaticPagesQuery("intake_report", {
    refetchOnFocus: true,
  });

  return (
    <CustomDialog open={open} handleClose={handleClose} maxWidth="md">
      <div className="lgnFormContainer regWrapper mx-auto mt-4">
        <div dangerouslySetInnerHTML={{ __html: staticPage?.content }}></div>
        <div className="row">
          <div className="col-md-12 text-center">
            <CustomButton label="Go Back" onClick={handleClose} />
          </div>
        </div>
      </div>
    </CustomDialog>
  );
};

export default IntakeReportDetails;
