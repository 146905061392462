import React from "react";
import "./LineBar.css";

export default function LineBar({ data, Graph_value, heading }) {
  const arrowPosition =
    (Graph_value?.value / 10) * 100 - (Graph_value?.value === 0 ? 2 : 4);
  return (
    <>
      <div className="PChartHead">
        <h2>{heading} GOAL</h2>
      </div>

      <div className="Progbar">
        <div className="bar">{/* <h4>Phenotype 1 : DYSREGULATION</h4> */}</div>

        <div className="linebar">
          <div className="red">
            <div className="yellow">
              <div className="green"></div>
            </div>
          </div>
          <div className="arrow" style={{ left: `${arrowPosition}%` }}></div>
        </div>

        <div className="pChartcont">
          <p>{Graph_value?.content ? Graph_value?.content : ""}</p>
        </div>
      </div>
    </>
  );
}
