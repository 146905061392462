import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { appApi } from "../services/api/api";
import authSlice from "../features/auth/authSlice";
import searchSlice from "../features/search/searchSlice";

const reducer = {
  [appApi.reducerPath]: appApi.reducer,
  auth: authSlice,
  search: searchSlice,
};

export const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appApi.middleware),
});

setupListeners(store.dispatch);
