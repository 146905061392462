import React, { useState } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import { Link, useNavigate } from "react-router-dom";
import "../Login/Login.css";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../Components/Layout/AuthLayout";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import { Button } from "@mui/material";
import CustomDialog from "../../Components/Molecules/CustomDialog";

const RegisterSuccess = () => {
  const navigate = useNavigate();
  const [inviteDialog, setInviteDialog] = useState(false);

  const validationArray = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    uname: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    pass: Yup.string().min(2, "Too Short!"),
    rememberme: 0,
  });

  const formik = useFormik({
    initialValues: {
      userType: "sport_person",
      first_name: "",
      last_name: "",
      uname: "",
      pass: "",
      rememberme: "",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      navigate("/twofactor");
    },
  });

  const sportlist = [
    "ARCHERY",
    "ATHLETICS Distance running",
    "ATHLETICS High Jump",
    "ATHLETICS Long Jump",
    "ATHLETICS Long Sprint",
    "ATHLETICS Middle-distance running",
    "ATHLETICS Short sprint",
    "ATHLETICS Triple Jump",
    "ATHLETICS Trows",
    "BASEBALL",
    "BASKETBALL",
    "BIATHLON",
    "BMX",
    "BODYBUILDING",
    "BOXING",
    "CANOEING / KAYAKING",
    "CLIMBING",
    "CRICKET",
    "CROSSFIT",
    "CYCLING 4000 m pursuit",
    "CYCLING CROSS",
    "CYCLING Road Racing",
    "CYCLING Track 200 m",
    "DIVING",
    "EQUESTRIAN",
    "FENCING / SCREENS",
    "FIELD HOCKEY",
    "FIGURE SKATING",
    "FOODBALL American",
    "FOODBALL American Defensive backs, tailbacks",
    "FOODBALL American Inside receivers",
    "FOODBALL American Running backs",
    "FOODBALL American Wide receivers",
    "FOOTBALL American Linebackers",
    "FOOTBALL American Linemen",
    "FOOTBALL American Quarterbacks",
    "GOLF",
    "GYMNASTICS RHYTHMIC",
    "HANDBALL European",
    "ICE HOCKEY",
    "JUDO",
    "KARATE",
    "KAYAKING 1000 M",
    "KAYAKING 10000 M",
    "KAYAKING 500 M",
    "LACROSSE",
    "MARTIAL ARTS",
    "MOUNTAIN BIKE",
    "PADDLE",
    "PENTATHLON",
    "RACKETBALL",
    "RHYTHMIC SPORTIVE GYMNASTICS",
    "ROWING",
    "RUGBY",
    "SAILING",
    "SHOOTING",
    "SKATEBOARDING",
    "SKIING Alpine",
    "SKIING Nodic",
    "SOCCER",
    "SOCCER Forwards",
    "SOCCER Fullbacks",
    "SOCCER Midfielders",
    "SOCCER Sweepers",
    "SOFTBALL / HONKBALL",
    "SPEEDSKATING",
    "SQUSH / HANDBALL",
    "SURF",
    "SWIMMING",
    "SWIMMING SYNCHRONIZED",
    "TABLE TENNIS",
    "TEAKWONDO",
    "TENNIS",
    "TRAMPOLINE",
    "TRIATLON",
    "TRIATLON FULL-IRONMAN",
    "TRIATLON HALF-IRONMAN",
    "TRIATLON MINI-SPRINT",
    "TRIATLON OLYMPIC",
    "TRIATLON SPRINT",
    "VOLLEYBALL",
    "WATER POLO",
    "WEIGHT LIFTING",
    "WRESTLING",
  ];

  return (
    <AuthLayout showButton={true}>
      <div className="lgnFormContainer regWrapper">
        <div className="rgstrnSucs text-center">
          <img src="/assets/svg/tick.svg" className="mb-4" />
          <h4 className="mb-3">Registration Completed Successfully!</h4>
          <p>Please login to proceed!</p>
          <CustomButton
            label="Click to login"
            onClick={() => navigate("/login")}
          />
        </div>
      </div>
    </AuthLayout>
  );
};

export default RegisterSuccess;
