import React from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useInvitePeopleMutation } from "../../services/InvitePeople/InvitePeople";
import { useSelector } from "react-redux";
import CustomDialog from "../../Components/Molecules/CustomDialog";

const InviteForm = ({ inviteDialog, setInviteDialog }) => {
  const { user } = useSelector((state) => state.auth);
  const [invitePeople, { isLoading }] = useInvitePeopleMutation();

  const validationArray = Yup.object().shape({
    name: Yup.string()
      .min(3, "Too short!")
      .required("Required")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces"),

    to_mail: Yup.string()
      .email("Invalid email format")
      .max(250, "Maximum 250 characters")
      .lowercase("*This field cannot contain uppercase!")
      .strict(true)
      .required("Required"),
    person_type: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      to_mail: "",
      person_type: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      try {
        if (!user) return toast.error("Please login before invite.");
        const res = await invitePeople({
          from_mail: user?.email,
          ...values,
        })
          .unwrap()
          .then((res) => {
            if (res) {
              console.log(res);
              actions.resetForm();
              setInviteDialog(false);
              toast.success("Invitation sent successfully.");
            }
          });
        return res;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error?.data?.detail);
        } else {
          toast.error("Something went wrong.");
        }
        console.error(error);
        throw new Error(error);
      }
    },
  });
  return (
    <CustomDialog
      open={inviteDialog}
      handleClose={() => setInviteDialog(false)}
    >
      <div className="text-center">
        <img src="/assets/svg/invite.svg" className="mb-3" />
        <h3 className="mb-2">Invite Dialog</h3>
        <p className="">
          Invite your clients to this website, you can see the list of invited
          people in your profile
        </p>
        <form className="mt-5" onSubmit={formik.handleSubmit}>
          <div className="row text-start">
            <div className="col-md-12">
              <CustomInput
                label="Name"
                placeholder="Enter name"
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Email"
                placeholder="Enter email"
                type="text"
                name="to_mail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.to_mail}
                formik={formik}
              />
            </div>
            <div className="col-md-6">
              {user?.account_type == "doctor" ? (
                <div className="customInputWrpr">
                  <CustomSelect
                    label="Person Type"
                    name="person_type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.person_type}
                    formik={formik}
                    options={[
                      {
                        value: "sport_person",
                        name: "Sport Person/Patient",
                      },
                      {
                        value: "professional",
                        name: "Professional",
                      },
                      {
                        value: "team_manager",
                        name: "Team Manager",
                      },
                      {
                        value: "doctor",
                        name: "Doctor",
                      },
                    ]}
                  />
                </div>
              ) : (
                <div className="customInputWrpr">
                  <CustomSelect
                    label="Person Type"
                    name="person_type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.person_type}
                    formik={formik}
                    options={[
                      {
                        value: "sport_person",
                        name: "Sport Person",
                      },
                      {
                        value: "professional",
                        name: "Professional",
                      },
                      {
                        value: "team_manager",
                        name: "Team Manager",
                      },
                      {
                        value: "doctor",
                        name: "Doctor",
                      },
                    ]}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6">
              <CustomButton
                label={isLoading ? "Inviting..." : "Send Invite"}
                type="submit"
                disabled={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default InviteForm;
