import * as React from "react";
import Drawer from "@mui/material/Drawer";
import "./product.css";
import "swiper/css";
import { CircularProgress, Divider, IconButton } from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";
import {
  useAddItemToCartMutation,
  useGetCartListQuery,
  useGetProductByIdQuery,
} from "../../services/products/ProductsApi";
import { baseUrl } from "../../services/api/api";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";
import { useSelector } from "react-redux";

export default function ViewProduct({ showProduct, setShowProduct, id }) {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [swiperInstance, setSwiperInstance] = React.useState(null);
  const { user } = useSelector((state) => state.auth);

  const {
    data: product,
    isLoading,
    isFetching,
  } = useGetProductByIdQuery(id, { refetchOnFocus: true });

  const { data: cart } = useGetCartListQuery();

  const navigate = useNavigate();

  const [count, setCount] = React.useState(1);
  const handleIncrement = () => setCount(count + 1);
  const handleDecrement = () => !count <= 1 && setCount(count - 1);

  const handleClose = () => {
    setShowProduct((draft) => {
      return {
        ...draft,
        view: false,
      };
    });
  };

  const isCartItem = React.useMemo(() => {
    return !!cart?.filter((el) => el.product.id === id && el)?.length
      ? true
      : false;
  }, [cart]);

  const [addItemToCart, { isLoading: isAddingProduct }] =
    useAddItemToCartMutation();

  const handleAddItemToCart = async () => {
    try {
      await addItemToCart({
        product: id,
        quantity: count,
        user: user.id,
      }).unwrap();
      navigate("/products/my-cart");
    } catch (error) {
      console.log(error);
      if (error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("Failed to add product!");
      }
    }
  };

  const handleBuyNow = () => {
    !isCartItem ? handleAddItemToCart() : navigate("/products/my-cart");
    handleClose();
  };

  if (isFetching || isLoading)
    return (
      <div className="ldngWrpr">
        <CircularProgress />
      </div>
    );

  return (
    <Drawer
      anchor={"right"}
      open={showProduct}
      onClose={handleClose}
      className="product_view_modal"
    >
      <div className="product_dtl_wrapper">
        <div className="product_dtl_header">
          <h5 className="mb-0">{product?.product_name}</h5>
          <IconButton onClick={handleClose}>
            <span className="material-symbols-rounded">clear</span>
          </IconButton>
        </div>
        <Swiper
          className="mySwiper"
          onSwiper={(swiper) => setSwiperInstance(swiper)}
          onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
          grabCursor={true}
          // breakpoints={{
          //   640: {
          //     slidesPerView: 2,
          //     spaceBetween: 10,
          //   },
          // }}
        >
          {!!product.productimages.length &&
            product.productimages.map((el, index) => (
              <SwiperSlide
                key={index}
                virtualIndex={index}
                className="product_previewer"
              >
                <img src={el?.product_image} alt={el} />
              </SwiperSlide>
            ))}
        </Swiper>

        <div className="product_que_imgs">
          {!!product.productimages.length &&
            product.productimages.map((el, idx) => (
              <img
                key={idx}
                src={el?.product_image}
                alt={`que_img-${idx}`}
                className={activeSlide == idx ? "active" : ""}
                onClick={() => {
                  !!swiperInstance && swiperInstance.slideTo(idx, 150, false);
                }}
              />
            ))}
        </div>
        <Divider className="my-4" />
        {/* <div className="">
          <h6 className="mb-2">Price</h6>
          <h4 className="colorPrimary">
            {product?.price !== ""
              ? `${currencyFomatter(product?.price)}`
              : "Not Given"}
          </h4>
        </div> */}
        <div className="product_description mt-3">
          <h5 className="mb-3">Description</h5>
          <div
            className="pdtDscVwRpr"
            dangerouslySetInnerHTML={{ __html: product?.description }}
          ></div>

          <div className="product_footer">
            {/* {!isCartItem && (
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <h6 className="mb-2">Quantity</h6>
                  <h6 className="mb-2 avblQty">
                    Avbl: <span>{product?.available_qty}</span>
                  </h6>
                </div>
                <div className="add_cart">
                  <CustomButton
                    label={
                      <span className="material-symbols-rounded">remove</span>
                    }
                    btnType="secondary"
                    onClick={handleDecrement}
                    disabled={count <= 1}
                  />
                  <h6 className="mb-0">{count}</h6>
                  <CustomButton
                    label={
                      <span className="material-symbols-rounded">add</span>
                    }
                    btnType="secondary"
                    onClick={handleIncrement}
                    disabled={count >= product?.available_qty}
                  />
                </div>
              </div>
            )} */}

            {/* <div className="ms-auto">
              <h6 className="mb-2">Price</h6>
              <h4 className="">
                {product?.price !== ""
                  ? `${currencyFomatter(product?.price)}`
                  : "Not Given"}
              </h4>
            </div> */}
          </div>
        </div>
        {/* <div className="mt-4">
          <CustomButton
            disabled={isAddingProduct}
            color="secondary"
            label={!!isCartItem ? "Go to cart" : "Buy Now"}
            onClick={handleBuyNow}
          />
        </div> */}
      </div>
    </Drawer>
  );
}
