import { appApi } from "../api/api";

export const reportsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getGutbiomicsReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/gutbiomics_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Gutbiomics"],
    }),

    getMicrobiomeReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/microbiome_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Microbiome"],
    }),

    getToleranceReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/tolerence_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Tolerance"],
    }),

    getKpiReportReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/kpi_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["KpiReport"],
    }),

    getInnerHacksReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/innerhack_search/${id}/`,
        method: "POST",
        body: values,
      }),
      providesTags: ["InnerHacksReport"],
    }),

    getUserUploadedReports: builder.query({
      query: (id) => `/api/user_report_index/${id}`,
      providesTags: ["UploadedReports"],
    }),
    deleteUserUploadedreport: builder.mutation({
      query: (id) => ({
        url: `api/user_report_delete/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["UploadedReports"],
    }),
    deleteUserReportRequest: builder.mutation({
      query: ({ id, ...values }) => ({
        url: `api/report_delete_requests/${id}/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UploadedReports"],
    }),
    searchUploadedReports: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/user_report_search/${id}`,
        method: "POST",
        body: values,
      }),
      providesTags: ["UploadedReports"],
    }),
    // user_report_search/1

    uploadUserReport: builder.mutation({
      query: (values) => ({
        url: `/api/user_report/${values.get("userId")}`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UploadedReports"],
    }),

    getLastGutReport: builder.query({
      query: (id) => `/api/gut_recent/${id}/`,
      providesTags: ["Gutbiomics"],
    }),
    getLastKpiReport: builder.query({
      query: (id) => `/api/kpi_recent/${id}/`,
      providesTags: ["KpiReport"],
    }),
    getLastInnerhackReport: builder.query({
      query: (id) => `/api/innerhack_recent/${id}/`,
      providesTags: ["InnerHacksReport"],
    }),

    getGutbiomicsIndex: builder.query({
      query: (id) => `/api/gut_report_archives/${id}`,
      providesTags: ["Gutbiomics"],
    }),
    getMicrobiomeIndex: builder.query({
      query: (id) => `/api/microbiome_report/${id}`,
      providesTags: ["Microbiome"],
    }),
    getToleranceIndex: builder.query({
      query: (id) => `/api/tolerance_report/${id}`,
      providesTags: ["Tolerance"],
    }),
    getKpiIndex: builder.query({
      query: (id) => `/api/kpi_report/${id}`,
      providesTags: ["KpiReport"],
    }),
    getInnerhackIndex: builder.query({
      query: (id) => `/api/innerhack_reports/${id}`,
      providesTags: ["InnerHacksReport"],
    }),

    getReportById: builder.query({
      query: (id) => `/api/admin/gutbiomics_reports/${id}/show`,
      providesTags: (result, error, arg) => [{ type: "Reports", id: arg }],
    }),
  }),
});

export const {
  useGetGutbiomicsReportsQuery,
  useGetMicrobiomeReportsQuery,
  useGetToleranceReportsQuery,
  useGetKpiReportReportsQuery,
  useGetUserUploadedReportsQuery,
  useUploadUserReportMutation,
  useGetInnerHacksReportsQuery,
  useLazyGetGutbiomicsReportsQuery,
  useLazyGetMicrobiomeReportsQuery,
  useLazyGetToleranceReportsQuery,
  useLazyGetKpiReportReportsQuery,
  useLazyGetInnerHacksReportsQuery,
  useGetLastGutReportQuery,
  useGetLastKpiReportQuery,
  useGetLastInnerhackReportQuery,
  useGetGutbiomicsIndexQuery,
  useGetInnerhackIndexQuery,
  useGetKpiIndexQuery,
  useGetMicrobiomeIndexQuery,
  useGetToleranceIndexQuery,
  useLazySearchUploadedReportsQuery,
  useDeleteUserUploadedreportMutation,
  useDeleteUserReportRequestMutation,
  useLazyGetReportByIdQuery
} = reportsApi;
