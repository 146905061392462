import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetUserProductsQuery } from "../../services/products/ProductsApi";
import { useSelector } from "react-redux";
import "./ProfileDetails.css";
import MultiLayout from "../../Components/Layout/MultiLayout";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import ViewOrder from "./ViewOrder";
import {
  Button,
  Collapse,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  ShoppingCart,
} from "@mui/icons-material";
import { StyledMenu } from "../../Utils/UiLayers";
import ProductSingleOrder from "../Products/ProductSingleOrder";
import ViewProduct from "../Products/ViewProduct";

const ProductsPurchased = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const { data: products, isLoading } = useGetUserProductsQuery(user?.id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
  });

  const [showProduct, setShowProduct] = useState({
    view: false,
    id: null,
  });

  const handlePopupClose = () => setAddPopup({ mount: false, data: null });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [singleOrder, setSingleOrder] = useState(false);

  const [tableIndex, setTableIndex] = React.useState([]);

  const handleTableIndex = (index) => {
    const isExistsIndex = tableIndex.findIndex((el) => el === index);
    return isExistsIndex >= 0
      ? setTableIndex((pre) => [...pre.filter((el) => el !== index)])
      : setTableIndex((pre) => [...pre, index]);
  };

  const isActiveRow = (index) =>
    tableIndex.findIndex((el) => el === index) >= 0 ? true : false;

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-3">
              <div className="d-flex align-items-center">
                <img src="/assets/svg/products.svg" className="me-2" />
                <h5 className="mb-0">Purchased Products</h5>
              </div>
              {user?.account_type !== "sport_person" &&
                !window.location.pathname.includes("user/purchased") && (
                  <>
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      startIcon={<ShoppingCart />}
                      endIcon={<KeyboardArrowDown />}
                      className="text-light"
                    >
                      Order Products
                    </Button>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          setSingleOrder(true);
                          handleClose();
                        }}
                        disableRipple
                      >
                        Single Order
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          navigate("/products/bulkorder");
                          handleClose();
                        }}
                        disableRipple
                      >
                        Bulk Order
                      </MenuItem>
                    </StyledMenu>
                  </>
                )}
            </div>

            <hr />
          </div>
          {user?.account_type === "sport_person" ? (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Product Image</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Order Price</th>
                    <th>Payment Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!!products?.length ? (
                    products.map((el, idx) => (
                      <>
                        {!!el.orderitems?.length
                          ? el.orderitems.map((data, index) => (
                              <tr key={index}>
                                {index === 0 ? (
                                  <td rowspan={el.orderitems?.length}>
                                    {el.id || "-"}
                                  </td>
                                ) : (
                                  ""
                                )}
                                <td>
                                  <img
                                    style={{
                                      width: "80px",
                                      height: "80px",
                                      objectFit: "contain",
                                    }}
                                    src={
                                      data?.product?.productimages[0]
                                        ?.product_image
                                    }
                                  />
                                </td>
                                <td>{data?.product?.product_name || "-"}</td>
                                <td>{currencyFomatter(data?.price || 0)}</td>
                                <td>{data?.quantity || "-"}</td>
                                {index === 0 ? (
                                  <>
                                    <td
                                      style={{ paddingLeft: "20px" }}
                                      rowspan={el.orderitems?.length}
                                    >
                                      {currencyFomatter(el?.total_price || 0)}
                                    </td>
                                    <td rowspan={el.orderitems?.length}>
                                      <span
                                        className={`${el?.payment_status} pymtStaus`}
                                      >
                                        {el?.payment_status}
                                      </span>
                                    </td>
                                    <td rowspan={el.orderitems?.length}>
                                      <div className="d-flex align-items-center">
                                        <CustomButton
                                          label="View Invoice"
                                          onClick={() =>
                                            setAddPopup({
                                              mount: true,
                                              data: el,
                                            })
                                          }
                                        />
                                        {el?.payment_status === "unpaid" &&
                                          el.user === user?.id && (
                                            <CustomButton
                                              className="ms-2"
                                              label="Pay Now"
                                              onClick={() =>
                                                navigate(
                                                  `/payment/${el.ref_no}`
                                                )
                                              }
                                            />
                                          )}
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  ""
                                )}
                              </tr>
                            ))
                          : ""}
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8} className="text-center text-muted">
                        {isLoading ? "Fetching Records" : "No records found!"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="table-responsive">
              <table className="table flwKitTbl">
                <thead>
                  <tr>
                    <th></th>
                    <th>S.No</th>
                    <th>Order ID</th>
                    <th>Ordered By</th>
                    <th>Ordered Type</th>
                    <th>Group Name</th>
                    <th>Payment Status</th>
                  </tr>
                </thead>
                <tbody>
                  {!!products?.length ? (
                    products.map((data, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className={`${isActiveRow(index) && "bg-light"}`}
                          style={{ verticalAlign: "center" }}
                        >
                          <td>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleTableIndex(index)}
                            >
                              {isActiveRow(index) ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>
                          </td>
                          <td>{index + 1}</td>
                          <td>{data.order_group_id}</td>
                          <td>{data?.ordered_by}</td>
                          <td className="text-capitalize">
                            {data?.group_name ? "bulk" : "single"}
                          </td>
                          <td className="text-capitalize">
                            {data?.group_name || "-"}
                          </td>
                          <td>
                            <div className="d-flex align-items-center gap-3">
                              <span
                                className={`status ${
                                  data.order_details[0].payment_status ==
                                  "unpaid"
                                    ? "pending"
                                    : "accepted"
                                }`}
                              >
                                {data.order_details[0].payment_status}
                              </span>
                              {data.order_details[0].payment_status ===
                                "unpaid" &&
                                user?.email === data?.ordered_by && (
                                  <Tooltip title="Pay now">
                                    <IconButton
                                      color="primary"
                                      onClick={() =>
                                        navigate(
                                          `/payment/bulk/${data.order_group_id}`
                                        )
                                      }
                                    >
                                      <span className="material-symbols-rounded">
                                        payments
                                      </span>
                                    </IconButton>
                                  </Tooltip>
                                )}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={7} className={`border-bottom-0 p-0`}>
                            <Collapse
                              in={isActiveRow(index)}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                                marginTop={2}
                              >
                                Order Information
                              </Typography>
                              <div className="table-responsive">
                                <table className="table flwKitTbl">
                                  <thead>
                                    <tr>
                                      <td>Client Id</td>
                                      <td>Product Image</td>
                                      <td>Product Name</td>
                                      <td>Price</td>
                                      <td>Qty</td>
                                      <td>Amount</td>
                                      <td>Invoice</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {data.order_details?.map(
                                      (order, orderIdx) =>
                                        order.orderitems?.map((item, imIdx) => (
                                          <tr key={imIdx}>
                                            {imIdx === 0 && (
                                              <td
                                                rowSpan={
                                                  order.orderitems?.length
                                                }
                                              >
                                                <Link
                                                  to={
                                                    order?.receiver_user_acc_subtype ===
                                                    "patient"
                                                      ? `/patients/view/${order?.receiver_user_id}`
                                                      : `/sportpersons/view/${order?.receiver_user_id}`
                                                  }
                                                >
                                                  {order?.receiver_user}
                                                </Link>
                                              </td>
                                            )}
                                            <td>
                                              <img
                                                style={{
                                                  width: "80px",
                                                  height: "80px",
                                                  objectFit: "contain",
                                                }}
                                                src={
                                                  item.product?.productimages[0]
                                                    ?.product_image
                                                }
                                              />
                                            </td>
                                            <td>
                                              {item?.product?.product_name ||
                                                "-"}
                                            </td>
                                            <td>
                                              {currencyFomatter(
                                                item?.price || 0
                                              )}
                                            </td>
                                            <td>{item?.quantity || "-"}</td>
                                            <td>
                                              {currencyFomatter(
                                                order?.total_price || 0
                                              )}
                                            </td>
                                            {imIdx === 0 && (
                                              <td
                                                rowSpan={
                                                  order.orderitems?.length
                                                }
                                              >
                                                <Tooltip title="View Invoice">
                                                  <IconButton
                                                    onClick={() =>
                                                      setAddPopup({
                                                        mount: true,
                                                        data: order,
                                                      })
                                                    }
                                                    color={"secondary"}
                                                  >
                                                    <span className="material-symbols-rounded">
                                                      summarize
                                                    </span>
                                                  </IconButton>
                                                </Tooltip>
                                              </td>
                                            )}
                                          </tr>
                                        ))
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Collapse>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td className="text-center" colSpan={7}>
                        {isLoading ? "Fetching Records" : "No records found!"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
          {/* <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Product Image</th>
                  <th>Product Name</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Order Price</th>
                  <th>Payment Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!!products?.length ? (
                  products.map((el, idx) => (
                    <>
                      {!!el.orderitems?.length
                        ? el.orderitems.map((data, index) => (
                            <tr key={index}>
                              {index === 0 ? (
                                <td rowspan={el.orderitems?.length}>
                                  {el.id || "-"}
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                <img
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "contain",
                                  }}
                                  src={
                                    data?.product?.productimages[0]
                                      ?.product_image
                                  }
                                />
                              </td>
                              <td>{data?.product?.product_name || "-"}</td>
                              <td>{currencyFomatter(data?.price || 0)}</td>
                              <td>{data?.quantity || "-"}</td>
                              {index === 0 ? (
                                <>
                                  <td
                                    style={{ paddingLeft: "20px" }}
                                    rowspan={el.orderitems?.length}
                                  >
                                    {currencyFomatter(el?.total_price || 0)}
                                  </td>
                                  <td rowspan={el.orderitems?.length}>
                                    <span
                                      className={`${el?.payment_status} pymtStaus`}
                                    >
                                      {el?.payment_status}
                                    </span>
                                  </td>
                                  <td rowspan={el.orderitems?.length}>
                                    <div className="d-flex align-items-center">
                                      <CustomButton
                                        label="View Invoice"
                                        onClick={() =>
                                          setAddPopup({
                                            mount: true,
                                            data: el,
                                          })
                                        }
                                      />
                                      {el?.payment_status === "unpaid" &&
                                        el.user === user?.id && (
                                          <CustomButton
                                            className="ms-2"
                                            label="Pay Now"
                                            onClick={() =>
                                              navigate(`/payment/${el.ref_no}`)
                                            }
                                          />
                                        )}
                                    </div>
                                  </td>
                                </>
                              ) : (
                                ""
                              )}
                            </tr>
                          ))
                        : ""}
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center text-muted">
                      {isLoading ? "Fetching Records" : "No records found!"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div> */}

          {addPopup.mount && (
            <CustomDialog
              avoidBG
              open={addPopup.mount}
              handleClose={handlePopupClose}
            >
              <ViewOrder {...addPopup} handleClose={handlePopupClose} />
            </CustomDialog>
          )}
          {singleOrder && (
            <ProductSingleOrder
              mount={singleOrder}
              setDialog={setSingleOrder}
              setShowProduct={setShowProduct}
            />
          )}

          {showProduct.view && (
            <ViewProduct
              showProduct={showProduct.view}
              setShowProduct={setShowProduct}
              id={showProduct.id}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsPurchased;
