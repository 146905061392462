import { appApi } from "../api/api";

export const followKitApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getFollowKits: builder.query({
      query: (id) => `/api/follow_up_kit/${id}`,
      providesTags: ["FollowKits"],
    }),

    //team manager index
    getTeamFollowKits: builder.query({
      query: (id) => `/api/team_manager_kit_index/${id}`,
      providesTags: ["FollowKits"],
    }),

    //Bulk order
    bulkFollowKitRequest: builder.mutation({
      query: (values) => ({
        url: `/api/bulk_upload/${values.id}`,
        method: "POST",
        body: values.requested_bulk_Kit,
      }),
      invalidatesTags: ["Follow_Cart"],
    }),

    //followkit individual request
    followKitRequest: builder.mutation({
      query: (values) => ({
        url: `/api/req_followupkit/${values.id}`,
        method: "POST",
        body: values.requested_Kit,
      }),
      invalidatesTags: ["Follow_Cart"],
    }),

    //followkit submission
    followKitSubmit: builder.mutation({
      query: (values) => ({
        url: `/api/submit_followupkit`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["FollowKits"],
    }),

    //followkit report index
    getFollowkitReports: builder.query({
      query: (id) => `/api/follow_up_kit_report_index/${id}`,
      providesTags: ["FollowKits"],
    }),

    //reports and its prices
    getFollowKitPricings: builder.query({
      query: () => `/api/admin/pricings`,
      providesTags: ["FollowKits"],
    }),

    //cart and checkout
    getFollowCartList: builder.query({
      query: () => `api/fkitcarts`,
      providesTags: ["Follow_Cart"],
    }),
    deleteFollowCartList: builder.mutation({
      query: (id) => ({
        url: `/api/fkitcarts/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Follow_Cart"],
    }),
    createFollowOrder: builder.mutation({
      query: (values) => ({
        url: `/api/fkitorders/${values.id}/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Follow_Cart", "FollowKits"],
    }),

    createBulkFollowOrder: builder.mutation({
      query: (values) => ({
        url: `/api/fkitorders/${values.id}/bulk_new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Follow_Cart", "FollowKits"],
    }),

    viewFollowKitById: builder.query({
      query: (id) => `/api/fkitorders/${id}/show`,
    }),

    // getUserOrderById: builder.query({
    //   query: (id) => `/api/orders/${id}`,
    //   providesTags: ["user"],
    // }),

    // getUsersOrderedProducts: builder.query({
    //   query: (id) => `/api/users/${id}/order_products`,
    //   providesTags: (result, error, arg) => [{ type: "Products", id: arg }],
    // }),
  }),
});

export const {
  useGetFollowKitsQuery,
  useBulkFollowKitRequestMutation,
  useFollowKitRequestMutation,
  useLazyGetTeamFollowKitsQuery,
  useFollowKitSubmitMutation,
  useGetFollowkitReportsQuery,
  useGetFollowKitPricingsQuery,
  useGetFollowCartListQuery,
  useCreateFollowOrderMutation,
  useViewFollowKitByIdQuery,
  useDeleteFollowCartListMutation,
  useCreateBulkFollowOrderMutation,
} = followKitApi;
