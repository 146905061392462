import React, { useMemo, useState, useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import { Badge, Button, Pagination, TablePagination } from "@mui/material";
import {
  useGetInvitationsQuery,
  useLazyGetInvitationsBySearchQuery,
} from "../../services/InvitePeople/InvitePeople";
import InviteForm from "./InviteForm";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { addSearchValue } from "../../features/search/searchSlice";
import { toast } from "react-hot-toast";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Tune } from "@mui/icons-material";
import { handleSort } from "../../Utils/Utils";
import InviteSearchModal from "./InviteSearchModal";

const Invite = () => {
  const [inviteDialog, setInviteDialog] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Reports list with filters

  const [getInvitations, { data: list, isFetching, isLoading, isError }] =
    useLazyGetInvitationsBySearchQuery();

  const [filterModal, setFilterModal] = useState(false);
  const previousFilter = useSelector((state) => state.search.invitaions_search);

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        invite_status: "",
      },
      list_per_page: 10,
      page: 1,
      sort: "",
    },
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ values, name: "invitaions_search" }));
      try {
        const query = await getInvitations({ ...values, id: user?.id })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    getInvitations(
      previousFilter
        ? { ...previousFilter, id: user?.id }
        : { ...searchFormik.values, id: user?.id }
    );
  }, [previousFilter]);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.invite_status && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;
    return count;
  }, [searchFormik.values]);

  const tableHeads = [
    { name: "S.No", sortable: false },
    {
      name: "Name",
      key: "name",
      sortable: true,
    },
    {
      name: "Email",
      key: "to_mail",
      sortable: true,
    },
    { name: "User Role", key: "person_type", sortable: true },
    {
      name: "Invite Sent on",
      key: "invite_sent_date",
      sortable: true,
    },
    { name: "Joined on", key: "invite_accepted_date", sortable: true },
    { name: "Status", key: "invite_status", sortable: true },
    ,
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 pb-4">
            <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
              <h5 className="d-flex align-items-center">
                <img src="/assets/svg/invitegray.svg" className="me-2" />
                Invite People
              </h5>
              <CustomSearch
                inputName="search"
                query={searchFormik.values.search}
                handleChange={searchFormik.handleChange}
                handleSubmit={(e) => {
                  e.preventDefault();
                  searchFormik.handleSubmit();
                  searchFormik.setFieldValue("page", 1);
                }}
                containerClasses="ms-md-auto small"
              />

              <Badge badgeContent={handelBageContent} color="secondary">
                <CustomButton
                  size="small"
                  onClick={() => setFilterModal(true)}
                  label={
                    <>
                      <Tune className="me-2" />
                      Filter
                    </>
                  }
                />
              </Badge>
              <Button className="brdrBtn" onClick={() => setInviteDialog(true)}>
                <span className="material-symbols-rounded">group_add</span>
                Invite
              </Button>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {tableHeads.map(({ sortable, key, name, hidden }, idx) => (
                      <th key={idx}>
                        <div
                          className={`${
                            hidden ? "d-none" : "d-flex "
                          } align-items-center ${sortable ? "sortable" : ""} ${
                            searchFormik.values.sort.includes(key)
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            if (sortable && !isLoading && !isFetching) {
                              return handleSort(searchFormik, key || "");
                            }
                          }}
                        >
                          {name}
                          {sortable && (
                            <span className="material-symbols-rounded">
                              {searchFormik.values.sort.includes(key)
                                ? searchFormik.values.sort.includes(`-${key}`)
                                  ? "expand_more"
                                  : "expand_less"
                                : "unfold_more"}
                            </span>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {!!list?.report?.length &&
                    list.report?.map((data, index) => (
                      <tr key={data?.id || index}>
                        <td>{index + 1}</td>
                        <td>{data?.name || "-"}</td>
                        <td>{data?.to_mail}</td>
                        <td className="text-capitalize">
                          {user?.account_type == "doctor" &&
                          data?.person_type == "sport person"
                            ? "Sport Person / Patient"
                            : data?.person_type == "team manager"
                            ? "Team Manager"
                            : data?.person_type || "-"}
                        </td>
                        <td>{data?.invite_sent_date || "-"}</td>
                        <td>{data?.invite_accepted_date || "-"}</td>
                        <td>
                          <span
                            className={`${
                              data?.status || data?.invite_status == "Accept"
                                ? "accepted"
                                : data?.invite_status == "Closed"
                                ? "rejected"
                                : "pending"
                            }`}
                          >
                            {console.log(
                              "data?.invite_status",
                              data?.invite_status
                            )}
                            {data?.status || data?.invite_status == "Accept"
                              ? "Accepted"
                              : data?.invite_status == "Closed"
                              ? "Rejected"
                              : "Pending"}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="tblFtrActs">
              <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                {isLoading
                  ? "Fetching records.."
                  : isError && !list?.report?.length
                  ? "Failed to load records!"
                  : !list?.report?.length && "No Records Found!"}
              </p>
              <div>
                <TablePagination
                  component="div"
                  count={list?.count || 0}
                  page={searchFormik.values.page - 1}
                  onPageChange={handleChangePage}
                  rowsPerPage={searchFormik.values.list_per_page}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <InviteForm
        inviteDialog={inviteDialog}
        setInviteDialog={setInviteDialog}
      />
      {filterModal && (
        <InviteSearchModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default Invite;
