import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PdfViewer from "../../Components/Molecules/PdfViewer";
import { useGetToleranceIndexQuery } from "../../services/reports/reportsApi";
import { urlBuilder } from "../../Utils/Utils";

const SpToleranceReport = () => {
  const { id } = useParams();
  const [activeToleReport, setActiveToleReport] = useState(0);

  const { data: Tolerance_report } = useGetToleranceIndexQuery(id, {
    refetchOnFocus: true,
  });

  return (
    <div>
      <div className="rprtVwScrn">
        <div className="rprtVwPrt">
          <PdfViewer
            fileUrl={urlBuilder(Tolerance_report?.[activeToleReport]?.report)}
            reportOnly={true}
          />
        </div>
        <div className="archVwPrt">
          <h6>ARCHIVES</h6>
          <ul>
            {!!Tolerance_report?.length ? (
              Tolerance_report.map((data, index) => (
                <li
                  className={activeToleReport === index ? "active" : ""}
                  key={index}
                  onClick={() => setActiveToleReport(index)}
                >
                  <label>{data.report_name}</label>
                  <p>{data.date}</p>
                </li>
              ))
            ) : (
              <li>
                <label>{"No Records Found"}</label>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SpToleranceReport;
