import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./MeterGraph.css";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

function MeterGraph({ heading, Graph_value }) {
  const gaugeNeedle = {
    id: "gaugeNeedle",
    afterDatasetsDraw(chart, args, plugins) {
      const { ctx, data } = chart;
      ctx.save();
      const xCenter = chart.getDatasetMeta(0).data[0].x;
      const yCenter = chart.getDatasetMeta(0).data[0].y;

      const outerRadius = chart.getDatasetMeta(0).data[0].outerRadius;
      const innerRadius = chart.getDatasetMeta(0).data[0].innerRadius;
      const widthSlice = (outerRadius - innerRadius) / 2;
      const radius = 10;
      const angle = Math.PI / 180;

      const needleValue = data.datasets[0].needleValue;
      const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);
      const circumference =
        (chart.getDatasetMeta(0).data[0].circumference /
          Math.PI /
          data.datasets[0].data[0]) *
        needleValue;

      ctx.translate(xCenter, yCenter);
      ctx.rotate(Math.PI * (circumference + 1.5));

      ctx.beginPath();
      ctx.strokeStyle = "black";
      ctx.fillStyle = "grey";
      ctx.lineWidth = 1;

      ctx.moveTo(0 - radius, 0);
      ctx.lineTo(0, 0 - innerRadius - widthSlice);
      ctx.lineTo(0 + radius, 0);
      ctx.closePath();
      ctx.stroke();
      ctx.fill();

      ctx.beginPath();
      ctx.arc(0, 0, radius, 0, angle * 360, false);

      ctx.fill();

      ctx.restore();
    },
  };

  const gaugeLabels = {
    id: "gaugeLabels",
    afterDatasetsDraw(chart, args, plugins) {
      const {
        ctx,
        chartArea: { left, right, top },
      } = chart;
      const yCenter = chart.getDatasetMeta(0).data[0].y;

      ctx.font = "bold 20px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "left";
      ctx.fillText("Very Poor", left, yCenter + 20);

      ctx.font = "bold 20px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "center"; // Adjust the text alignment as needed
      ctx.fillText("Fair", (left + right) / 2, top + 50);

      ctx.font = "bold 20px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "right";
      ctx.fillText("Excellent", right, yCenter + 20);
    },
  };
  const data = {
    datasets: [
      {
        data: [2, 1.5, 1.5, 1.5, 1.5, 2],
        backgroundColor: [
          "#FF5733",
          "#FF5733",
          "#fde74c",
          "#fde74c",
          "#81B622",
          "#81B622",
        ],
        circumference: 180,
        rotation: 270,
        needleValue: Graph_value?.value,
        cutout: "60%",
      },
    ],
  };
  const options = {
    plugins: {
      tooltip: {
        enabled: false,
      },
    },
  };

  return (
    <>
      <div className="MChartHead">
        <h2>{heading} RESULT</h2>
      </div>
      <div className="meterChart">
        <div className="chart">
          <Doughnut
            data={data}
            options={options}
            plugins={[gaugeNeedle, gaugeLabels]}
          ></Doughnut>
        </div>
        <div className="meterChartTitle">
          {/* <h4>Phenotype 1 : DYSREGULATION</h4> */}
        </div>
        <div className="meterChartcont">
          <p>{Graph_value?.content}</p>
        </div>
      </div>
    </>
  );
}

export default MeterGraph;
