import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomButton from "../../../Components/Atoms/CustomButton";
import {
  useGetLastGutReportQuery,
  useLazyGetGutbiomicsReportsQuery,
} from "../../../services/reports/reportsApi";
import { baseUrl } from "../../../services/api/api";
import { useDispatch, useSelector } from "react-redux";
import PdfViewer, {
  PdfViewerFSModal,
} from "../../../Components/Molecules/PdfViewer";
import { Badge, Pagination, TablePagination } from "@mui/material";
import SearchReportModal, { reportSearchSchema } from "./SearchReportModal";
import { Tune } from "@mui/icons-material";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import ReportDialog from "../../../Components/Organisms/ReportDialog";
import ViewGutReport from "./ViewGutReport";
import { handleSort, urlBuilder } from "../../../Utils/Utils";
import { addSearchValue } from "../../../features/search/searchSlice";
import MultiLineBarGraph from "../../../Components/Molecules/Graph/MultiLineBarGraph/MultiLineGraph";
import MeterGraph from "../../../Components/Molecules/Graph/MeterGraph/MeterGraph";
import LineBar from "../../../Components/Molecules/Graph/LineBarGraph/LineBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const GutBiomicsReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { user } = useSelector((state) => state.auth);

  const { data: lastReport } = useGetLastGutReportQuery(user?.id, {
    skip: !user?.id,
    refetchOnFocus: true,
  });

  // Reports list with filters

  const [getReports, { data: list, isError, isLoading, isFetching }] =
    useLazyGetGutbiomicsReportsQuery();

  const [filterModal, setFilterModal] = useState(false);
  const previousFilter = useSelector(
    (state) => state.search.gutbiomics_report_search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
    },
    validationSchema: reportSearchSchema,
    onSubmit: async (values, actions) => {
      if (isLoading) return;
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: "gutbiomics_report_search", values }));

      try {
        const query = await getReports({ id: user?.id, ...values })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getReports({ id: user?.id, ...previousFilter });
      } else {
        await getReports({ id: user?.id, ...searchFormik.values });
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.date1 && count++;
    searchFormik.values.filter.date2 && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  // view report dialog
  const [reports, setReports] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) setReports(null);
  }, [showModal]);

  const handleShowPdf = (data, status) => {
    setShowModal(status);
    setReports(data);
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Report Name", key: "report_name", sortable: true },
    { name: "Date", key: "date", sortable: true },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <h5 className="d-flex align-items-center">
              <img src="/assets/svg/gutbiomics.svg" className="me-2" />
              Gutbiomics Report
            </h5>
            <hr />
          </div>
          <div className="mt-1">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="scrollable"
                >
                  <Tab label="Summary" {...a11yProps(0)} />
                  <Tab label="Food" {...a11yProps(1)} />
                  <Tab label="Supplements" {...a11yProps(2)} />
                  <Tab label="Exercise" {...a11yProps(3)} />
                  <Tab label="Cognitive" {...a11yProps(4)} />
                  <Tab label="Wellbeing" {...a11yProps(5)} />
                  <Tab label="Super organism" {...a11yProps(6)} />
                  <Tab label="Archives" {...a11yProps(7)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <div className="">
                  <div className="">
                    {/* <PdfViewer
                      fileUrl={urlBuilder(lastReport?.summary_report)}
                      reportOnly={true}
                    /> */}

                    {/* EXCEL REPORT */}

                    {lastReport?.summary_phenotype ||
                    lastReport?.summary_phenotype_elements ? (
                      <>
                        {lastReport?.summary_phenotype && (
                          <>
                            <MeterGraph
                              heading={lastReport?.summary_phenotype?.heading}
                              Graph_value={
                                lastReport?.summary_phenotype?.data[0]
                              }
                            />
                            <LineBar
                              heading={lastReport?.summary_phenotype?.heading}
                              Graph_value={
                                lastReport?.summary_phenotype?.data[1]
                              }
                            />
                          </>
                        )}
                        {lastReport?.summary_phenotype_elements && (
                          <MultiLineBarGraph
                            data={lastReport?.summary_phenotype_elements?.data}
                            heading={
                              lastReport?.summary_phenotype_elements?.heading
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="">
                  <div className=" ">
                    {/* <PdfViewer
                      fileUrl={urlBuilder(lastReport?.food_report)}
                      reportOnly={true}
                    /> */}

                    {/* EXCEL REPORT */}

                    {lastReport?.food_phenotype ||
                    lastReport?.food_phenotype_elements ? (
                      <>
                        {lastReport?.food_phenotype && (
                          <>
                            <MeterGraph
                              heading={lastReport?.food_phenotype?.heading}
                              Graph_value={lastReport?.food_phenotype?.data[0]}
                            />
                            <LineBar
                              heading={lastReport?.food_phenotype?.heading}
                              Graph_value={lastReport?.food_phenotype?.data[1]}
                            />
                          </>
                        )}
                        {lastReport?.food_phenotype_elements && (
                          <MultiLineBarGraph
                            data={lastReport?.food_phenotype_elements?.data}
                            heading={
                              lastReport?.food_phenotype_elements?.heading
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <div className="">
                  <div className=" ">
                    {/* <PdfViewer
                      fileUrl={urlBuilder(lastReport?.supplement_report)}
                      reportOnly={true}
                    /> */}

                    {/* EXCEL REPORT */}

                    {lastReport?.supplement_phenotype ||
                    lastReport?.supplement_phenotype_elements ? (
                      <>
                        {lastReport?.supplement_phenotype && (
                          <>
                            <MeterGraph
                              heading={
                                lastReport?.supplement_phenotype?.heading
                              }
                              Graph_value={
                                lastReport?.supplement_phenotype?.data[0]
                              }
                            />
                            <LineBar
                              heading={
                                lastReport?.supplement_phenotype?.heading
                              }
                              Graph_value={
                                lastReport?.supplement_phenotype?.data[1]
                              }
                            />
                          </>
                        )}
                        {lastReport?.supplement_phenotype_elements && (
                          <MultiLineBarGraph
                            data={
                              lastReport?.supplement_phenotype_elements?.data
                            }
                            heading={
                              lastReport?.supplement_phenotype_elements?.heading
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <div className="">
                  <div className=" ">
                    {/* <PdfViewer
                      fileUrl={urlBuilder(lastReport?.exercise_report)}
                      reportOnly={true}
                    /> */}

                    {/* EXCEL REPORT */}

                    {lastReport?.exercise_recovery_phenotype ||
                    lastReport?.exercise_recovery_phenotype_elements ? (
                      <>
                        {lastReport?.exercise_recovery_phenotype && (
                          <>
                            <MeterGraph
                              heading={
                                lastReport?.exercise_recovery_phenotype?.heading
                              }
                              Graph_value={
                                lastReport?.exercise_recovery_phenotype?.data[0]
                              }
                            />
                            <LineBar
                              heading={
                                lastReport?.exercise_recovery_phenotype?.heading
                              }
                              Graph_value={
                                lastReport?.exercise_recovery_phenotype?.data[1]
                              }
                            />
                          </>
                        )}
                        {lastReport?.exercise_recovery_phenotype_elements && (
                          <MultiLineBarGraph
                            data={
                              lastReport?.exercise_recovery_phenotype_elements
                                ?.data
                            }
                            heading={
                              lastReport?.exercise_recovery_phenotype_elements
                                ?.heading
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <div className="">
                  <div className=" ">
                    {/* <PdfViewer
                      fileUrl={urlBuilder(lastReport?.cognitive_report)}
                      reportOnly={true}
                    /> */}

                    {/* EXCEL REPORT */}

                    {lastReport?.cognitive_phenotype ||
                    lastReport?.cognitive_phenotype_elements ? (
                      <>
                        {lastReport?.cognitive_phenotype && (
                          <>
                            <MeterGraph
                              heading={lastReport?.cognitive_phenotype?.heading}
                              Graph_value={
                                lastReport?.cognitive_phenotype?.data[0]
                              }
                            />
                            <LineBar
                              heading={lastReport?.cognitive_phenotype?.heading}
                              Graph_value={
                                lastReport?.cognitive_phenotype?.data[1]
                              }
                            />
                          </>
                        )}
                        {lastReport?.cognitive_phenotype_elements && (
                          <MultiLineBarGraph
                            data={
                              lastReport?.cognitive_phenotype_elements?.data
                            }
                            heading={
                              lastReport?.cognitive_phenotype_elements?.heading
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={5}>
                <div className="">
                  <div className=" ">
                    {/* <PdfViewer
                      fileUrl={urlBuilder(lastReport?.wellbeing_report)}
                      reportOnly={true}
                    /> */}

                    {/* EXCEL REPORT */}

                    {lastReport?.wellbeing_phenotype ||
                    lastReport?.wellbeing_phenotype_elements ? (
                      <>
                        {lastReport?.wellbeing_phenotype && (
                          <>
                            <MeterGraph
                              heading={lastReport?.wellbeing_phenotype?.heading}
                              Graph_value={
                                lastReport?.wellbeing_phenotype?.data[0]
                              }
                            />
                            <LineBar
                              heading={lastReport?.wellbeing_phenotype?.heading}
                              Graph_value={
                                lastReport?.wellbeing_phenotype?.data[1]
                              }
                            />
                          </>
                        )}
                        {lastReport?.wellbeing_phenotype_elements && (
                          <MultiLineBarGraph
                            data={
                              lastReport?.wellbeing_phenotype_elements?.data
                            }
                            heading={
                              lastReport?.wellbeing_phenotype_elements?.heading
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={6}>
                <div className="">
                  <div className=" ">
                    {/* <PdfViewer
                      fileUrl={urlBuilder(lastReport?.superorganism_report)}
                      reportOnly={true}
                    /> */}

                    {/* EXCEL REPORT */}

                    {lastReport?.super_organism_phenotype ||
                    lastReport?.super_organism_elements ? (
                      <>
                        {lastReport?.super_organism_phenotype && (
                          <>
                            <MeterGraph
                              heading={
                                lastReport?.super_organism_phenotype?.heading
                              }
                              Graph_value={
                                lastReport?.super_organism_phenotype?.data[0]
                              }
                            />
                            <LineBar
                              heading={
                                lastReport?.super_organism_phenotype?.heading
                              }
                              Graph_value={
                                lastReport?.super_organism_phenotype?.data[1]
                              }
                            />
                          </>
                        )}
                        {lastReport?.super_organism_elements && (
                          <MultiLineBarGraph
                            data={lastReport?.super_organism_elements?.data}
                            heading={
                              lastReport?.super_organism_elements?.heading
                            }
                          />
                        )}
                      </>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center">
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <div className="tpHdrVw d-flex flex-wrap align-items-center justify-content-end mb-2 gap-2">
                  <CustomSearch
                    inputName="search"
                    query={searchFormik.values.search}
                    handleChange={searchFormik.handleChange}
                    handleSubmit={(e) => {
                      e.preventDefault();
                      searchFormik.handleSubmit();
                      searchFormik.setFieldValue("page", 1);
                    }}
                    containerClasses="ms-md-auto small"
                    disabled={isFetching || isLoading}
                  />
                  <Badge badgeContent={handelBageContent} color="secondary">
                    <CustomButton
                      size="small"
                      onClick={() => setFilterModal(true)}
                      label={
                        <>
                          <Tune className="me-2" />
                          Filter
                        </>
                      }
                    />
                  </Badge>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        {tableHeads.map(({ sortable, key, name }, idx) => (
                          <th key={idx}>
                            <div
                              className={`d-flex align-items-center ${
                                sortable ? "sortable" : ""
                              } ${
                                searchFormik.values.sort.includes(key)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                if (sortable && !isLoading && !isFetching) {
                                  return handleSort(searchFormik, key || "");
                                }
                              }}
                            >
                              {name}
                              {sortable && (
                                <span className="material-symbols-rounded">
                                  {searchFormik.values.sort.includes(key)
                                    ? searchFormik.values.sort.includes(
                                        `-${key}`
                                      )
                                      ? "expand_more"
                                      : "expand_less"
                                    : "unfold_more"}
                                </span>
                              )}
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {!!list?.report?.length &&
                        list.report.map((data, index) => (
                          <tr
                            key={data?.id}
                            style={{ verticalAlign: "middle" }}
                          >
                            <td>{index + 1}</td>
                            <td>{data?.report_name || "-"}</td>
                            <td>{data?.date || "-"}</td>
                            <td>
                              <CustomButton
                                label={
                                  <>
                                    <span className="material-symbols-rounded me-2">
                                      open_in_new
                                    </span>
                                    View
                                  </>
                                }
                                btnType="secondary"
                                size="small"
                                onClick={() => {
                                  handleShowPdf(data, true);
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="tblFtrActs">
                  <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                    {isLoading
                      ? "Fetching records.."
                      : isError && !list?.report?.length
                      ? "Failed to load records!"
                      : !list?.report?.length && "No Records Found!"}
                  </p>
                  <div>
                    <TablePagination
                      component="div"
                      count={list?.count || 0}
                      page={searchFormik.values.page - 1}
                      onPageChange={handleChangePage}
                      rowsPerPage={searchFormik.values.list_per_page}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>

                {/* <PdfViewerFSModal
                fileUrl={pdf.url}
                title={pdf.title}
                open={showModal}
                handleClose={() => setShowModal(false)}
              /> */}
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      <ReportDialog
        title={reports?.report_name}
        open={showModal}
        handleClose={() => setShowModal(false)}
      >
        <div className="mt-3">
          <ViewGutReport reports={reports} />
        </div>
      </ReportDialog>
      {filterModal && (
        <SearchReportModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default GutBiomicsReport;
