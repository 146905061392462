import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetUserProductsQuery } from "../../services/products/ProductsApi";
import { useSelector } from "react-redux";
import "./ProfileDetails.css";
import MultiLayout from "../../Components/Layout/MultiLayout";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useGetTransactionsQuery } from "../../services/auth/authApi";

const Transactions = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const { data: transactions } = useGetTransactionsQuery(user?.id, {
    refetchOnFocus: true,
  });

  console.log("transactions", transactions);

  return (
    <>
      <div className="dshStCard">
        <div className="pt-2 pb-2">
          <div className="d-flex align-items-center">
            <span className="material-symbols-rounded me-2">receipt_long</span>
            <h5 className="mb-0">My Transactions</h5>
          </div>
          <hr />
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Order ID</th>
                <th>Order Type</th>
                <th>Transaction ID</th>
                <th>Date</th>
                <th>Transaction Amount</th>
                <th>Payment Mode</th>
              </tr>
            </thead>
            <tbody>
              {!!transactions?.length &&
                transactions.map((el, idx) => (
                  <>
                    <tr>
                      {console.log("el", el)}
                      <td>{idx + 1}</td>
                      <td>{el["fkitorder"] ?? el["order"]}</td>
                      <td>
                        {el["fkitorder"] != undefined ? "Followkit" : "Product"}
                      </td>
                      <td>{el.transaction_id}</td>
                      <td>{el.transaction_date}</td>
                      <td>{currencyFomatter(el?.amount || 0)}</td>
                      <td>
                        <span className={`${el?.payment_type} pymtStaus`}>
                          {el?.payment_type}
                        </span>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        </div>
        {!transactions?.length && (
          <h6 className="text-center text-muted">No Transaction Found</h6>
        )}
      </div>
    </>
  );
};

export default Transactions;
