import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setUserCredentials } from "../../features/auth/authSlice";

export const baseUrl = process.env.REACT_APP_API;

const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,
  credentials: "include",
  mode: "cors",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.accessToken;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 403) {
    const refreshResult = await baseQuery("/api/user", api, extraOptions);
    if (refreshResult?.data) {
      api.dispatch(setUserCredentials(refreshResult.data));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const appApi = createApi({
  reducerPath: "APP_API",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "User",
    "Invite",
    "Gutbiomics",
    "Microbiome",
    "KpiReport",
    "Products",
    "SportsPersons",
    "UploadedReports",
    "InnerHacksReport",
    "FollowKits",
    "EBooks",
    "Recipes",
    "Group",
    "Country",
    "State",
    "City",
    "Cart",
    "StaticPages",
    "Checkout",
    "UserProducts",
    "UserAddress",
    "Follow_Cart",
    "Follow_Checkout",
    "Patients",
  ],
  endpoints: (builder) => ({}),
});
