import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetProductsQuery,
  useLazyGetProductsBySearchQuery,
} from "../../services/products/ProductsApi";
import ProductCard from "./ProductCard";
import ViewProduct from "./ViewProduct";
import { useDispatch, useSelector } from "react-redux";
import "./product.css";
import { Badge, Divider, TablePagination } from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Tune } from "@mui/icons-material";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import SearchProducts from "./SearchProducts";
import { addSearchValue } from "../../features/search/searchSlice";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";

const Products = () => {
  const { user } = useSelector((state) => state.auth);
  const [showProduct, setShowProduct] = useState({
    view: false,
    id: null,
  });

  const [getProducts, { data: list, isLoading, isError, isFetching }] =
    useLazyGetProductsBySearchQuery();

  const dispatch = useDispatch();
  // Reports list with filters

  const [filterModal, setFilterModal] = useState(false);
  const { products_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      id: user?.id,
      search: "",
      filter: {
        category: [],
      },
      sort: "",
      list_per_page: 10,
      page: 1,
    },
    onSubmit: async (values, actions) => {
      if (isLoading) return;
      const notify = toast.loading("Getting products..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: "products_search", values }));
      try {
        const query = await getProducts({ ...values })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, { id: notify });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getProducts(previousFilter);
      } else {
        await getProducts({ ...searchFormik.values });
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.category?.length && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <div className="d-flex align-items-center">
              {/* <div className="searchWrapper ms-auto">
                <input placeholder="Search" />
                <span className="material-symbols-rounded">search</span>
              </div> */}
            </div>
            <div className="tpHdrVw d-flex flex-wrap align-items-center mb-4 gap-2">
              <div className="d-flex align-items-center">
                <img src="/assets/svg/products.svg" className="me-2" />
                <h5 className="mb-0">Products</h5>
              </div>
              <CustomSearch
                inputName="search"
                query={searchFormik.values.search}
                handleChange={searchFormik.handleChange}
                handleSubmit={(e) => {
                  e.preventDefault();
                  searchFormik.handleSubmit();
                  searchFormik.setFieldValue("page", 1);
                }}
                containerClasses="ms-md-auto small"
                disabled={isFetching || isLoading}
              />
              <Badge badgeContent={handelBageContent} color="secondary">
                <CustomButton
                  size="small"
                  onClick={() => setFilterModal(true)}
                  label={
                    <>
                      <Tune className="me-2" />
                      Filter
                    </>
                  }
                />
              </Badge>
            </div>
            <hr />
          </div>
          <div className="dtl_card_wrapper">
            {!!list?.report?.length &&
              list.report.map((el, idx) => (
                <ProductCard
                  {...el}
                  key={idx}
                  setShowProduct={setShowProduct}
                />
              ))}
          </div>
          {!list?.report?.length && (
            <h6 className="text-center text-muted">No Products Listed</h6>
          )}
          {!!list?.report?.length && (
            <>
              <Divider className="mt-5" />
              <TablePagination
                component="div"
                count={list?.count || 0}
                page={searchFormik.values.page - 1}
                onPageChange={handleChangePage}
                rowsPerPage={searchFormik.values.list_per_page}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </div>
      </div>
      {showProduct.view && (
        <ViewProduct
          showProduct={showProduct.view}
          setShowProduct={setShowProduct}
          id={showProduct.id}
        />
      )}
      {filterModal && (
        <SearchProducts
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </>
  );
};

export default Products;
