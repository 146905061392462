import React from "react";
import "./MultiLayout.css";
import { Link, NavLink, Outlet, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const MultiLayout = (props) => {
  const navigate = useNavigate();

  return (
    <div className="mnDashboardView">
      <div className="topTabs">
        <NavLink activeClassName="active" to="/profile">
          Profile
        </NavLink>
        <NavLink activeClassName="active" to="/user/address">
          My Address
        </NavLink>
        <NavLink activeClassName="active" to="/user/purchased">
          Purchased Products
        </NavLink>
        <NavLink activeClassName="active" to="/user/transactions">
          Transactions
        </NavLink>
        {/* <NavLink activeClassName="active" to="/user/mycards">
          My Cards
        </NavLink> */}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default MultiLayout;
