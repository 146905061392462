import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import "../Dashboard/Dashboard.css";
import { useGetStaticPagesQuery } from "../../services/StaticPages/StaticPageApis";
import { CircularProgress } from "@mui/material";

const Faq = () => {
  const { type } = useParams();

  const { data: staticPage, isLoading } = useGetStaticPagesQuery(type, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const handleEmailLink = (text = "") => {
    const extractEmails =
      text.match(
        /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi
      ) || [];

    if (!!extractEmails.length) {
      extractEmails.map((email) => {
        return (text = text
          ?.toString()
          ?.replace(email, `<a href = "mailto:${email}">${email}</a>`));
      });
    }

    return text;
  };

  if (isLoading)
    return (
      <div className="d-flex justify-content-center">
        <CircularProgress />
      </div>
    );

  return (
    <div className="mnDashboardView">
      <div className="dshStCard">
        <div className="container staticPageContainer p-2 p-md-5">
          {/* <h2 className="mb-4" style={{ textTransform: "capitalize" }}>
            {staticPage?.title === "terms"
              ? "Terms & Conditions"
              : staticPage?.title}
          </h2> */}
          <div
            dangerouslySetInnerHTML={{
              __html: handleEmailLink(staticPage?.content),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
