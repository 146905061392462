import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";

import {
  Autocomplete,
  Avatar,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import { useGetGroupListQuery } from "../../services/Groupping/grouppingApi";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import { ChevronLeft, Delete, PostAddOutlined } from "@mui/icons-material";
import "./product.css";
import { useAddBulkProductsToCartMutation } from "../../services/products/ProductsApi";
import ProductBulkModal from "./ProductBulkModal";
import ViewProduct from "./ViewProduct";

const ProductBulkOrder = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [vatSum, setVatSum] = useState(0);
  const [itemsToCart, { isLoading: bulkLoading }] =
    useAddBulkProductsToCartMutation();

  const [showProduct, setShowProduct] = useState({
    view: false,
    id: null,
  });

  const { data: groupData } = useGetGroupListQuery(undefined, {
    refetchOnFocus: true,
  });

  const [dialog, setDialog] = useState({
    mount: false,
    user: undefined,
    index: undefined,
  });

  const formik = useFormik({
    initialValues: {
      group: null,
      group_members: [],
    },
    validationSchema: Yup.object().shape({
      group_members: Yup.array()
        .of(
          Yup.object().shape({
            products: Yup.array()
              .min(1, "Please select product!")
              .required("Required"),
          })
        )
        .required("Required"),
    }),
    onSubmit: async (values, actions) => {
      const transformed_data = () => {
        const data = new Array();

        values.group_members?.map((client) => {
          !!client.products?.length &&
            client.products?.map((product) => {
              return data.push({
                product: product.id,
                quantity: product.quantity,
                user: client.user.id,
                group: values.group?.id,
              });
            });
        });

        return data;
      };

      try {
        const res = await itemsToCart(transformed_data())
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Your order has been moved to cart.");
              navigate("/products/my-cart");
            }
          });
        return res;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong.");
        }
        console.log(error);
      }
    },
  });

  const orderPriceInfo = useMemo(() => {
    return formik.values.group_members?.reduce(
      (acc, crr, idx) => {
        crr.products?.map((el) => {
          acc.total = parseInt(acc.total) + parseInt(el?.price);
          const isExistsProduct = acc.product.findIndex(
            (ele) => ele.id === el.id
          );
          isExistsProduct >= 0
            ? acc.product[isExistsProduct].count++
            : acc.product.push({ ...el, count: 1 });
        });

        return acc;
      },
      { product: [], total: 0 }
    );
  }, [formik.values]);

  // Calculating Vat price
  useEffect(() => {
    const orders = formik.values.group_members.map((el) => {
      if (el.products.length > 0) {
        return el.products.map((ele) => (ele?.price * ele?.vat) / 100);
      } else {
        return [];
      }
    });
    const dat = orders
      ?.map((vats) => vats.reduce((acc, curr) => acc + curr, 0))
      .map((val) => Math.round(val))
      .reduce((acc, curr) => acc + curr, 0);
    setVatSum(dat);
  }, [orderPriceInfo, formik?.values]);

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 ">
            <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
              <h5 className="d-flex align-items-center">
                <IconButton onClick={() => navigate("/products/purchased")}>
                  <ChevronLeft />
                </IconButton>
                Bulk Products Order
              </h5>
            </div>
            <hr />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <div className="row g-5">
                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="customInputWrpr">
                        <Autocomplete
                          className="flex-fill"
                          disablePortal
                          options={groupData ?? []}
                          getOptionLabel={(option) => option?.group_name ?? ""}
                          value={formik.values.group}
                          onChange={(_, newValue) => {
                            formik.setFieldValue(`group`, newValue ?? null);
                            formik.setFieldValue(
                              `group_members`,
                              newValue?.users.map((user) => {
                                return {
                                  user: user,
                                  products: [],
                                };
                              })
                            );
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?.id === value?.id || null
                          }
                          renderInput={(params) => (
                            <TextField
                              name="group"
                              label="Enter group"
                              {...params}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      {!!formik.values.group_members?.length ? (
                        <FieldArray
                          name={`group_members`}
                          render={(arrayHeplers) => (
                            <>
                              {!!formik.values.group_members.length &&
                                formik.values.group_members.map(
                                  ({ user, products }, idx) => (
                                    <React.Fragment key={idx}>
                                      <div className="bl_users_wrapper">
                                        <div className="bl_user">
                                          <div className="bl_user_header">
                                            <div className="bl_user_header_profile">
                                              <Avatar
                                                sx={{ width: 50, height: 50 }}
                                                src={urlBuilder(
                                                  user?.profile_image
                                                )}
                                              />
                                              <h6 className="mb-0">
                                                {user?.first_name}{" "}
                                                {user?.last_name}
                                                <br />
                                                <small className="text-muted">
                                                  {user?.ref_no} | {user?.email}
                                                </small>
                                              </h6>
                                            </div>

                                            <div className="d-flex gap-2">
                                              <Tooltip title="Add Products">
                                                <IconButton
                                                  color="primary"
                                                  onClick={() =>
                                                    setDialog({
                                                      mount: true,
                                                      user,
                                                      index: idx,
                                                    })
                                                  }
                                                >
                                                  <PostAddOutlined />
                                                </IconButton>
                                              </Tooltip>
                                              <Tooltip title="Remove user">
                                                <IconButton
                                                  onClick={() =>
                                                    arrayHeplers.remove(idx)
                                                  }
                                                  color="error"
                                                >
                                                  <Delete />
                                                </IconButton>
                                              </Tooltip>
                                            </div>
                                          </div>
                                          <hr />
                                          <div className="bl_user_prducts_wrapper">
                                            <h6 className="mb-3 border-bottom pb-3">
                                              Selected Products
                                            </h6>
                                            {!!products?.length ? (
                                              products.map((product, idx) => (
                                                <>
                                                  <div
                                                    key={idx}
                                                    className="d-flex align-items-center mb-3 gap-3 border-bottom px-2 pb-2"
                                                  >
                                                    <Avatar
                                                      variant="rounded"
                                                      src={
                                                        product
                                                          ?.productimages[0]
                                                          ?.product_image
                                                      }
                                                    />
                                                    <p className="flex-fill mb-0">
                                                      {product?.product_name}{" "}
                                                      {product?.product_type ===
                                                        "personalized" && (
                                                        <>
                                                          |{" "}
                                                          {
                                                            product?.product_type
                                                          }
                                                        </>
                                                      )}
                                                    </p>
                                                    <b>
                                                      <small className="text-muted ">
                                                        {currencyFomatter(
                                                          product?.price
                                                        )}{" "}
                                                      </small>
                                                    </b>
                                                  </div>
                                                </>
                                              ))
                                            ) : (
                                              <h6 className="text-danger my-4 text-center">
                                                {formik.touched
                                                  ?.group_members?.[idx]
                                                  ?.products &&
                                                formik.errors?.group_members?.[
                                                  idx
                                                ]?.products ? (
                                                  <small className="mb-0 text-danger">
                                                    {
                                                      formik.errors
                                                        .group_members[idx]
                                                        .products
                                                    }
                                                  </small>
                                                ) : (
                                                  ""
                                                )}
                                              </h6>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                            </>
                          )}
                        />
                      ) : (
                        <p className="text-danger">Please select a group</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 position-relative ">
                  <div className="row bg-light py-3 position-sticky sticky-top">
                    <div className="col-md-12">
                      <div className="rprtsWrpr">
                        <label className="mb-3 border-bottom w-100 pb-2">
                          Order summary:
                        </label>
                        {orderPriceInfo?.product &&
                        !!orderPriceInfo.product?.length ? (
                          <>
                            {orderPriceInfo.product.map((product, idx) => (
                              <div
                                key={idx}
                                className="d-flex justify-content-between"
                              >
                                <p>
                                  <span
                                    style={{ minWidth: "180px" }}
                                    className="input-label"
                                  >
                                    {product.product_name}
                                  </span>
                                  <br />
                                  <small className="text-muted">
                                    {currencyFomatter(product.price ?? 0)} x
                                    {product.count}
                                  </small>
                                </p>

                                <span className="rprtPricce mb-3">
                                  <b>
                                    {currencyFomatter(
                                      product.price * product.count ?? 0
                                    )}
                                  </b>
                                </span>
                              </div>
                            ))}
                            {/* <div className="d-flex align-items-center justify-content-between mt-0">
                              <p className="mb-0">Order Amount:</p>
                              <span className="mb-0 rprtPricce me-0">
                                <b>
                                  {currencyFomatter(orderPriceInfo?.total || 0)}
                                </b>
                              </span>
                            </div> */}
                            {/* <div className="d-flex align-items-center justify-content-between mt-3">
                              <p className="mb-0">BTW (Excl):</p>
                              <span className="mb-0 rprtPricce me-0">
                                <b>
                                  {currencyFomatter(orderPriceInfo?.total || 0)}
                                </b>
                              </span>
                            </div> */}
                            {/* <div className="d-flex align-items-center justify-content-between mt-3">
                              <p className="mb-0">BTW (Incl):</p>
                              <span className="mb-0 rprtPricce me-0">
                                <b>
                                  {currencyFomatter(
                                    orderPriceInfo?.total + Number(vatSum) || 0
                                  )}
                                </b>
                              </span>
                            </div> */}
                          </>
                        ) : (
                          <p>No Products Selected</p>
                        )}

                        <div className="ttlPrice d-flex align-items-center justify-content-between">
                          <p className="mb-0">Total Price:</p>
                          <p className="mb-0 ttlPrVl me-0">
                            {/* {currencyFomatter(
                              orderPriceInfo?.total + Number(vatSum) || 0
                            )} */}
                            {currencyFomatter(orderPriceInfo?.total || 0)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 text-end mt-4">
                      <CustomButton
                        label={bulkLoading ? "Adding to Cart.." : "Add to Cart"}
                        disabled={bulkLoading}
                        type="submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </FormikProvider>
          </form>
        </div>
      </div>

      <ProductBulkModal
        formik={formik}
        {...dialog}
        setDialog={setDialog}
        setShowProduct={setShowProduct}
      />

      {showProduct.view && (
        <ViewProduct
          showProduct={showProduct.view}
          setShowProduct={setShowProduct}
          id={showProduct.id}
        />
      )}
    </>
  );
};

export default ProductBulkOrder;
