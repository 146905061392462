import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import "./errorscreen.css";

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <div className="error_screen_wrapper">
      <div className="error_container">
        <img src="/assets/svg/401.svg" alt="401" />
        <h2>Unauthorized</h2>
        <p>
          Sorry, you do not have access to this page.
          <br /> Please contact the site administrator for more information.
        </p>
        <div className="btn_cntr">
          <CustomButton
            btnType="secondary"
            label="Go Back"
            onClick={() => navigate(-1)}
          />
          <CustomButton label="Return home" onClick={() => navigate("/")} />
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
