import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Avatar, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-hot-toast";
import { baseUrl } from "../../services/api/api";

import moment from "moment";
import DoctorForm from "../Signup/forms/DoctorForm";
import TeamManagerForm from "../Signup/forms/TeamManagerForm";
import ProfessionalForm from "../Signup/forms/ProfessionalForm";
import SportPersonForm from "../Signup/forms/SportPersonForm";
import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";
import CustomButton from "../../Components/Atoms/CustomButton";
import {
  useDeleteProfileImageMutation,
  useGetDoctorListQuery,
  useGetMotivesListQuery,
  useGetProfessionalListQuery,
  useGetTeamManagerListQuery,
  useUpdateUserMutation,
} from "../../services/auth/authApi";
import MultiLayout from "../../Components/Layout/MultiLayout";
import { useGetSportCategoryQuery } from "../../services/sportsPerson/sportsPersonApi";
import { setUserCredentials } from "../../features/auth/authSlice";
import { Delete, PhotoCamera } from "@mui/icons-material";
const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [selectedFile, setSelectedFile] = useState(null);
  const [profileUrl, setProfileUrl] = useState(null);

  const [county, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [deleteProfile, { isLoading: profileDeleting }] =
    useDeleteProfileImageMutation();

  const { data: motive_list } = useGetMotivesListQuery();
  const { data: professional_list } = useGetProfessionalListQuery();
  const { data: manager_list } = useGetTeamManagerListQuery();
  const { data: doctor_list } = useGetDoctorListQuery();
  const { data: sport_categeory } = useGetSportCategoryQuery();

  const validationArray = Yup.object().shape({
    first_name: Yup.string()
      .min(3, "Minimum 3 characters required")
      .required("First Name is a required field")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),

    last_name: Yup.string()
      .min(3, "Minimum 3 characters required")
      .required("Last Name is a required field")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[a-zA-Z0-9 ]*$/, "Special characters are not allowed"),

    dob: Yup.date()
      .typeError("INVALID_DATE")
      .min(moment().subtract(120, "years"), "Invalid DOB")
      .max(moment().endOf("day").subtract(1, "years"), "Invalid DOB")
      .required("DOB is a required field"),
    email: Yup.string().email().required("Email is a required field"),
    address_1: Yup.string()
      .min(3, "Minimum 3 characters required")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z0-9w!@#$%^&*()-=+_]+)$/,
        "Invalid address"
      ),
    mobile_no: Yup.number().required("Phone No is a required field"),
    height: Yup.number().when(["account_type"], {
      is: (account_type) => account_type === "sport_person",
      then: Yup.number()
        .positive()
        .min(10)
        .max(350)
        .required("Height is a required field"),
    }),
    weight: Yup.number().when(["account_type"], {
      is: (account_type) => account_type === "sport_person",
      then: Yup.number()
        .positive()
        .min(5)
        .max(650)
        .required("Weight is a required field"),
    }),
    team_name: Yup.string().when(["account_type"], {
      is: (account_type) => account_type === "team_manager",
      then: Yup.string().required("Team name is a required field"),
    }),
    hospital_name: Yup.string().when(["account_type"], {
      is: (account_type) => account_type === "doctor",
      then: Yup.string().required("Hospital name is a required field"),
    }),
    sport_categeory: Yup.mixed()
      .when(["account_type", "account_subtype"], {
        is: (account_type, account_subtype) =>
          (account_type === "sport_person" &&
            account_subtype === "sport_person") ||
          account_type === "team_manager" ||
          account_type === "professional",
        then: Yup.string()
          .typeError("Sports category is a required field")
          .required("Sports category is a required field"),
      })
      .typeError("Sports category is a required field"),
    zipcode: Yup.string().max(15, "max 15").required("required"),
    btw_no: Yup.string()
      .nullable()
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .matches(/^[A-Z0-9]+$/, "Invalid Format")
      .matches(
        /^(?=.*[a-zA-Z])([a-zA-Z 0-9w!@#$%^&*()-=+]+)$/,
        "Numbers only not allowed"
      )
      .min(11, "BTW/VAT number must be at least 11 characters")
      .max(15, "BTW/VAT number must be at most 15 characters"),
  });

  const formik = useFormik({
    initialValues: user || {
      account_type: "professional",
      first_name: "",
      last_name: "",
      email: "",
      mobile_no: "",
      dob: "",
      gender: "",
      country: "",
      state: "",
      city: "",
      zipcode: "",
      height: "",
      weight: "",
      sport_categeory: "",
      professional: "",
      team_manager: "",
      motive: "",
      team_name: "",
      council_name: "",
      no_of_persons: "",
      specialization: "",
      hospital_name: "",
      referer_code: "",
      btw_no: 0,
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      const formValues = new FormData();
      for (const key in values) {
        if (
          key !== "uploads" &&
          key !== "profile_image" &&
          key !== "sport_categeory"
        ) {
          formValues.append(key, values[key]);
        }
      }
      if (selectedFile !== null) {
        formValues.append("profile_image", selectedFile);
      }
      if (values.sport_categeory) {
        formValues.append("sport_categeory", values.sport_categeory);
      }

      await updateUser(formValues)
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(setUserCredentials(res));
            toast.success("Account has been updated successfully.");
          }
        })
        .catch((error) => {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        });
    },
  });

  const handleProfile = (e) => {
    const file = e.target.files[0];
    if (file.size > 1000000)
      return toast.error("Image size must be less than 1 mb");
    setSelectedFile(file);
    const blob = URL.createObjectURL(file);
    setProfileUrl(blob);
    return (e.target.value = "");
  };

  useEffect(() => {
    if (formik.values.country || "") {
      country_api
        .getAllCountries()
        .filter((el) => el.isoCode === formik.values.country && setCountry(el));
    }
  }, [formik.values.country]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      state_api
        .getStatesOfCountry(formik.values.country)
        .filter((el) => el.isoCode === formik.values.state && setState(el));
    }
  }, [formik.values.country, formik.values.state]);

  useEffect(() => {
    if (formik.values.country && formik.values.state) {
      city_api
        .getCitiesOfState(formik.values.country, formik.values.state)
        .filter((el) => el.name === formik.values.city && setCity(el));
    }
  }, [formik.values.country, formik.values.state, formik.values.city]);

  const handleProfileDelete = async (e) => {
    if (profileDeleting) return;
    setProfileUrl(null);
    setSelectedFile(null);
    if (user?.profile_image) {
      const notify = toast.loading("Profile image deleting..");
      try {
        await deleteProfile()
          .unwrap()
          .then((res) => {
            dispatch(setUserCredentials({ ...user, profile_image: null }));
            toast.success("Profile has been deleted!", { id: notify });
          });
      } catch (error) {
        if (error?.data?.detail) {
          console.log(error.data.detail);
          toast.error(error.data.detail, { id: notify });
        } else {
          toast.error("Something went wrong!", { id: notify });
        }
      }
    }
  };

  const getFormByType = () => {
    switch (formik.values.account_type) {
      case "sport_person":
        return (
          <>
            <SportPersonForm
              formik={formik}
              country_list={country_api.getAllCountries()}
              state_list={state_api.getStatesOfCountry(formik.values.country)}
              city_list={city_api.getCitiesOfState(
                formik.values.country,
                formik.values.state
              )}
              professional_list={professional_list}
              manager_list={manager_list}
              doctor_list={doctor_list}
              motive_list={motive_list}
              county={county}
              state={state}
              city={city}
              setCountry={setCountry}
              setState={setState}
              setCity={setCity}
              sport_categeory={sport_categeory}
              avoidPassword={true}
            />
          </>
        );
      case "professional":
        return (
          <ProfessionalForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            sport_categeory={sport_categeory}
            avoidPassword={true}
          />
        );
      case "team_manager":
        return (
          <TeamManagerForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            sport_categeory={sport_categeory}
            avoidPassword={true}
          />
        );
      case "doctor":
        return (
          <DoctorForm
            formik={formik}
            country_list={country_api.getAllCountries()}
            state_list={state_api.getStatesOfCountry(formik.values.country)}
            city_list={city_api.getCitiesOfState(
              formik.values.country,
              formik.values.state
            )}
            county={county}
            state={state}
            city={city}
            setCountry={setCountry}
            setState={setState}
            setCity={setCity}
            avoidPassword={true}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className="dshStCard prflPageWrpr">
        <div className="pt-2 pb-4">
          <h5 className="d-flex align-items-center">
            <span className="material-symbols-rounded me-2">
              account_circle
            </span>
            Profile
          </h5>
          <hr />
        </div>
        <div className="row frmFlxWrpr">
          <div className="col-md-8">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12 d-none">
                  <label className="mb-2">Account Type</label>
                  <div className="cmRdIptWrpr mb-4">
                    <div className="radIpt">
                      <input
                        checked={
                          formik.values.account_type === "sport_person"
                            ? true
                            : false
                        }
                        id="sport_person"
                        type="radio"
                        name="account_type"
                        onChange={formik.handleChange}
                        value="sport_person"
                      />
                      <label htmlFor="sport_person">
                        <img src="/assets/svg/user.svg" alt="user" />
                        Sport Person
                      </label>
                    </div>
                    <div className="radIpt">
                      <input
                        checked={
                          formik.values.account_type === "professional"
                            ? true
                            : false
                        }
                        type="radio"
                        id="professional"
                        name="account_type"
                        onChange={formik.handleChange}
                        value="professional"
                      />
                      <label htmlFor="professional">
                        <img
                          src="/assets/svg/professional.svg"
                          alt="professional"
                        />
                        Professional
                      </label>
                    </div>
                    <div className="radIpt">
                      <input
                        checked={
                          formik.values.account_type === "team_manager"
                            ? true
                            : false
                        }
                        id="team_manager"
                        type="radio"
                        name="account_type"
                        onChange={formik.handleChange}
                        value="team_manager"
                      />
                      <label htmlFor="team_manager">
                        <img src="/assets/svg/manager.svg" alt="manager" />
                        Team Manager
                      </label>
                    </div>
                    <div className="radIpt">
                      <input
                        checked={
                          formik.values.account_type === "doctor" ? true : false
                        }
                        id="doctor"
                        type="radio"
                        onChange={formik.handleChange}
                        name="account_type"
                        value="doctor"
                      />
                      <label htmlFor="doctor">
                        <img src="/assets/svg/doctor.svg" alt="doctor" />
                        Doctor
                      </label>
                    </div>
                  </div>
                </div>
                {getFormByType()}
                <div className="col-md-12 text-center mt-3">
                  <CustomButton
                    label={isLoading ? "Updating..." : "Update"}
                    type="submit"
                    disabled={isLoading}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-4">
            <div className="usrImgWrpr">
              <div className="profile_actions">
                <IconButton
                  className="profile_action_btn"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    type="file"
                    onChange={handleProfile}
                    accept="image/*"
                    hidden
                  />
                  <PhotoCamera />
                </IconButton>
                <IconButton
                  className="profile_action_btn"
                  onClick={handleProfileDelete}
                  aria-label="delete picture"
                >
                  <Delete />
                </IconButton>
              </div>

              <Avatar src={profileUrl || baseUrl + user?.profile_image} />
              <p
                className="text-center mt-2"
                style={{ fontSize: "12px", color: "gray" }}
              >
                <small>
                  Please upload the image png/jpeg in 400px X 400px / 1:1 ratio
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
