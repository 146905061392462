import { useFormik } from "formik";
import React from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useInvitePeopleMutation } from "../../services/InvitePeople/InvitePeople";

function InvitePeople({ inviteDialog, setInviteDialog }) {
  const [invitePeople, { isLoading }] = useInvitePeopleMutation();

  const validationArray = Yup.object().shape({
    from_mail: Yup.string().email().required("Please enter your valid Email"),
    to_mail: Yup.string().email().required("Please enter Invite Person Email"),
  });

  const formik = useFormik({
    initialValues: {
      from_mail: "",
      to_mail: "",
      person_type: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      await invitePeople(values)
        .unwrap()
        .then((res) => {
          if (res) {
            actions.resetForm();
            toast.success("Invitaion sent successfully.");
            setInviteDialog(false);
          }
        })
        .catch((err) => {
          console.error(err);
          if (err?.data?.detail) {
            toast.error(err?.data?.detail);
          } else {
            toast.error("Something went wrong.");
          }
        });
    },
  });

  return (
    <CustomDialog
      open={inviteDialog}
      handleClose={() => setInviteDialog(false)}
    >
      <div className="text-center">
        <img src="/assets/svg/invite.svg" className="mb-3" />
        <h3 className="mb-2">Invite Dialog</h3>
        <p className="">
          Invite your clients to this website, you can see the list of invited
          people in your profile
        </p>
        <form className="mt-5" onSubmit={formik.handleSubmit}>
          <div className="row text-start">
            <div className="col-md-12">
              <CustomInput
                label="Email"
                placeholder="Enter email"
                type="text"
                name="from_mail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.from_mail}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Invite Person Email"
                placeholder="Enter Invite Person Email"
                type="text"
                name="to_mail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.to_mail}
                formik={formik}
              />
            </div>
            <div className="col-md-6">
              <CustomSelect
                label="Person Type"
                name="person_type"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.person_type}
                formik={formik}
                options={[
                  {
                    value: "Sport Person",
                    name: "Sport Person",
                  },
                  {
                    value: "Professional",
                    name: "Professional",
                  },
                  {
                    value: "Team Manager",
                    name: "Team Manager",
                  },
                  {
                    value: "Doctor",
                    name: "Doctor",
                  },
                ]}
              />
            </div>
            <div className="col-md-6">
              <CustomButton
                label={isLoading ? "Inviting..." : "Send Invite"}
                type="submit"
                disabled={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
}

export default InvitePeople;
