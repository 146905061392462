import { appApi } from "../api/api";

export const ebookAndRecipesApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getEBooks: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/ebooks_search`,
        method: "POST",
        body: values,
      }),
      providesTags: ["EBooks"],
    }),

    getEBookById: builder.query({
      query: (id) => `/api/ebooks/${id}`,
      providesTags: ["EBooks"],
    }),

    getRecipes: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/receipe_search`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Recipes"],
    }),

    getSingleRecipe: builder.query({
      query: (id) => `/api/recipes/${id}`,
      providesTags: ["Recipes"],
    }),

    //Recipe Category
    getRecipeCategory: builder.query({
      query: () => `api/admin/recipe_categories`,

      providesTags: (result, error, arg) =>
        result
          ? [
              "Recipe-Category",
              ...result.map(({ id }) => ({ type: "Recipe-Category", id })),
            ]
          : ["Recipe-Category"],
    }),

    //E-Book Category
    getEbookCategory: builder.query({
      query: () => `api/admin/ebook_categories`,
      providesTags: (result, error, arg) =>
        result
          ? [
              "E-Book-Category",
              ...result.map(({ id }) => ({ type: "E-Book-Category", id })),
            ]
          : ["E-Book-Category"],
    }),
  }),
});

export const {
  useGetEBooksQuery,
  useGetRecipesQuery,
  useLazyGetEBookByIdQuery,
  useLazyGetSingleRecipeQuery,
  useLazyGetEBooksQuery,
  useLazyGetRecipesQuery,
  useGetEbookCategoryQuery,
  useGetRecipeCategoryQuery,
} = ebookAndRecipesApi;
