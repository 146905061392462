import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useLazyGetCouponInfoQuery } from "../../services/products/ProductsApi";
import { currencyFomatter } from "../../Utils/Utils";
import "./Checkout.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import CustomInput from "../../Components/Atoms/CustomInput";

import CustomButton from "../../Components/Atoms/CustomButton";
import { useGetAddressQuery } from "../../services/auth/authApi";
import AddAddress from "../ProfileDetails/AddAddress";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import {
  useGetFollowCartListQuery,
  useGetFollowKitPricingsQuery,
  useCreateFollowOrderMutation,
  useDeleteFollowCartListMutation,
  useCreateBulkFollowOrderMutation,
} from "../../services/followKits/followKits";

import { Delete, Mail, Person } from "@mui/icons-material";

import * as yup from "yup";

const FollowCheckout = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const {
    data: cart,
    isFetching,
    isLoading: cartLoading,
  } = useGetFollowCartListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const { data: reports } = useGetFollowKitPricingsQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [createOrder, { isLoading }] = useCreateFollowOrderMutation();
  const [createBulkOrder, { isLoading: bulkOrderLoading }] =
    useCreateBulkFollowOrderMutation();

  const [addressModal, setAddressModal] = useState({
    data: null,
    type: "new",
    open: false,
  });

  const { data: address } = useGetAddressQuery(user?.id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const validationArray =
    user.account_type !== "sport_person"
      ? Yup.object().shape({
          shipping: Yup.string().when("separate_shipping", {
            is: false,
            then: Yup.string().required("Address is a required field"),
          }),
          payment_method: Yup.string().required(
            "Plese choose the Payment method."
          ),
          separate_shipping: Yup.boolean().required(
            "Plese choose the shipping method"
          ),
        })
      : Yup.object().shape({
          shipping: Yup.string().required("Address is a required field"),
          payment_method: Yup.string().required(
            "Plese choose the Payment method."
          ),
          separate_shipping: Yup.boolean().required(
            "Plese choose the shipping method"
          ),
        });

  const formik = useFormik({
    initialValues: {
      id: "",
      shipping: "",
      payment_method: "online",
      separate_shipping: false,
      coupon_code: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      console.log("valuesasasss", values);
      if (isLoading || bulkOrderLoading) return;
      if (user?.account_type !== "sport_person") {
        await createBulkOrder({
          ...values,
          separate_shipping: values.separate_shipping === "true" ? true : false,
          shipping: values.separate_shipping === true ? "" : values.shipping,
          coupon_code: values.coupon_code ? values.coupon_code.coupon_code : "",
        })
          .unwrap()
          .then((res) => {
            if (res) {
              const order_group_id = res?.order_details[0]?.order_group_id;

              if (
                values.coupon_code?.percentage != 100 &&
                values.payment_method === "online"
              ) {
                navigate(`/payment/bulk/${order_group_id}`);
              } else {
                toast.success("Order Placed Successfully!");
                navigate("/followKits");
              }
            }
          })
          .catch((error) => {
            if (error?.data?.detail) {
              toast.error(error?.data?.detail);
            } else if (error?.data?.msg) {
              toast.error(error?.data?.msg);
            } else {
              toast.error("Something went wrong");
            }
            console.log("rejected", error);
            throw new Error(error);
          });
      } else if (user?.account_type === "sport_person") {
        await createOrder({
          ...values,
          coupon_code: values.coupon_code ? values.coupon_code.coupon_code : "",
        })
          .unwrap()
          .then((res) => {
            if (res) {
              if (
                values.coupon_code?.percentage != 100 &&
                values.payment_method === "online"
              ) {
                navigate(`/payment/${res?.data?.ref_no}`);
              } else {
                toast.success("Order Placed Successfully!");
                navigate("/followKits");
              }
            }
          })
          .catch((error) => {
            if (error?.data?.detail) {
              toast.error(error.data.detail);
            } else {
              toast.error("Something went wrong");
            }
            console.log("rejected", error);
            throw new Error(error);
          });
      }
    },
  });

  useEffect(() => {
    if (user !== "sport_person" && formik.values.separate_shipping === true) {
      formik.setFieldValue("shipping", "");
    } else {
      if (address?.length > 0) {
        const defaultAddress = address.findIndex(
          (address) => address.is_default === true
        );
        formik.setFieldValue(
          "shipping",
          address[defaultAddress !== -1 ? defaultAddress : 0]?.id
        );
      }
    }
  }, [address, user, formik.values.separate_shipping]);

  useEffect(() => {
    if (cart?.length > 0) {
      formik.setFieldValue("id", cart[0]?.id);
    }
  }, [cart]);

  useEffect(() => {
    const { percentage } = formik.values.coupon_code;
    if (percentage == 100) {
      formik.setFieldValue("payment_method", "offline");
    }
  }, [formik.values.coupon_code]);

  const [deleteFollowCartList, { isLoading: deletingCart }] =
    useDeleteFollowCartListMutation();

  const handelDeleteCarthandler = async ({ id }) => {
    if (deletingCart) return;

    const notify = toast.loading("Deleting cart item...");

    try {
      await deleteFollowCartList(id)
        .unwrap()
        .then((res) => {
          if (res) {
            console.log(res);
            toast.success("Cart removed successfully", { id: notify });
          }
        });
    } catch (error) {
      console.log(error);
      if (error?.data?.detail) {
        toast.error(error.data.detail, { id: notify });
      } else {
        toast.error("Failed to delete cart item", { id: notify });
      }
    }
  };

  const transformdBulkData = useCallback(
    (list) => {
      return list?.reduce((acc, item, index) => {
        const user = item.receiver_user;
        const userName = item.user_name;
        const userEmail = item.user_email;

        const userExists = acc.findIndex((el) => el.user === user);
        if (userExists >= 0) {
          acc[userExists].kits.push(item.followkit?.kit_name);
        } else {
          acc.push({
            kits: [item.followkit?.kit_name],
            reports: item.followkit?.pricing,
            user: user,
            user_name: userName,
            user_email: userEmail,
          });
        }
        return acc;
      }, []);
    },
    [cart, reports]
  );

  const [getCouponInfo, { isLoading: couponLoading, isError: couponError }] =
    useLazyGetCouponInfoQuery();

  const couponFormik = useFormik({
    initialValues: {
      coupon_code: "",
    },
    validationSchema: yup.object().shape({
      coupon_code: yup.string().required("please provide valid coupon code!"),
    }),
    onSubmit: async (values) => {
      try {
        await getCouponInfo(values.coupon_code)
          .unwrap()
          .then((res) => {
            if (
              res?.user?.id === user?.id &&
              res?.amount < orderInfo.subTotal
            ) {
              formik.setFieldValue("coupon_code", res);
              toast.success("Coupon applied successfully!");
            } else {
              formik.setFieldValue("coupon_code", "");
              toast.error("Invalid Coupon!");
            }
          });
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Invalid Coupon!");
        }
        formik.setFieldValue("coupon_code", "");
        console.log("rejected", error);
        throw new Error(error);
      }
    },
  });

  const orderSummaryHandler = () => {
    const subTotal = cart?.reduce((subTotal, item, index) => {
      subTotal += item.total_price;
      return subTotal;
    }, 0);

    const discount = formik.values.coupon_code
      ? formik.values.coupon_code?.type === "percentage"
        ? subTotal -
          (
            subTotal -
            (subTotal * formik.values.coupon_code?.percentage) / 100
          ).toFixed(2)
        : formik.values.coupon_code?.amount
      : 0;

    if (!cart || !cart?.length > 0) {
      return; // Handle loading state
    }

    const sumOfVATPercentages = transformdBulkData(cart[0]?.fkitcartitems)
      .map((item) =>
        item.reports.map((report) => (report.price * report.vat) / 100)
      )
      .map((vats) => vats.reduce((acc, vat) => acc + vat, 0))
      .map((vat) => Math.round(vat))
      .reduce((acc, vat) => acc + vat, 0);

    const total = cart[0]?.is_vat_include
      ? subTotal + sumOfVATPercentages - discount
      : subTotal - discount;

    return { subTotal, discount, total, sumOfVATPercentages };
  };

  const orderInfo = useMemo(orderSummaryHandler, [
    cart,
    formik.values.coupon_code,
  ]);
  console.log(cart?.length > 0 && transformdBulkData(cart[0]?.fkitcartitems));
  if (cartLoading)
    return (
      <div className="w-100 d-flex justify-content-center">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <div className="d-flex align-items-center">
              <span className="material-symbols-rounded me-2 text-muted">
                shopping_bag
              </span>

              <h5 className="mb-0">Checkout</h5>
            </div>
            <hr />
          </div>
          {cart?.length > 1 && (
            <div className="alert_wrapper mb-4">
              <Alert severity="error">
                If more than one cart Please remove unwanted cart before
                checking out to avoid any issues.
              </Alert>
            </div>
          )}

          {!!cart?.length ? (
            <div className="checkoutWrapper flwKtChkWrpr justify-content-between flex-column flex-md-row">
              <div className="chckOtPdtDtls">
                {cart?.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <div className="chckOtCard">
                      <div className="pdtDtlsCnrt pt-3">
                        {transformdBulkData(data?.fkitcartitems)?.map(
                          (user, userIdx) => (
                            <div key={userIdx}>
                              <div className="d-flex align-items-center justify-content-between gap-2 flex-wrap">
                                <p className="d-flex align-items-center flex-wrap gap-2 mb-0">
                                  <Person color="primary" />
                                  {user.user_name}
                                  <small>({user.user_email})</small>
                                </p>
                                {user?.account_type !== "sport_person"
                                  ? cart?.length > 1 &&
                                    userIdx === 0 && (
                                      <div className="dltCrt">
                                        <IconButton
                                          onClick={() =>
                                            handelDeleteCarthandler(data)
                                          }
                                          color="error"
                                          disabled={deletingCart}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </div>
                                    )
                                  : cart?.length > 1 && (
                                      <div className="dltCrt">
                                        <IconButton
                                          onClick={() =>
                                            handelDeleteCarthandler(data)
                                          }
                                          color="error"
                                          disabled={deletingCart}
                                        >
                                          <Delete />
                                        </IconButton>
                                      </div>
                                    )}
                              </div>
                              <div className="lstCntnr border-bottom my-3 p-2">
                                <label>Kit Types:</label>
                                <ol>
                                  {user.kits.map((kit, kitIdx) => (
                                    <li key={kitIdx}>
                                      <div>
                                        <span className="ktNm">{kit}</span>
                                        <span className="ktPrc">
                                          {currencyFomatter(0)}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ol>
                              </div>
                              <div className="lstCntnr border-bottom my-3 p-2">
                                <label>Reports:</label>
                                <ol>
                                  {user.reports.map((report, reportIdx) => (
                                    <li className="tCap" key={reportIdx}>
                                      <div>
                                        <span className="ktNm">
                                          {report.report_name}
                                        </span>
                                        <span className="ktPrc">
                                          {currencyFomatter(report.price)}
                                        </span>
                                      </div>
                                    </li>
                                  ))}
                                </ol>
                              </div>
                            </div>
                          )
                        )}

                        <p className=" d-flex align-items-center justify-content-between">
                          <span>Total:</span>
                          <b>{currencyFomatter(data.total_price)}</b>
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>
              <div className="pdtActnInfos">
                <form
                  onSubmit={couponFormik.handleSubmit}
                  className="coupon_wrapper"
                >
                  <CustomInput
                    label="Have an Coupon"
                    placeholder="Enter Coupon Here"
                    type="text"
                    name="coupon_code"
                    onChange={couponFormik.handleChange}
                    onBlur={couponFormik.handleBlur}
                    value={couponFormik.values.coupon_code}
                    formik={couponFormik}
                  />

                  <CustomButton
                    // disabled={isLoading}
                    label={couponLoading ? "Appling.." : "Apply"}
                    type="submit"
                  />
                </form>

                <div className="bg-light p-3 rounded mb-3">
                  <h5 className="">Order Payment Details</h5>
                  <hr />

                  <div className="d-flex align-items-center justify-content-between">
                    <p>Order Amount</p>
                    <p>
                      <b>{currencyFomatter(orderInfo.subTotal)}</b>
                    </p>
                  </div>

                  <div className="d-flex align-items-center justify-content-between">
                    <p>
                      Discount
                      {formik.values.coupon_code && (
                        <>
                          <br />
                          <small className="text-muted">
                            Coupon - ({formik.values.coupon_code?.coupon_code})
                          </small>
                        </>
                      )}
                    </p>
                    <p>
                      <b>-{currencyFomatter(orderInfo.discount)}</b>
                    </p>
                  </div>
                  {cart[0]?.is_vat_include && (
                    <>
                      <div className="d-flex align-items-center justify-content-between">
                        <p>BTW (Excl):</p>
                        <p>
                          <b>
                            {currencyFomatter(
                              orderInfo.subTotal - orderInfo.discount
                            )}
                          </b>
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <p>BTW (Incl):</p>
                        <p>
                          <b>{currencyFomatter(orderInfo.total)}</b>
                        </p>
                      </div>
                    </>
                  )}
                  <hr />
                  <div className="d-flex align-items-center justify-content-between">
                    <p>Order Total</p>
                    <p>
                      <b>{currencyFomatter(orderInfo.total.toFixed(0))}</b>
                    </p>
                  </div>
                </div>
                <div className="customInputWrpr">
                  <FormControl>
                    <FormLabel>Payment Method</FormLabel>
                    <RadioGroup
                      defaultValue={formik.values.payment_method}
                      name="payment_method"
                      onChange={formik.handleChange}
                      className="d-flex flex-row gap-3 flex-wrap"
                    >
                      <FormControlLabel
                        value="online"
                        control={
                          <Radio
                            disabled={
                              formik.values.coupon_code?.percentage == 100
                            }
                            checked={formik.values.payment_method === "online"}
                          />
                        }
                        label="Online"
                      />
                      <FormControlLabel
                        value="offline"
                        control={
                          <Radio
                            checked={formik.values.payment_method === "offline"}
                          />
                        }
                        label="Offline"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formik.touched?.payment_method &&
                  formik.errors?.payment_method ? (
                    <p className="errMsg">{formik.errors.payment_method}</p>
                  ) : (
                    ""
                  )}
                </div>
                {user?.account_type !== "sport_person" && (
                  <div className="customInputWrpr">
                    <FormControl>
                      <FormLabel>Shipping Method</FormLabel>
                      <RadioGroup
                        defaultValue={formik.values.separate_shipping}
                        name="separate_shipping"
                        onChange={formik.handleChange}
                        className="d-flex flex-row gap-3 flex-wrap"
                      >
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="My Address"
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Client's Respective Address"
                        />
                      </RadioGroup>
                    </FormControl>
                    {formik.touched?.separate_shipping &&
                    formik.errors?.separate_shipping ? (
                      <p className="errMsg">
                        {formik.errors.separate_shipping}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}

                <div
                  className={`shpInfo d-flex align-items-center justify-content-between gap-2 flex-wrap ${
                    user?.account_type !== "sport_person" &&
                    formik.values.separate_shipping == "true" &&
                    "d-none"
                  }`}
                >
                  <h5 className="actnInfoTtle">Shipping Address</h5>
                  <Button
                    onClick={() =>
                      setAddressModal({ open: true, data: null, type: "new" })
                    }
                  >
                    Add New Address
                  </Button>
                </div>

                <hr />
                <form onSubmit={formik.handleSubmit}>
                  <div className="row mt-4">
                    <div
                      className={`adrwLstWrpr mb-3 ${
                        user?.account_type !== "sport_person" &&
                        formik.values.separate_shipping == "true" &&
                        "d-none"
                      }`}
                    >
                      {address?.length > 0 &&
                        address?.map((data, index) => (
                          <div
                            className={`adrsSlct ${
                              data?.is_default ? "dfltAdrs" : ""
                            }`}
                            key={index}
                          >
                            <input
                              id={data.id}
                              type="radio"
                              hidden
                              name="shipping"
                              onChange={formik.handleChange}
                              value={data.id}
                              checked={
                                Number(formik.values.shipping) ===
                                Number(data.id)
                              }
                              onBlur={() => {
                                formik.setFieldTouched("shipping", true);
                              }}
                            />
                            <label for={data.id}>
                              <h5 className="w-75">
                                {data.first_name}&nbsp;{data.last_name}
                              </h5>
                              <p>
                                <span>{data.address}</span>
                                <br />
                                <span>
                                  {data.city},&nbsp;{data.state},{data.country}
                                  &nbsp;
                                  {data.pincode}
                                </span>
                                <br />
                                Ph: {"+" + data.phone}
                              </p>
                            </label>
                          </div>
                        ))}
                      {formik.touched.shipping && formik.errors.shipping ? (
                        <p className="errMsg text-danger">
                          <small>{formik.errors.shipping}</small>
                        </p>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="col-md-12">
                      <CustomButton
                        disabled={
                          isLoading || bulkOrderLoading || cart?.length > 1
                        }
                        label={
                          isLoading || bulkOrderLoading
                            ? "Redirecting to payment..."
                            : "Proceed to Pay"
                        }
                        type="submit"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="text-center">
              {cartLoading || isFetching ? (
                <div className="w-100 d-flex justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <div className="emptyCartWrapper">
                  <img src="/assets/svg/emptycart.svg" />
                  <p className="my-4">Oops! your cart seems to be empty!</p>
                  <Button
                    onClick={() => navigate("/followkits")}
                    size="large"
                    className="brdrBtn"
                  >
                    Browse Follow Kits
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <CustomDialog
        open={addressModal.open}
        handleClose={() => setAddressModal({ open: false, data: null })}
        maxWidth="md"
        className="blogDialog"
      >
        <AddAddress
          data={addressModal.data}
          type={addressModal.type}
          handleClose={() => setAddressModal({ open: false, data: null })}
        />
      </CustomDialog>
    </>
  );
};

export default FollowCheckout;
