import { appApi } from "../api/api";

export const grouppingApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroupList: builder.query({
      query: () => `/api/group_index`,
      providesTags: ["Group"],
    }),

    createTeamGroup: builder.mutation({
      query: (values) => ({
        url: "/api/new_group",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Group"],
    }),

    updateTeamGroup: builder.mutation({
      query: (values) => ({
        url: `/api/update_group/${values.id}`,
        method: "PATCH",
        body: values.body,
      }),
      invalidatesTags: ["Group"],
    }),

    deleteTeamGroup: builder.mutation({
      query: (arg) => ({
        url: `/api/destroy_group/${arg}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Group"],
    }),
  }),
});

export const {
  useGetGroupListQuery,
  useCreateTeamGroupMutation,
  useUpdateTeamGroupMutation,
  useDeleteTeamGroupMutation,
} = grouppingApi;
