import React, { useState } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Login.css";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../Components/Layout/AuthLayout";
import { useChangePasswordMutation } from "../../services/auth/authApi";
import { toast } from "react-hot-toast";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [passVisible, setPassVisible] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const { id, token } = useParams();

  const validationArray = Yup.object().shape({
    password: Yup.string()
      .min(2, "Too Short!")
      .required("Password is a required field")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    conf_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password must match")
      .required("Confirm Password is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      var1: id || "",
      var2: token || "",
      password: "",
      conf_password: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      try {
        const response = await changePassword(values)
          .unwrap()
          .then((res) => {
            if (res) {
              console.log("response", res);
              toast.success(res.message);
              setTimeout(() => {
                navigate("/login");
              }, 1500);
            }
          });
        return response;
      } catch (error) {
        console.log(error);
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Please check your email!");
        }
      }
    },
  });

  console.log("formik value", formik);

  return (
    <AuthLayout showButton={false}>
      <div className="lgnFormContainer authWrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr">
            <h2>Change Password</h2>
            <p>Please enter your new password</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Password"
                placeholder="Enter password"
                name="password"
                type={passVisible ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                formik={formik}
                autoComplete="new-password"
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setPassVisible(!passVisible)}
                  >
                    {passVisible ? "visibility_off" : "visibility"}
                  </IconButton>
                }
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Confirm Password"
                placeholder="Re-enter your password"
                name="conf_password"
                type={confirmPass ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.conf_password}
                formik={formik}
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setConfirmPass(!confirmPass)}
                  >
                    {confirmPass ? "visibility_off" : "visibility"}
                  </IconButton>
                }
                autoComplete="new-password"
              />
            </div>
            <div className="col-md-12 text-center">
              <CustomButton
                label={isLoading ? "Changing Password..." : "Change Password"}
                disabled={isLoading}
                type="submit"
              />
              {/* <p className="mt-3 rdrTxt">
                <Link to="/login">Back to Login</Link>
              </p> */}
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ChangePassword;
