import React, { useEffect, useMemo, useState } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "@mui/material";
import CustomDialog from "../../Components/Molecules/CustomDialog";

import {
  useGetSportsPersonsQuery,
  useGetProSportsPersonsQuery,
  useGetDocSportsPersonsQuery,
} from "../../services/sportsPerson/sportsPersonApi";
import { useSelector } from "react-redux";
import {
  useCreateTeamGroupMutation,
  useDeleteTeamGroupMutation,
  useGetGroupListQuery,
  useUpdateTeamGroupMutation,
} from "../../services/Groupping/grouppingApi";
import { toast } from "react-hot-toast";
import GrouppinForm from "./GrouppinForm";
import ViewGroup from "./ViewGroup";

const Grouping = () => {
  const navigate = useNavigate();

  const [groupDialog, setGroupDialog] = useState({
    status: false,
    data: null,
    type: "new",
  });

  const { user } = useSelector((state) => state.auth);

  const { data: groupData } = useGetGroupListQuery(undefined, {
    refetchOnFocus: true,
  });

  const [createTeamGroup, { isLoading: cLoading }] =
    useCreateTeamGroupMutation();
  const [updateTeamGroup, { isLoading: uLoading }] =
    useUpdateTeamGroupMutation();

  const validationArray = Yup.object().shape({
    group_name: Yup.string()
      .min(3, "Too Short!")
      .max(20, "Too Long!")
      .trim()
      .required("Group name is required."),
    users: Yup.array().min(1, "Please select team members."),
  });

  const formik = useFormik({
    initialValues: {
      group_name: "",
      users: [],
      type: "sport_person",
    },
    enableReinitialize: true,
    validationSchema: validationArray,
    onSubmit: async (values, actions) => {
      if (groupDialog.type === "edit") {
        try {
          const res = await updateTeamGroup({
            id: groupDialog.data.id,
            body: {
              group_name: values.group_name,
              users: values.users,
              Status: true,
              type: values.type,
            },
          })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Group successfully updated.");
                setGroupDialog(false);
              }
            });
          return res;
        } catch (error) {
          console.log(error);
          if (error?.data) {
            toast.error(error?.data?.group_name);
          } else {
            toast.error("something went wrong");
          }
        }
      } else {
        try {
          const res = await createTeamGroup({
            group_name: values.group_name,
            users: values.users,
            Status: true,
            type: values.type,
          })
            .unwrap()
            .then((res) => {
              if (res) {
                formik.resetForm();
                toast.success("Group successfully created.");
                setGroupDialog(false);
              }
            });
          return res;
        } catch (error) {
          console.log(error);
          if (error?.data?.detail) {
            formik.resetForm();

            toast.error(error.data.detail);
          } else {
            formik.resetForm();
            toast.error("something went wrong");
          }
        }
      }
    },
  });

  const { data: man_person_list } = useGetSportsPersonsQuery(
    { id: user?.id, type: formik.values.type },
    {
      skip: user?.account_type !== "team_manager" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: pro_persons_list } = useGetProSportsPersonsQuery(
    { id: user?.id, type: formik.values.type },
    {
      skip: user?.account_type !== "professional" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: doc_person_list } = useGetDocSportsPersonsQuery(
    { id: user?.id, type: formik.values.type },
    {
      skip: user?.account_type !== "doctor" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const list = useMemo(() => {
    switch (user?.account_type) {
      case "professional":
        return pro_persons_list;
      case "team_manager":
        return man_person_list;
      case "doctor":
        return doc_person_list;
      default:
        return [];
    }
  }, [
    user,
    formik.values.type,
    doc_person_list,
    pro_persons_list,
    man_person_list,
  ]);

  console.log(list);

  useEffect(() => {
    if (groupDialog.type !== "new" && groupDialog.data) {
      formik.setFieldValue("group_name", groupDialog.data?.group_name);
      formik.setFieldValue("type", groupDialog.data?.type);
      formik.setFieldValue(
        "users",
        groupDialog.data?.users?.map(({ id }) => id) || []
      );
    }
  }, [groupDialog]);

  const [deleteTeamGroup, { isLoading: deletingGroup }] =
    useDeleteTeamGroupMutation();

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2 pb-4">
            <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
              <h5 className="d-flex align-items-center">
                <span className="material-symbols-rounded me-2">groups</span>
                Grouping
              </h5>
              <Button
                className="brdrBtn"
                onClick={() =>
                  setGroupDialog((pre) => {
                    return {
                      ...pre,
                      status: true,
                    };
                  })
                }
              >
                <span className="material-symbols-rounded">group_add</span>
                Add New Group
              </Button>
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th className="w-50">Group Name</th>
                    <th>No. of People</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!!groupData?.length ? (
                    groupData.map((data, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{data.group_name}</td>
                        <td>{data.users.length || 0}</td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <CustomButton
                              label={
                                <>
                                  <span className="material-symbols-rounded me-2">
                                    open_in_new
                                  </span>
                                  View
                                </>
                              }
                              btnType="secondary"
                              size="small"
                              btnclassName="w-100 me-2"
                              onClick={() =>
                                setGroupDialog({
                                  status: true,
                                  type: "view",
                                  data: data,
                                })
                              }
                            />
                            <CustomButton
                              label={
                                <>
                                  <span className="material-symbols-rounded me-2">
                                    edit
                                  </span>
                                  Edit
                                </>
                              }
                              btnType="secondary"
                              size="small"
                              btnclassName="w-100"
                              onClick={() =>
                                setGroupDialog({
                                  status: true,
                                  type: "edit",
                                  data: data,
                                })
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="text-center">
                        No Records Found!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={groupDialog.status}
        handleClose={() => {
          formik.resetForm();
          setGroupDialog({
            status: false,
            data: null,
            type: "new",
          });
        }}
      >
        {groupDialog.type === "view" ? (
          <ViewGroup {...groupDialog} />
        ) : (
          <GrouppinForm
            groupDialog={groupDialog}
            formik={formik}
            sports_persons_list={list}
            cLoading={cLoading}
            uLoading={uLoading}
          />
        )}
      </CustomDialog>
    </>
  );
};

export default Grouping;
