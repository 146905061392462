import React, { useState } from "react";
import "./Header.css";
import { Badge, Button, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutUserMutation } from "../../../services/auth/authApi";
import { baseUrl } from "../../../services/api/api";
import { urlBuilder } from "../../../Utils/Utils";
import { useGetCartListQuery } from "../../../services/products/ProductsApi";
import useMediaQuery from "@mui/material/useMediaQuery";
import Notification from "../Notification";
import { logOut } from "../../../features/auth/authSlice";
import ChangePassword from "./ChangePassword";
import { LockReset } from "@mui/icons-material";

const Header = (props) => {
  const { user } = useSelector((state) => state.auth);
  const matches = useMediaQuery("(max-width:1200px)");
  const [logoutUser] = useLogoutUserMutation();
  const { data: cart } = useGetCartListQuery();
  const dispatch = useDispatch();
  const [resetPass, setResetPass] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const logout = await logoutUser()
        .unwrap()
        .then((res) => {
          if (res) {
            dispatch(logOut());
            toast.success("You have successfully logged out.");
            navigate("/login");
          }
        });
      return logout;
    } catch (error) {
      if (error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("You have failed to logging out.");
      }
      return console.error(error);
    }
  };

  return (
    <>
      <div className="header d-flex align-items-center justify-content-between">
        {matches ? (
          <div className="respNavActn">
            <Button onClick={props.toggleDrawer("left", true)}>
              <span className="material-symbols-rounded">menu</span>
            </Button>
            <Link to="/">
              <img src="/assets/images/logo.png" />
            </Link>
          </div>
        ) : (
          ""
        )}

        {/* <div className="srchBox">
        <input placeholder="search here" />
        <Button>
          <span className="material-symbols-rounded">search</span>
        </Button>
      </div> */}
        <div></div>
        <div className="rtHdrVw d-flex align-items-center">
          <IconButton onClick={() => navigate("/infoPages/faq")}>
            <img src="/assets/svg/support.svg" />
          </IconButton>
          {/* <IconButton>
          <Badge badgeContent={4} color="secondary">
            <img src="/assets/svg/chat.svg" />
          </Badge>
        </IconButton> */}
          {/* <IconButton>
          <Badge badgeContent={4} color="secondary">
            <img src="/assets/svg/notification.svg" />
          </Badge>
        </IconButton> */}
          <Notification />
          {user?.account_type !== "sport_person" && (
            <IconButton
              onClick={() => navigate("/products/my-cart")}
              color="primary"
            >
              <Badge badgeContent={cart?.length || 0} color="secondary">
                <span className="material-symbols-rounded">shopping_cart</span>
              </Badge>
            </IconButton>
          )}

          <Button
            className="acntDropBtn"
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ width: 28, height: 28 }}
              alt={user?.first_name}
              src={urlBuilder(user?.profile_image)}
            />
            {!matches && (
              <>
                <div className="acntNmInfo">
                  <p style={{ maxWidth: "80px" }} className="text-truncate">
                    {user?.first_name} {user?.last_name}
                  </p>
                  {/* <small>Admin</small> */}
                </div>
                <span className="material-symbols-rounded">expand_more</span>
              </>
            )}
          </Button>
          <Menu
            className="prflDrop"
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={() => navigate("/profile")}>
              <Avatar
                className="me-3"
                alt={user?.first_name}
                src={urlBuilder(user?.profile_image)}
              />
              My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => setResetPass(true)}>
              <ListItemIcon>
                <LockReset fontSize="small" />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={() => handleLogout()}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </div>
      {resetPass && (
        <ChangePassword
          mount={resetPass}
          handleClose={() => setResetPass(false)}
        />
      )}
    </>
  );
};

export default Header;
