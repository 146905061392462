import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import {
  alpha,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Tooltip,
} from "@mui/material";
import FollowkitForm from "./FollowkitForm";
import { useSelector } from "react-redux";
import { useGetFollowKitsQuery } from "../../services/followKits/followKits";
import FollowKitReport from "./FollowKitReport";
import { followStatus } from "../../Utils/Utils";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import ViewFollowOrder from "./ViewFollowOrder";
import { KeyboardArrowDown, ShoppingCart } from "@mui/icons-material";
import styled from "@emotion/styled";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledMenu } from "../../Utils/UiLayers";

const Followkits = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [followKitDialog, setFollowKitDialog] = useState(false);
  const [reportDialog, setReportDialog] = useState({
    view: false,
    data: null,
    type: "new",
  });
  const { data: followkit, isLoading } = useGetFollowKitsQuery(user?.id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [addPopup, setAddPopup] = useState({
    mount: false,
    data: null,
  });

  const [followkitError, setFollowKitError] = useState(false);

  //pagination
  const [page, setPage] = React.useState(0);
  const [listPerPage, setListPerPage] = useState(10);

  const count = !!followkit?.length
    ? Math.ceil(followkit?.length / listPerPage)
    : 0;

  const handlePageChange = (_, value) => {
    setPage(value);
  };

  const getCurrentList = useMemo(() => {
    let lastIdxItem = page * listPerPage;
    let firstIdxItem = lastIdxItem - listPerPage;

    !!followkit?.length && page === 0 && setPage(1);

    return !!followkit?.length && followkit?.slice(firstIdxItem, lastIdxItem);
  }, [page, followkit]);

  const findPercentage = (data) => {
    let percent =
      ((followStatus.findIndex((ele) => ele.id == data.follow_status) + 1) /
        followStatus.length) *
      100;
    return percent;
  };

  const handlePopupClose = () => setAddPopup({ mount: false, data: null });

  useEffect(() => {
    if (followkit?.length > 0 && user?.account_type === "sport_person") {
      let indexValue = followkit.findIndex(
        (ele) => ele.submitfollowupkit === null
      );

      if (indexValue >= 0) {
        setFollowKitError(true);
      }
    }
  }, [followkit]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [tableIndex, setTableIndex] = React.useState([]);

  const handleTableIndex = (index) => {
    const isExistsIndex = tableIndex.findIndex((el) => el === index);
    return isExistsIndex >= 0
      ? setTableIndex((pre) => [...pre.filter((el) => el !== index)])
      : setTableIndex((pre) => [...pre, index]);
  };

  const isActiveRow = (index) =>
    tableIndex.findIndex((el) => el === index) >= 0 ? true : false;

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard prflPageWrpr">
          <div className="pt-2">
            <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between">
              <h5 className="d-flex align-items-center">
                <span className="material-symbols-rounded me-2">
                  medical_services
                </span>
                Follow kits
              </h5>
              {user?.account_type === "sport_person" ? (
                <Button
                  className="brdrBtn"
                  onClick={() => setFollowKitDialog(true)}
                >
                  <span className="material-symbols-rounded">
                    shopping_cart
                  </span>
                  Order kits
                </Button>
              ) : (
                <>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    startIcon={<ShoppingCart />}
                    endIcon={<KeyboardArrowDown />}
                    className="text-light"
                  >
                    Order kits
                  </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        setFollowKitDialog(true);

                        handleClose();
                      }}
                      disableRipple
                    >
                      Single Order
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate("/followkits/bulkorder");
                        handleClose();
                      }}
                      disableRipple
                    >
                      Bulk Order
                    </MenuItem>
                  </StyledMenu>
                </>
              )}
            </div>
            <hr />
          </div>
          {followkitError && (
            <div className="showFlwKitErr">
              <ul className="mb-0">
                <li>
                  To provide accurate information for the follow-up kit, please
                  fill out the online form completely and correctly.
                </li>
                <li>
                  Followkit Report is not submitted for one or more orders.
                  Click on the red icon{" "}
                  <span className="btnIconFlw material-symbols-rounded">
                    edit_note
                  </span>{" "}
                  under the action field on the right corner of this table to
                  submit the form. For more information about the test, click on
                  the info button at the top of the form.
                </li>
              </ul>
            </div>
          )}
          {user.account_type === "sport_person" ? (
            <>
              <div className="row">
                <div className="table-responsive">
                  <table className="table flwKitTbl">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Order ID</th>
                        <th>Ordered By</th>
                        <th>Kit Details</th>
                        <th>Requested Date</th>
                        <th>Payment Status</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!getCurrentList?.length ? (
                        getCurrentList.map((data, index) => (
                          <tr key={index} style={{ verticalAlign: "top" }}>
                            <td>{index + 1}</td>
                            <td>{data.id}</td>
                            <td>
                              {data?.fkitorderitems[0]?.followkit?.ordered_by}{" "}
                              {data.submitfollowupkit === null && (
                                <>
                                  <br />
                                  <span className="text-danger">
                                    <small>
                                      Followkit report submission is pending for
                                      this order
                                    </small>
                                  </span>
                                </>
                              )}
                            </td>
                            <td className="kitDtlsVw">
                              {data?.fkitorderitems.map((fdata, index) => (
                                <>
                                  <div className="kitDtlRw" key={index}>
                                    <span>{fdata.followkit.req_id}</span>
                                    <span className="pe-3">:</span>
                                    <span>{fdata.followkit.kit_name}</span>
                                  </div>
                                </>
                              ))}
                            </td>
                            <td>{data.date}</td>
                            <td>
                              <span
                                className={`status ${
                                  data.payment_status === "unpaid"
                                    ? "pending"
                                    : "accepted"
                                }`}
                              >
                                {data.payment_status}
                              </span>
                            </td>
                            <td
                              className={`kitStWrp ${
                                data.payment_status === "unpaid"
                                  ? "ppnd"
                                  : findPercentage(data) <= 25
                                  ? "yellow"
                                  : findPercentage(data) <= 50
                                  ? "orange"
                                  : findPercentage(data) <= 75
                                  ? "lightGreen"
                                  : "green"
                              }`}
                            >
                              <div className="prgrsWrapper">
                                <div
                                  className="prgrsBr"
                                  style={{
                                    width:
                                      data.payment_status === "unpaid"
                                        ? 0
                                        : `${findPercentage(data)}% `,
                                  }}
                                ></div>
                              </div>
                              <div className={`kitStatus`}>
                                {data.payment_status === "unpaid"
                                  ? "Payment Pending"
                                  : followStatus[
                                      followStatus.findIndex(
                                        (ele) => ele.id == data.follow_status
                                      )
                                    ]?.name}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-2">
                                {user?.account_type === "sport_person" &&
                                  data?.fkitorderitems[0]?.followkit?.status ===
                                    "open" && (
                                    <>
                                      <Tooltip title="Followkit Report">
                                        <IconButton
                                          onClick={() => {
                                            data.submitfollowupkit
                                              ? setReportDialog({
                                                  view: true,
                                                  data: data,
                                                  type: "view",
                                                })
                                              : setReportDialog({
                                                  view: true,
                                                  data: data,
                                                  type: "new",
                                                });
                                          }}
                                          color={
                                            data.submitfollowupkit
                                              ? "primary"
                                              : "error"
                                          }
                                        >
                                          <span className="material-symbols-rounded">
                                            {data.submitfollowupkit
                                              ? "open_in_new"
                                              : "edit_note"}
                                          </span>
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                <Tooltip title="Order Details">
                                  <IconButton
                                    onClick={() =>
                                      setAddPopup({
                                        mount: true,
                                        data: data,
                                        type: "followKit",
                                      })
                                    }
                                    color={
                                      data.submitfollowupkit
                                        ? "primary"
                                        : "secondary"
                                    }
                                  >
                                    <span className="material-symbols-rounded">
                                      summarize
                                    </span>
                                  </IconButton>
                                </Tooltip>
                                {data?.payment_status === "unpaid" &&
                                  user?.id === data?.user && (
                                    <Tooltip title="Pay now">
                                      <IconButton
                                        color="primary"
                                        onClick={() =>
                                          navigate(`/payment/${data?.ref_no}`)
                                        }
                                      >
                                        <span className="material-symbols-rounded">
                                          payments
                                        </span>
                                      </IconButton>
                                    </Tooltip>
                                  )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={9}>
                            {isLoading
                              ? "Fetching Records"
                              : "No records found!"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <Pagination
                    size="medium"
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    count={count}
                    page={page}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="table-responsive">
                  <table className="table flwKitTbl">
                    <thead>
                      <tr>
                        <th></th>
                        <th>S.No</th>
                        <th>Order ID</th>
                        <th>Ordered By</th>
                        <th>Ordered Type</th>
                        <th>Group Name</th>
                        <th>Payment Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!getCurrentList?.length ? (
                        getCurrentList.map((data, index) => (
                          <React.Fragment key={index}>
                            <tr
                              style={{
                                verticalAlign: "middle",
                              }}
                              className={`${
                                isActiveRow(index) ? "bg-light" : ""
                              }`}
                            >
                              <td>
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleTableIndex(index)}
                                >
                                  {isActiveRow(index) ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </td>
                              <td>
                                {page > 1 && page - 1}
                                {index + 1}
                              </td>
                              <td>{data.order_group_id}</td>
                              <td>{data?.ordered_by}</td>
                              <td className="text-capitalize">
                                {data?.group_name ? "bulk" : "single"}
                              </td>
                              <td className="text-capitalize">
                                {data?.group_name || "-"}
                              </td>
                              <td>
                                <div className="d-flex align-items-center gap-5">
                                  <span
                                    className={`status ${
                                      data.order_details[0].payment_status ==
                                      "unpaid"
                                        ? "pending"
                                        : "accepted"
                                    }`}
                                  >
                                    {data.order_details[0].payment_status}
                                  </span>
                                  {data.order_details[0].payment_status ===
                                    "unpaid" &&
                                    user?.email === data?.ordered_by && (
                                      <Tooltip title="Pay now">
                                        <IconButton
                                          color="primary"
                                          onClick={() => {
                                            if (
                                              user?.account_type ===
                                              "sport_person"
                                            ) {
                                              navigate(
                                                `/payment/${data?.ref_no}`
                                              );
                                            } else {
                                              navigate(
                                                `/payment/bulk/${data.order_group_id}`
                                              );
                                            }
                                          }}
                                        >
                                          <span className="material-symbols-rounded">
                                            payments
                                          </span>
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={7} className={`border-bottom-0 p-0`}>
                                <Collapse
                                  in={isActiveRow(index)}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                    marginTop={2}
                                  >
                                    Order Information
                                  </Typography>
                                  <div className="table-responsive">
                                    <table className="table flwKitTbl">
                                      <thead>
                                        <tr>
                                          <td>S.NO</td>
                                          <td>Order Id</td>
                                          <td>Client ID</td>
                                          <td>Requested Date</td>
                                          <td>Kit Details</td>
                                          <td>Status</td>
                                          <td>Action</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {data.order_details.map(
                                          (order, orderIdx) => (
                                            <tr key={orderIdx}>
                                              <td>{orderIdx + 1}</td>
                                              <td>{order.id}</td>
                                              <td>
                                                {order?.fkitorderitems[0]
                                                  ?.followkit
                                                  .account_subtype ===
                                                "patient" ? (
                                                  <Link
                                                    to={`/patients/view/${order?.fkitorderitems[0]?.followkit.user}`}
                                                  >
                                                    {
                                                      order?.fkitorderitems[0]
                                                        .followkit.user_ref_no
                                                    }
                                                  </Link>
                                                ) : (
                                                  <Link
                                                    to={`/sportpersons/view/${order?.fkitorderitems[0]?.followkit.user}`}
                                                  >
                                                    {
                                                      order?.fkitorderitems[0]
                                                        .followkit.user_ref_no
                                                    }
                                                  </Link>
                                                )}
                                              </td>

                                              <td>{order.date}</td>
                                              <td className="kitDtlsVw">
                                                {order?.fkitorderitems.map(
                                                  (fdata, index) => (
                                                    <>
                                                      <div
                                                        className="kitDtlRw"
                                                        key={index}
                                                      >
                                                        <span>
                                                          {
                                                            fdata.followkit
                                                              .req_id
                                                          }
                                                        </span>
                                                        <span className="pe-3">
                                                          :
                                                        </span>
                                                        <span>
                                                          {
                                                            fdata.followkit
                                                              .kit_name
                                                          }
                                                        </span>
                                                      </div>
                                                    </>
                                                  )
                                                )}
                                                {order.submitfollowupkit ===
                                                  null && (
                                                  <>
                                                    <br />
                                                    <span className="text-danger">
                                                      <small>
                                                        Followkit report
                                                        submission is pending
                                                        for this order
                                                      </small>
                                                    </span>
                                                  </>
                                                )}
                                              </td>
                                              <td
                                                className={`kitStWrp ${
                                                  order.payment_status ===
                                                  "unpaid"
                                                    ? "ppnd"
                                                    : findPercentage(order) <=
                                                      25
                                                    ? "yellow"
                                                    : findPercentage(order) <=
                                                      50
                                                    ? "orange"
                                                    : findPercentage(order) <=
                                                      75
                                                    ? "lightGreen"
                                                    : "green"
                                                }`}
                                              >
                                                <td>
                                                  <div className="prgrsWrapper">
                                                    <div
                                                      className="prgrsBr"
                                                      style={{
                                                        width:
                                                          order.payment_status ===
                                                          "unpaid"
                                                            ? 0
                                                            : `${findPercentage(
                                                                order
                                                              )}% `,
                                                      }}
                                                    ></div>
                                                  </div>
                                                  <div className={`kitStatus`}>
                                                    {order.payment_status ===
                                                    "unpaid"
                                                      ? "Payment Pending"
                                                      : followStatus[
                                                          followStatus.findIndex(
                                                            (ele) =>
                                                              ele.id ==
                                                              order.follow_status
                                                          )
                                                        ]?.name}
                                                  </div>
                                                </td>
                                              </td>
                                              <td>
                                                <div className="d-flex align-items-center gap-2">
                                                  {user?.account_type ===
                                                    "sport_person" &&
                                                  order?.fkitorderitems[0]
                                                    ?.followkit?.status ==
                                                    "open" ? (
                                                    <>
                                                      <Tooltip title="Followkit Report">
                                                        <IconButton
                                                          onClick={() => {
                                                            order.submitfollowupkit
                                                              ? setReportDialog(
                                                                  {
                                                                    view: true,
                                                                    data: order,
                                                                    type: "view",
                                                                  }
                                                                )
                                                              : setReportDialog(
                                                                  {
                                                                    view: true,
                                                                    data: order,
                                                                    type: "new",
                                                                  }
                                                                );
                                                          }}
                                                          color={
                                                            order.submitfollowupkit
                                                              ? "primary"
                                                              : "error"
                                                          }
                                                        >
                                                          <span className="material-symbols-rounded">
                                                            {order.submitfollowupkit
                                                              ? "open_in_new"
                                                              : "edit_note"}
                                                          </span>
                                                        </IconButton>
                                                      </Tooltip>
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <Tooltip title="Order Details">
                                                    <IconButton
                                                      onClick={() =>
                                                        setAddPopup({
                                                          mount: true,
                                                          data: order,
                                                          type: "followKit",
                                                        })
                                                      }
                                                      color={
                                                        order.submitfollowupkit
                                                          ? "primary"
                                                          : "secondary"
                                                      }
                                                    >
                                                      <span className="material-symbols-rounded">
                                                        summarize
                                                      </span>
                                                    </IconButton>
                                                  </Tooltip>
                                                </div>
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </Collapse>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center" colSpan={7}>
                            {isLoading
                              ? "Fetching Records"
                              : "No records found!"}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="col-12 d-flex justify-content-end">
                  <Pagination
                    size="medium"
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                    count={count}
                    page={page}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {addPopup.mount && (
        <CustomDialog
          avoidBG
          open={addPopup.mount}
          handleClose={handlePopupClose}
        >
          <ViewFollowOrder {...addPopup} handleClose={handlePopupClose} />
        </CustomDialog>
      )}

      <FollowkitForm dialog={followKitDialog} setDialog={setFollowKitDialog} />

      {reportDialog && (
        <FollowKitReport dialog={reportDialog} setDialog={setReportDialog} />
      )}
    </>
  );
};

export default Followkits;
