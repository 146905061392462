import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";
import MultipleSelect from "../../Components/Atoms/MultipleSelect";

const GrouppinForm = ({
  groupDialog,
  formik,
  sports_persons_list,
  cLoading,
  uLoading,
}) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="text-center">
      <img src="/assets/svg/invite.svg" className="mb-3" />
      <h3 className="mb-2 text-capitalize">
        {groupDialog.type === "new" || groupDialog.type === undefined
          ? "Grouping"
          : groupDialog.data?.group_name}
      </h3>
      <p className="">
        {groupDialog.type === "new" || groupDialog.type === undefined
          ? "Group your team"
          : groupDialog.data?.type === "patient"
          ? "This group contains only patients."
          : "This group contains only sport persons."}
      </p>
      <p>
        <small>
          Users who are available in other groups, their group names will be
          displayed next to the name of the user
        </small>
      </p>

      <form onSubmit={formik.handleSubmit}>
        <div className="row text-start">
          {(groupDialog.type === "new" || groupDialog.type === undefined) && (
            <div className="customInputWrpr">
              <FormControl className="">
                {/* <FormLabel>Members Type</FormLabel> */}
                {console.log(formik.values.type)}
                <RadioGroup
                  name="type"
                  value={formik.values.type}
                  defaultValue={formik.values.type}
                  onChange={formik.handleChange}
                  className="d-flex flex-row gap-3 flex-wrap justify-content-center"
                >
                  <FormControlLabel
                    value="sport_person"
                    control={<Radio />}
                    label="Sport Person"
                  />
                  {user?.account_type == "doctor" && (
                    <FormControlLabel
                      value="patient"
                      control={<Radio />}
                      label="Patient"
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </div>
          )}

          <div className="col-md-12">
            <CustomInput
              label="Group Name"
              placeholder="Enter group name"
              type="text"
              name="group_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.group_name}
              formik={formik}
              readOnly={groupDialog.type === "view" ? true : false}
            />
          </div>
          {console.log("sports_persons_list", sports_persons_list)}
          <div className="col-md-12">
            <MultipleSelect
              label="Team Members"
              name="users"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.users}
              formik={formik}
              withChip={true}
              options={
                sports_persons_list?.map((el) => {
                  return {
                    name: `${el.first_name} ${el.last_name}`,
                    chipContent: el.group,
                    value: el.id,
                  };
                }) ?? []
              }
              readOnly={groupDialog.type === "view" ? true : false}
            />
          </div>
          <div className="col-md-12">
            {groupDialog.type !== "view" && (
              <CustomButton
                label={
                  (console.log(groupDialog.type, "news"),
                  groupDialog.type === "new" || groupDialog.type === undefined
                    ? "Create Group"
                    : "Update Group")
                }
                type="submit"
                disabled={cLoading || uLoading}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default GrouppinForm;
