import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./StripeCheckout";
import "./Stripe.css";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../../services/api/api";
import Loaders from "../../Molecules/Loaders";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Stripe({ refNo }) {
  const [clientSecret, setClientSecret] = useState("");
  const { id, type } = useParams();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (type === "bulk") {
      fetch(`${baseUrl}/api/bulk_payment_intent/${id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: "",
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    } else {
      fetch(`${baseUrl}/api/StripeIntentCreate/${refNo ? refNo : id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: "",
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="StripeWrapper dshStCard">
      <div>
        <h4 className="text-center mb-4">Enter Your Card Details!</h4>
        {!clientSecret && <Loaders />}
        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}
      </div>
    </div>
  );
}
