import { Tune } from "@mui/icons-material";
import { Badge, Divider, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";
import { addSearchValue } from "../../features/search/searchSlice";
import { baseUrl } from "../../services/api/api";
import { urlBuilder } from "../../Utils/Utils";
import ERSearchModal, { ERSearchSchema } from "./ERSearchModal";
import EbookSearch from "./EbookSearch";

const Ebooks = ({
  getIndividualDetails,
  isError,
  isLoading,
  isFetching,
  getEbooks,
  list,
}) => {
  const dispatch = useDispatch();
  // Reports list with filters

  const [filterModal, setFilterModal] = useState(false);
  const { ebooks_search: previousFilter } = useSelector(
    (state) => state.search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
        category: "",
      },
      sort: "",
      list_per_page: 10,
      page: 1,
    },
    validationSchema: ERSearchSchema,
    onSubmit: async (values, actions) => {
      if (isLoading) return;
      const notify = toast.loading("Getting ebooks..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: "ebooks_search", values }));
      try {
        const query = await getEbooks({ ...values })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, { id: notify });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getEbooks(previousFilter);
      } else {
        await getEbooks({ ...searchFormik.values });
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.date1 && count++;
    searchFormik.values.filter.date2 && count++;
    searchFormik.values.filter.category && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  return (
    <div>
      <div className="tpHdrVw d-flex flex-wrap align-items-center mb-4 gap-2">
        <CustomSearch
          inputName="search"
          query={searchFormik.values.search}
          handleChange={searchFormik.handleChange}
          handleSubmit={(e) => {
            e.preventDefault();
            searchFormik.handleSubmit();
            searchFormik.setFieldValue("page", 1);
          }}
          containerClasses="ms-md-auto small"
          disabled={isFetching || isLoading}
        />
        <Badge badgeContent={handelBageContent} color="secondary">
          <CustomButton
            size="small"
            onClick={() => setFilterModal(true)}
            label={
              <>
                <Tune className="me-2" />
                Filter
              </>
            }
          />
        </Badge>
      </div>
      {!!list?.report?.length ? (
        <div className="dtl_card_wrapper">
          {list.report?.map((el, idx) => (
            <div key={idx} className="dtl_card">
              <div
                className="dtl_card_image cursorPointer"
                onClick={() => getIndividualDetails(el.id)}
              >
                {urlBuilder(el.ebook_image) && (
                  <img src={urlBuilder(el.ebook_image)} alt="" />
                )}
              </div>
              <h5>{el.ebook_name}</h5>
              <div className="d-flex align-items-center justify-content-between px-3">
                <Link
                  className="dwnldIcon"
                  to=""
                  onClick={() =>
                    window.open(`${baseUrl}/api/admin/ebook_pdf/${el.id}`)
                  }
                >
                  <span className="material-symbols-rounded">save_alt</span>
                </Link>
                <Link to="" onClick={() => getIndividualDetails(el.id)}>
                  View more
                  <span className="material-symbols-rounded">
                    trending_flat
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <h6 className="text-center text-muted">No Ebooks Found</h6>
      )}
      {!!list?.report?.length && (
        <>
          <Divider className="mt-5" />
          <TablePagination
            component="div"
            count={list?.count || 0}
            page={searchFormik.values.page - 1}
            onPageChange={handleChangePage}
            rowsPerPage={searchFormik.values.list_per_page}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
      {filterModal && (
        <EbookSearch
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
    </div>
  );
};

export default Ebooks;
