import React, { useEffect, useState } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./Login.css";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import AuthLayout from "../../Components/Layout/AuthLayout";
import { toast } from "react-hot-toast";
import { useLoginUserMutation } from "../../services/auth/authApi";
import secureLocalStorage from "react-secure-storage";
import CustomRadio from "../../Components/Atoms/CustomRadio";

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [passVisible, setPassVisible] = useState(false);

  const validationArray = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email")
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    password: Yup.string().min(2, "Too Short!").required("Required"),
    rememberme: 0,
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      method: "mail",
      rememberme: [],
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      if (values.rememberme.includes("remember")) {
        let cred = {
          email: values.email,
          password: values.password,
        };
        secureLocalStorage.setItem("rem", JSON.stringify(cred));
      } else {
        secureLocalStorage.removeItem("rem");
      }
      try {
        await loginUser(values)
          .unwrap()
          .then(async (res) => {
            if (res) {
              await secureLocalStorage.setItem("2FA", res[0]);
              navigate("/twofactor");
            }
          });
      } catch (error) {
        console.error(error, "eroress");
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong");
        }
        throw new Error(error);
      }
    },
  });

  useEffect(() => {
    if (
      searchParams.get("user") != null &&
      searchParams.get("user") != undefined
    ) {
      formik.setFieldValue("email", searchParams.get("user"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (secureLocalStorage.getItem("rem") != undefined) {
      let values = JSON.parse(secureLocalStorage.getItem("rem"));
      formik.setFieldValue("email", values.email);
      formik.setFieldValue("password", values.password);
      if (!formik.values.rememberme.includes("remember")) {
        formik.values.rememberme.push("remember");
      }
    }
  }, []);

  return (
    <AuthLayout showButton={true}>
      <div className="lgnFormContainer">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr">
            <h2>Login</h2>
            <p>Welcome! Please login to continue</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Email"
                placeholder="Enter email"
                type="text"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Password"
                placeholder="Enter password"
                name="password"
                type={passVisible ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                formik={formik}
                autoComplete="new-password"
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setPassVisible(!passVisible)}
                  >
                    {passVisible ? "visibility_off" : "visibility"}
                  </IconButton>
                }
              />
            </div>

            <div className="col-md-12 chkFrgSctn d-flex align-items-center justify-content-between">
              <CustomCheckBox
                name="rememberme"
                label="Remember Me"
                value="remember"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                checked={formik.values.rememberme.includes("remember")}
              />
              <Link to="/forgotPassword">Forgot Password?</Link>
            </div>
            <div className="col-md-12 mt-3 mb-0">
              <CustomRadio
                label="OTP sent through"
                name="method"
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                value={formik.values.method}
                options={[
                  { label: "Email", value: "mail" },
                  { label: "Phone", value: "message" },
                ]}
              />
            </div>
            <div className="col-md-12 text-center">
              <CustomButton
                label={`${isLoading ? "You are logging in..." : "Login"}`}
                type="submit"
                disabled={isLoading}
              />
              <p className="mt-3 rdrTxt">
                Don't have an account? <Link to="/signup">Register here</Link>{" "}
              </p>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
