import React from "react";
import "./CustomCheck.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const CustomCheckBox = (props) => {
  return (
    <div className={`cstmChkBox ${props.containerClasses}`}>
      <FormControlLabel
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        control={<Checkbox checked={props.checked} disabled={props.disabled} />}
        label={props.label}
      />
    </div>
  );
};

export default CustomCheckBox;
