import React, { useEffect, useState } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import CustomDialog from "../../../Components/Molecules/CustomDialog";

import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { useUpdateUserMutation } from "../../../services/auth/authApi";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(2, "Too Short!")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    )
    .required("Password is a required field"),
  conf_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match")
    .when("password", {
      is: (password) => !!password,
      then: yup.string().required("confirmed Password is a required field"),
    }),
});

const ChangePassword = ({ mount, handleClose }) => {
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const [passVisible, setPassVisible] = useState(false);
  const [confirmPass, setConfirmPass] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const formik = useFormik({
    initialValues: {
      password: "",
      conf_password: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      const formValues = new FormData();

      for (let key in values) {
        formValues.append(key, values[key]);
      }
      formValues.append("id", user?.id);
      formValues.append("email", user?.email);

      try {
        await updateUser(formValues)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Password changed successfully.");
              actions.resetForm();
              handleClose();
            }
          });
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong");
        }
        console.log("rejected", error);
        throw new Error(error);
      }
    },
  });

  return (
    <CustomDialog
      open={mount}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
    >
      <div className="text-center">
        <div className="circle_back mb-2">
          <span className="material-symbols-rounded">lock_reset</span>
        </div>
        <h4 className="mb-2">Change Password</h4>
      </div>
      <div className="inrHckForm">
        <form onSubmit={formik.handleSubmit} className="p-4">
          <div className="row">
            <div className="col-md-12">
              <CustomInput
                label="Password"
                placeholder="Enter password"
                name="password"
                type={passVisible ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                formik={formik}
                autoComplete="new-password"
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setPassVisible(!passVisible)}
                  >
                    {passVisible ? "visibility_off" : "visibility"}
                  </IconButton>
                }
              />
            </div>
            <div className="col-md-12">
              <CustomInput
                label="Confirm Password"
                placeholder="Re-enter your password"
                name="conf_password"
                type={confirmPass ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.conf_password}
                formik={formik}
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setConfirmPass(!confirmPass)}
                  >
                    {confirmPass ? "visibility_off" : "visibility"}
                  </IconButton>
                }
                autoComplete="new-password"
              />
            </div>

            <div className="col-md-12 ">
              <div className="flscrnBtnWrpr gap-2 d-flex align-items-center ">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  onClick={handleClose}
                  btnClass="flex-fill"
                />
                <CustomButton
                  disabled={isLoading}
                  type="submit"
                  label={isLoading ? "submitting.." : "submit"}
                  btnClass="flex-fill"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </CustomDialog>
  );
};

export default ChangePassword;
