import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetCartListQuery,
  useCreateOrderMutation,
  useLazyGetCouponInfoQuery,
  useCreateBulkProductOrderMutation,
} from "../../services/products/ProductsApi";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";
import "./Checkout.css";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";

import CustomInput from "../../Components/Atoms/CustomInput";

import CustomButton from "../../Components/Atoms/CustomButton";
import { useGetAddressQuery } from "../../services/auth/authApi";
import AddAddress from "../ProfileDetails/AddAddress";
import CustomDialog from "../../Components/Molecules/CustomDialog";

const Checkout = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const { data: cart, isFetching } = useGetCartListQuery(undefined, {
    refetchOnFocus: true,
  });

  const [createOrder, { isLoading }] = useCreateOrderMutation();
  const [createBulkOrder, { isLoading: bulkLoading }] =
    useCreateBulkProductOrderMutation();

  const [addressModal, setAddressModal] = useState({
    data: null,
    type: "new",
    open: false,
  });

  const { data: address } = useGetAddressQuery(user?.id, {
    refetchOnFocus: true,
  });

  const validationArray =
    user.account_type !== "sport_person"
      ? Yup.object().shape({
          shipping: Yup.string().when("separate_shipping", {
            is: false,
            then: Yup.string().required("Address is a required field"),
          }),
          payment_method: Yup.string().required(
            "Plese choose the Payment method."
          ),
          separate_shipping: Yup.boolean().required(
            "Plese choose the shipping method"
          ),
        })
      : Yup.object().shape({
          shipping: Yup.string().required("Address is a required field"),
          payment_method: Yup.string().required(
            "Plese choose the Payment method."
          ),
          separate_shipping: Yup.boolean().required(
            "Plese choose the shipping method"
          ),
        });

  const formik = useFormik({
    initialValues: {
      shipping: "",
      payment_method: "online",
      coupon_code: "",
      separate_shipping: false,
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      if (user?.account_type !== "sport_person") {
        try {
          await createBulkOrder({
            ...values,

            separate_shipping:
              values.separate_shipping == "true" ? true : false,
            coupon_code: values.coupon_code
              ? values.coupon_code.coupon_code
              : "",
          })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Order Placed Successfully!");
                if (values.payment_method === "online") {
                  navigate(`/payment/${res?.data?.ref_no}`);
                } else {
                  navigate(`/user/purchased`);
                }
              }
            });
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        }
      } else {
        try {
          await createOrder({
            shipping: values.shipping,
            payment_method: values.payment_method,
            coupon_code: values.coupon_code
              ? values.coupon_code.coupon_code
              : "",
          })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Order Placed Successfully!");
                if (values.payment_method === "online") {
                  navigate(`/payment/${res?.data?.ref_no}`);
                } else {
                  navigate(`/user/purchased`);
                }
              }
            });
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        }
      }
    },
  });

  useEffect(() => {
    if (address?.length > 0) {
      const defaultAddress = address.findIndex(
        (address) => address.is_default === true
      );
      formik.setFieldValue(
        "shipping",
        address[defaultAddress !== -1 ? defaultAddress : 0]?.id
      );
    }
  }, [address]);

  const [getCouponInfo, { isLoading: couponLoading, isError: couponError }] =
    useLazyGetCouponInfoQuery();

  const couponFormik = useFormik({
    initialValues: {
      coupon_code: "",
    },
    validationSchema: Yup.object().shape({
      coupon_code: Yup.string().required("please provide valid coupon code!"),
    }),
    onSubmit: async (values) => {
      try {
        await getCouponInfo(values.coupon_code)
          .unwrap()
          .then((res) => {
            if (res?.user?.id === user?.id) {
              formik.setFieldValue("coupon_code", res);
              toast.success("Coupon applied successfully!");
            } else {
              formik.setFieldValue("coupon_code", "");
              toast.error("Invalid Coupon!");
            }
          });
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Invalid Coupon!");
        }
        formik.setFieldValue("coupon_code", "");
        console.log("rejected", error);
        throw new Error(error);
      }
    },
  });

  const orderSummaryHandler = () => {
    const subTotal = cart?.reduce((subTotal, product, index) => {
      subTotal += product.product.price * product.quantity;
      return subTotal;
    }, 0);

    const discount = formik.values.coupon_code
      ? formik.values.coupon_code?.type === "percentage"
        ? subTotal -
          (
            subTotal -
            (subTotal * formik.values.coupon_code?.percentage) / 100
          ).toFixed(2)
        : formik.values.coupon_code?.amount
      : 0;

    const total = subTotal - discount;

    return { subTotal, discount, total };
  };

  const orderInfo = useMemo(orderSummaryHandler, [
    cart,
    formik.values.coupon_code,
  ]);

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <div className="d-flex align-items-center">
              <span className="material-symbols-rounded me-2 text-muted">
                shopping_bag
              </span>

              <h5 className="mb-0">Checkout</h5>
            </div>
            <hr />
          </div>

          <div className="checkoutWrapper">
            <div className="chckOtPdtDtls">
              {!!cart?.length &&
                cart?.map((data, idx) => (
                  <>
                    <div className="chckOtCard">
                      <div className="imgCntnr">
                        <img
                          src={data?.product?.productimages[0]?.product_image}
                        />
                      </div>
                      <div className="pdtDtlsCnrt">
                        <h5>{data.product.product_name}</h5>
                        <p>
                          <span>Price:</span>{" "}
                          {currencyFomatter(data.product.price)}
                        </p>
                        <p>
                          <span>Qty:</span> {data.quantity}
                        </p>
                        <p>
                          <span>Total Price:</span>{" "}
                          <b>
                            {currencyFomatter(
                              data.product.price * data.quantity
                            )}
                          </b>
                        </p>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            <div className="pdtActnInfos">
              <form
                onSubmit={couponFormik.handleSubmit}
                className="coupon_wrapper"
              >
                <CustomInput
                  label="Have an Coupon"
                  placeholder="Enter Coupon Here"
                  type="text"
                  name="coupon_code"
                  onChange={couponFormik.handleChange}
                  onBlur={couponFormik.handleBlur}
                  value={couponFormik.values.coupon_code}
                  formik={couponFormik}
                />

                <CustomButton
                  // disabled={isLoading}
                  label={couponLoading ? "Appling.." : "Apply"}
                  type="submit"
                />
              </form>

              <div className="bg-light p-3 rounded mb-3">
                <h5 className="">Order summary</h5>
                <hr />

                <div className="d-flex align-items-center justify-content-between">
                  <p>Subtotal</p>
                  <p>
                    <b>{currencyFomatter(orderInfo.subTotal)}</b>
                  </p>
                </div>

                <div className="d-flex align-items-center justify-content-between">
                  <p>
                    Discount amount
                    {formik.values.coupon_code && (
                      <>
                        <br />
                        <small className="text-muted">
                          Coupon - ({formik.values.coupon_code?.coupon_code})
                        </small>
                      </>
                    )}
                  </p>
                  <p>
                    <b>{currencyFomatter(orderInfo.discount)}</b>
                  </p>
                </div>
                <hr />
                <div className="d-flex align-items-center justify-content-between ">
                  <p>Total amount</p>
                  <p>
                    <b>{currencyFomatter(orderInfo.total)}</b>
                  </p>
                </div>
              </div>

              <div className="customInputWrpr">
                <FormControl>
                  <FormLabel>Payment Method</FormLabel>
                  <RadioGroup
                    defaultValue={formik.values.payment_method}
                    name="payment_method"
                    onChange={formik.handleChange}
                    className="d-flex flex-row gap-3 flex-wrap"
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="Online"
                    />
                    <FormControlLabel
                      value="offline"
                      control={<Radio />}
                      label="Offline"
                    />
                  </RadioGroup>
                </FormControl>
                {formik.touched?.payment_method &&
                formik.errors?.payment_method ? (
                  <p className="errMsg">{formik.errors.payment_method}</p>
                ) : (
                  ""
                )}
              </div>

              {user?.account_type !== "sport_person" && (
                <div className="customInputWrpr">
                  <FormControl>
                    <FormLabel>Shipping Method</FormLabel>
                    <RadioGroup
                      defaultValue={formik.values.separate_shipping}
                      name="separate_shipping"
                      onChange={formik.handleChange}
                      className="d-flex flex-row gap-3 flex-wrap"
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="My Address"
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Client's Respective Address"
                      />
                    </RadioGroup>
                  </FormControl>
                  {formik.touched?.separate_shipping &&
                  formik.errors?.separate_shipping ? (
                    <p className="errMsg">{formik.errors.separate_shipping}</p>
                  ) : (
                    ""
                  )}
                </div>
              )}

              <div
                className={`shpInfo d-flex align-items-center justify-content-between gap-2 flex-wrap ${
                  user?.account_type !== "sport_person" &&
                  formik.values.separate_shipping == "true" &&
                  "d-none"
                }`}
              >
                <h5 className="actnInfoTtle">Shipping Address</h5>
                <Button
                  onClick={() =>
                    setAddressModal({ open: true, data: null, type: "new" })
                  }
                >
                  Add New Address
                </Button>
              </div>
              <hr />
              <form onSubmit={formik.handleSubmit}>
                <div className="row mt-4">
                  <div
                    className={`adrwLstWrpr mb-3 ${
                      user?.account_type !== "sport_person" &&
                      formik.values.separate_shipping == "true" &&
                      "d-none"
                    }`}
                  >
                    {address?.length > 0 &&
                      address?.map((data, index) => (
                        <div
                          className={`adrsSlct ${
                            data?.is_default ? "dfltAdrs" : ""
                          }`}
                          key={index}
                        >
                          <input
                            id={data.id}
                            type="radio"
                            hidden
                            name="shipping"
                            onChange={formik.handleChange}
                            value={data.id}
                            checked={
                              Number(formik.values.shipping) === Number(data.id)
                            }
                            onBlur={() => {
                              formik.setFieldTouched("shipping", true);
                            }}
                          />
                          <label for={data.id}>
                            <h5>
                              {data.first_name}&nbsp;{data.last_name}
                            </h5>
                            <p>
                              <span>{data.address}</span>
                              <br />
                              <span>
                                {data.city},&nbsp;{data.state},{data.country}
                                &nbsp;
                                {data.pincode}
                              </span>
                              <br />
                              Ph: {"+" + data.phone}
                            </p>
                          </label>
                        </div>
                      ))}
                    {formik.touched.shipping && formik.errors.shipping ? (
                      <p className="errMsg">{formik.errors.shipping}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <CustomButton
                    disabled={isLoading || bulkLoading}
                    label={
                      isLoading || bulkLoading
                        ? "Redirecting to payment..."
                        : "Proceed to Pay"
                    }
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={addressModal.open}
        handleClose={() => setAddressModal({ open: false, data: null })}
        maxWidth="md"
        className="blogDialog"
      >
        <AddAddress
          data={addressModal.data}
          type={addressModal.type}
          handleClose={() => setAddressModal({ open: false, data: null })}
        />
      </CustomDialog>
    </>
  );
};

export default Checkout;
