import React, { useState, useEffect } from "react";
import { Alert, Button, IconButton } from "@mui/material";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  currencyFomatter,
  followStatus,
  urlBuilder,
} from "../../../Utils/Utils";
import "../../Checkout/Checkout.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { useGetAddressQuery } from "../../../services/auth/authApi";
import AddAddress from "../../ProfileDetails/AddAddress";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import {
  useGetFollowCartListQuery,
  useGetFollowKitPricingsQuery,
  useCreateFollowOrderMutation,
  useDeleteFollowCartListMutation,
} from "../../../services/followKits/followKits";
import { Delete } from "@mui/icons-material";

const PreBuyCheckout = ({ handleNext, setRefNo }) => {
  const navigate = useNavigate();

  const { id } = useParams();

  const {
    data: cart,
    isFetching,
    isLoading: gettingCartDetails,
  } = useGetFollowCartListQuery(undefined, {
    refetchOnFocus: true,
  });

  const { data: reports } = useGetFollowKitPricingsQuery(undefined, {
    refetchOnFocus: true,
  });

  const [createOrder, { isLoading }] = useCreateFollowOrderMutation();

  const [addressModal, setAddressModal] = useState({
    data: null,
    type: "new",
    open: false,
  });

  const { data: address } = useGetAddressQuery(id, {
    refetchOnFocus: true,
  });

  const validationArray = Yup.object().shape({
    shipping: Yup.string().required("Address is a required field"),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      shipping: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      await createOrder({ ...values, coupon_code: "" })
        .unwrap()
        .then((res) => {
          if (res) {
            // toast.success("Order Placed Successfully!");
            setRefNo(res?.data?.ref_no);
            handleNext();
          }
        })
        .catch((error) => {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        });
    },
  });

  useEffect(() => {
    if (address?.length > 0) {
      formik.setFieldValue("shipping", address[0]?.id);
    }
  }, [address]);

  useEffect(() => {
    if (cart?.length > 0) {
      formik.setFieldValue("id", cart[0]?.id);
    }
  }, [cart]);

  const [deleteFollowCartList, { isLoading: deletingCart }] =
    useDeleteFollowCartListMutation();

  const handelDeleteCarthandler = async ({ id }) => {
    if (deletingCart) return;

    const notify = toast.loading("Deleting cart item...");

    try {
      await deleteFollowCartList(id)
        .unwrap()
        .then((res) => {
          if (res) {
            console.log(res);
            toast.success("Cart removed successfully", { id: notify });
          }
        });
    } catch (error) {
      console.log(error);
      if (error?.data?.detail) {
        toast.error(error.data.detail, {
          id: notify,
        });
      } else {
        toast.error("Failed to delete cart item", { id: notify });
      }
    }
  };

  const [btwnum, setBtwnum] = useState(0);
  const [total, setTotal] = useState();

  useEffect(() => {
    if (!cart) return;
    if (cart) {
      let totalPercentage = 0;

      for (const item of cart) {
        for (const pricingItem of item.pricing) {
          const { price, vat } = pricingItem;
          const percentage = (vat * price) / 100;
          totalPercentage += percentage;
        }
        const a = item?.is_vat_include ? item.total_price + totalPercentage : item?.total_price;
        setTotal(a.toFixed(0));
      }
      setBtwnum(totalPercentage.toFixed(0));
    }
  }, [cart]);

  return (
    <>
      <div className="">
        <div className="dshStCard">
          <div className="text-center">
            <h3 className="mb-4 justify-content-center">Checkout</h3>
          </div>
          {cart?.length > 1 && (
            <div className="alert_wrapper mb-4">
              <Alert severity="error">
                If more than one cart Please remove unwanted cart before
                checking out to avoid any issues.
              </Alert>
            </div>
          )}

          {!gettingCartDetails && !cart?.length && (
            <div className="alert_wrapper mb-4">
              <Alert severity="error">
                Please add cart before checking out.
              </Alert>
            </div>
          )}

          <div className="checkoutWrapper flwKtChkWrpr justify-content-between">
            <div className="chckOtPdtDtls">
              {!!cart?.length &&
                cart?.map((data, idx) => (
                  <React.Fragment key={idx}>
                    <div className="chckOtCard">
                      <div className="pdtDtlsCnrt">
                        <div className="lstCntnr">
                          <div className="d-flex align-items-center justify-content-between ">
                            <label>Kit Types:</label>
                            {cart?.length > 1 && (
                              <div className="dltCrt mb-3">
                                <IconButton
                                  onClick={() => handelDeleteCarthandler(data)}
                                  color="error"
                                  disabled={deletingCart}
                                >
                                  <Delete />
                                </IconButton>
                              </div>
                            )}
                          </div>
                          <ol>
                            {data?.fkitcartitems?.map((kits, kidx) => (
                              <li key={kidx}>
                                <div>
                                  <span className="ktNm">
                                    {kits.followkit.kit_name}
                                  </span>
                                  <span className="ktPrc">
                                    {currencyFomatter(0)}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ol>
                        </div>
                        <div className="lstCntnr">
                          <label>Reports:</label>
                          <ol>
                            {data?.pricing?.map((kits, kidx) => (
                              <li key={kidx} className="tCap">
                                <div>
                                  <span className="ktNm">
                                    {kits.report_name}
                                  </span>
                                  <span className="ktPrc">
                                    {currencyFomatter(kits.price)}
                                  </span>
                                </div>
                              </li>
                            ))}
                          </ol>
                        </div>
                        <p className="d-flex align-items-center justify-content-between">
                          <span>Order Amount:</span>
                          <b>{currencyFomatter(data.total_price)}</b>
                        </p>

                        {!!data?.is_vat_include && (
                          <>
                            <p className=" d-flex align-items-center justify-content-between">
                              <span>BTW (Excl):</span>
                              <b>{currencyFomatter(data.total_price)}</b>
                            </p>
                            <p className=" d-flex align-items-center justify-content-between">
                              <span>BTW (Incl):</span>
                              <b>{currencyFomatter(total)}</b>
                            </p>
                          </>
                        )}
                        <p className="sbTtle d-flex align-items-center justify-content-between">
                          <span>Total:</span>

                          <b>{currencyFomatter(total)}</b>
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
            </div>
            <div className="pdtActnInfos">
              <div className="shpInfo d-flex align-items-center justify-content-between">
                <h5 className="actnInfoTtle">Shipping Address</h5>
                <Button
                  onClick={() =>
                    setAddressModal({ open: true, data: null, type: "new" })
                  }
                >
                  Add New Address
                </Button>
              </div>
              <hr />
              <form onSubmit={formik.handleSubmit}>
                <div className="row mt-4">
                  <div className="adrwLstWrpr mb-3">
                    {address?.length > 0 &&
                      address?.map((data, index) => (
                        <div className="adrsSlct" key={index}>
                          <input
                            id={data.id}
                            type="radio"
                            hidden
                            name="shipping"
                            onChange={formik.handleChange}
                            value={data.id}
                            checked={
                              Number(formik.values.shipping) === Number(data.id)
                            }
                            onBlur={() => {
                              formik.setFieldTouched("shipping", true);
                            }}
                          />
                          <label htmlFor={data.id}>
                            <h5>
                              {data.first_name}&nbsp;{data.last_name}
                            </h5>
                            <p>
                              <span>{data.address}</span>
                              <br />
                              <span>
                                {data.city},&nbsp;{data.state},{data.country}
                                &nbsp;
                                {data.pincode}
                              </span>
                              <br />
                              Ph: {"+" + data.phone}
                            </p>
                          </label>
                        </div>
                      ))}
                    {formik.touched.shipping && formik.errors.shipping ? (
                      <p className="errMsg">{formik.errors.shipping}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <CustomButton
                    disabled={isLoading || cart?.length > 1 || !cart?.length}
                    label={isLoading ? "Placing Order..." : "Place Order"}
                    type="submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog
        open={addressModal.open}
        handleClose={() => setAddressModal({ open: false, data: null })}
        maxWidth="md"
        className="blogDialog"
      >
        <AddAddress
          data={addressModal.data}
          type={addressModal.type}
          handleClose={() => setAddressModal({ open: false, data: null })}
        />
      </CustomDialog>
    </>
  );
};

export default PreBuyCheckout;
