import { appApi } from "../api/api";

export const sportsPersonApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getSportsPersons: builder.query({
      query: (arg) => `/api/team_manager_view/${arg.id}/${arg.type}`,
      providesTags: ["SportsPersons"],
    }),
    getProSportsPersons: builder.query({
      query: (arg) => `/api/professional_view/${arg.id}/${arg.type}`,
      providesTags: ["SportsPersons"],
    }),
    getDocSportsPersons: builder.query({
      query: (arg) => `/api/doctor_view/${arg.id}/${arg.type}`,
      providesTags: ["SportsPersons"],
    }),

    getSportCategory: builder.query({
      query: (id) => `/api/sport_categeory`,
    }),
    getSportCategoryById: builder.query({
      query: (id) => `/api/sport_categeory/${id}`,
    }),

    getSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/admin_sp_search`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),

    getTMSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/team_sp_search/${values.id}`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),
    getProSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/prof_sp_search/${values.id}`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),
    getDoctorSportPersonsListSearch: builder.query({
      query: (values) => ({
        url: `/api/doctor_sp_search/${values.id}`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "SportsPersons",
              ...result?.report?.map(({ id }) => ({
                type: "SportsPersons",
                id,
              })),
            ]
          : ["SportsPersons"],
    }),
    getPatientSearch: builder.query({
      query: (values) => ({
        url: `/api/patient_index`,
        method: "POST",
        body: values,
      }),
      providesTags: (result, error, arg) =>
        result
          ? [
              "Patients",
              ...result?.report?.map(({ id }) => ({ type: "Patients", id })),
            ]
          : ["Patients"],
    }),
  }),
});

export const {
  useGetSportsPersonsQuery,
  useGetDocSportsPersonsQuery,
  useGetProSportsPersonsQuery,
  useLazyGetSportsPersonsQuery,
  useLazyGetProSportsPersonsQuery,
  useLazyGetDocSportsPersonsQuery,
  useGetSportCategoryQuery,
  useGetSportCategoryByIdQuery,
  useLazyGetSportCategoryByIdQuery,

  useLazyGetSportPersonsListSearchQuery,
  useLazyGetTMSportPersonsListSearchQuery,
  useLazyGetDoctorSportPersonsListSearchQuery,
  useLazyGetProSportPersonsListSearchQuery,
  useLazyGetPatientSearchQuery,
} = sportsPersonApi;
