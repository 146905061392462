import {
  CloudUpload,
  Delete,
  Info,
  InfoRounded,
  OpenInNew,
  Tune,
} from "@mui/icons-material";
import {
  Badge,
  Button,
  IconButton,
  Pagination,
  TablePagination,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomSearch from "../../../Components/Atoms/CustomSearch/CustomSearch";
import CustomDialog from "../../../Components/Molecules/CustomDialog";
import { PdfViewerFSModal } from "../../../Components/Molecules/PdfViewer";
import { addSearchValue } from "../../../features/search/searchSlice";
import { baseUrl } from "../../../services/api/api";
import {
  useDeleteUserUploadedreportMutation,
  useGetUserUploadedReportsQuery,
  useLazySearchUploadedReportsQuery,
  useUploadUserReportMutation,
  useDeleteUserReportRequestMutation,
} from "../../../services/reports/reportsApi";
import SearchReportModal, { reportSearchSchema } from "./SearchReportModal";
import * as Yup from "yup";
import { handleSort } from "../../../Utils/Utils";
import { useGetCurrentUserQuery } from "../../../services/auth/authApi";

const UploadedReports = ({ type }) => {
  const navigate = useNavigate();
  const [fileInputErrors, setFileInputErrors] = useState();

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { id } = useParams();

  const [uploadUserReport] = useUploadUserReportMutation();
  const [deleteUserReport, { isLoading: deletingReport }] =
    useDeleteUserUploadedreportMutation();
  const [deleteReportRequest, { isLoading: deletingReqeustloading }] =
    useDeleteUserReportRequestMutation();

  const [infoPopup, setInfoPopup] = useState({
    data: null,
    show: false,
  });

  // Reports list with filters

  const [getReports, { data: list, isError, isLoading, isFetching }] =
    useLazySearchUploadedReportsQuery();

  const [filterModal, setFilterModal] = useState(false);
  const [rejectPopup, setRejectPopup] = useState({
    id: null,
    show: false,
  });
  const previousFilter = useSelector(
    (state) => state.search.user_report_search
  );

  const searchFormik = useFormik({
    initialValues: {
      search: "",
      filter: {
        date1: "",
        date2: "",
      },
      sort: "",
      list_per_page: type !== "prebuy" ? 10 : "",
      page: 1,
    },
    validationSchema: reportSearchSchema,
    onSubmit: async (values, actions) => {
      if (isLoading || isFetching) return;
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ name: "user_report_search", values }));

      try {
        const query = await getReports({
          id: type == "prebuy" ? id : user?.id,
          ...values,
        })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  const validationArray = Yup.object().shape({
    reason: Yup.string()
      .min(5, "Minimum 5 characters required")
      .matches(/[^\s*]/g, "This field cannot contain only blankspaces")
      .required("Required"),
  });

  const deleteFormik = useFormik({
    initialValues: {
      reason: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      try {
        await deleteReportRequest({ id: rejectPopup.id, ...values })
          .unwrap()
          .then((res) => {
            if (res.success == true) {
              toast.success("Delete Request Sent");
              handleRejectPopupClose();
            } else if (res.success == false) {
              toast.error(res.message);
              handleRejectPopupClose();
            }
          });
      } catch (err) {
        toast.error(err);
      }
    },
  });

  const handleRejectPopupClose = () => {
    deleteFormik.resetForm();
    setRejectPopup({
      id: null,
      show: false,
    });
  };

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (previousFilter) {
        await getReports({
          id: type == "prebuy" ? id : user?.id,
          ...previousFilter,
        });
      } else {
        await getReports({
          id: type == "prebuy" ? id : user?.id,
          ...searchFormik.values,
        });
      }
    })();
  }, []);

  const handleChangePage = async (event, newPage) => {
    if (isLoading || isFetching) return;
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    if (isLoading || isFetching) return;
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.date1 && count++;
    searchFormik.values.filter.date2 && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;

    return count;
  }, [searchFormik.values]);

  const [pdf, setPdf] = useState({ title: "", url: null });
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!showModal) setPdf({ title: "", url: null });
  }, [showModal]);

  const handleShowPdf = (data, status) => {
    setShowModal(status);
    setPdf({
      title: data?.report_name,
      url: !!data?.report?.length ? `${baseUrl}${data?.report}` : null,
    });
  };

  const handleUploadReports = async (event) => {
    const report = event.target.files[0];
    const { name, files } = event.target;
    // console.log(name)

    let fileErr;
    if (files[0]) {
      if (files[0].type !== "application/pdf") {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid file format. Only PDF files are allowed.",
        }));
        fileErr = {
          [name]: "Invalid file format. Only PDF files are allowed.",
        };
      } else if (files[0].size > 5 * 1024 * 1024) {
        setFileInputErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "File size should be less than 5MB.",
        }));
        fileErr = {
          [name]: "File size should be less than 5MB.",
        };
      } else {
        setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        fileErr = {
          [name]: "",
        };
      }
    } else {
      setFileInputErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
      fileErr = {
        [name]: "",
      };
    }

    const report_name = report.name.replace(/\.[^/.]+$/, "");
    const report_formData = new FormData();
    const date = new Date().toISOString().slice(0, 10);

    report_formData.append("report_name", report_name);
    report_formData.append("report", report);
    report_formData.append("date", date);
    report_formData.append("userId", user?.id);

    const notify = fileErr?.report ? "" : toast.loading("Uploading report..");

    try {
      if (fileErr?.report) {
        return;
      }

      const uploadedReport = await uploadUserReport(report_formData).unwrap();

      if (uploadedReport) {
        toast.dismiss(notify);
      }
    } catch (error) {
      if (error?.data?.detail) {
        toast.error(error.data.detail, {
          id: notify,
        });
      } else {
        toast.error(`Failed to upload report.`, { id: notify });
      }
    } finally {
      event.target.value = "";
    }
  };

  const handleDeleteReport = async ({ id }) => {
    if (deletingReport) return;
    const notify = toast.loading("Deleting report..");
    try {
      const uploadedReport = await deleteUserReport(id).unwrap();
      if (uploadedReport) {
        toast.success("Report deleted successfully", { id: notify });
      }
    } catch (error) {
      if (error?.data?.detail) {
        toast.error(error.data.detail, {
          id: notify,
        });
      } else {
        toast.error(`Failed to delete report.`, { id: notify });
      }
    }
  };

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Report Name", key: "report_name", sortable: true },
    { name: "Date", key: "date", sortable: true },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <>
            <div className="pt-2 pb-2 d-flex align-items-center justify-content-between gap-2">
              <h5 className="d-flex align-items-center mb-0">
                <img src="/assets/svg/reports.svg" className="me-2" />
                Upload Reports
              </h5>
              {type === "prebuy" && (
                <Button
                  variant="contained"
                  component="label"
                  className="text-light"
                >
                  <CloudUpload className="me-2" />
                  Upload
                  <input
                    onChange={handleUploadReports}
                    type="file"
                    id="upld_report"
                    accept="application/pdf"
                    hidden
                    multiple
                  />
                </Button>
              )}
            </div>
            <hr />
          </>

          {type !== "prebuy" && (
            <div className="mb-4">
              <label htmlFor="upld_report" className="d-flex flex-column">
                <div className="upldWrpr text-center w-100">
                  <span className="material-symbols-rounded">cloud_upload</span>
                  <p>{"Upload your report"}</p>
                </div>
                <input
                  onChange={handleUploadReports}
                  type="file"
                  id="upld_report"
                  accept="application/pdf"
                  hidden
                  multiple
                  name="report"
                />
                {fileInputErrors?.report && (
                  <div className="errMsgs">{fileInputErrors?.report}</div>
                )}
              </label>
            </div>
          )}

          {type !== "prebuy" && (
            <div className="prvslyUpldTtle d-flex justify-content-between align-items-center mb-4 border-bottom pb-3">
              <h3>Previously Uploaded Reports</h3>
              <div className="tpHdrVw d-flex flex-wrap align-items-center justify-content-end mb-2 gap-2 flex-fill">
                <CustomSearch
                  inputName="search"
                  query={searchFormik.values.search}
                  handleChange={searchFormik.handleChange}
                  handleSubmit={(e) => {
                    e.preventDefault();
                    searchFormik.handleSubmit();
                    searchFormik.setFieldValue("page", 1);
                  }}
                  containerClasses="ms-md-auto small"
                  disabled={isFetching || isLoading}
                />
                <Badge badgeContent={handelBageContent} color="secondary">
                  <CustomButton
                    size="small"
                    onClick={() => setFilterModal(true)}
                    label={
                      <>
                        <Tune className="me-2" />
                        Filter
                      </>
                    }
                  />
                </Badge>
              </div>
            </div>
          )}
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  {tableHeads.map(({ sortable, key, name }, idx) => (
                    <th key={idx}>
                      <div
                        className={`d-flex align-items-center ${
                          sortable ? "sortable" : ""
                        } ${
                          searchFormik.values.sort.includes(key) ? "active" : ""
                        }`}
                        onClick={() => {
                          if (sortable && !isLoading && !isFetching) {
                            return handleSort(searchFormik, key || "");
                          }
                        }}
                      >
                        {name}
                        {sortable && type !== "prebuy" && (
                          <span className="material-symbols-rounded">
                            {searchFormik.values.sort.includes(key)
                              ? searchFormik.values.sort.includes(`-${key}`)
                                ? "expand_more"
                                : "expand_less"
                              : "unfold_more"}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {!!list?.report?.length &&
                  list.report.map((data, index) => (
                    <tr key={index} style={{ verticalAlign: "middle" }}>
                      <td>{index + 1}</td>
                      <td>{data.report_name}</td>
                      <td>{data.date}</td>
                      <td>
                        <IconButton
                          onClick={() => handleShowPdf(data, true)}
                          color="primary"
                        >
                          <OpenInNew />
                        </IconButton>
                        {type !== "prebuy" &&
                          data.userreportdelete?.length < 1 && (
                            <IconButton
                              onClick={() =>
                                setRejectPopup({
                                  id: data.id,
                                  show: true,
                                })
                              }
                              color="error"
                            >
                              <Delete />
                            </IconButton>
                          )}
                        {data.userreportdelete?.length > 0 &&
                          data.userreportdelete[0].reject === true && (
                            <IconButton
                              onClick={() =>
                                setInfoPopup({
                                  data: data.userreportdelete[0],
                                  show: true,
                                })
                              }
                              color="warning"
                            >
                              <InfoRounded />
                            </IconButton>
                          )}
                        {type === "prebuy" && (
                          <IconButton
                            onClick={() => handleDeleteReport(data)}
                            disabled={deletingReport}
                            color="error"
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {type !== "prebuy" && (
            <div className="tblFtrActs">
              <p colSpan={3} className={`${isError ? "text-danger" : ""}`}>
                {isLoading
                  ? "Fetching records.."
                  : isError && !list?.report?.length
                  ? "Failed to load records!"
                  : !list?.report?.length && "No Records Found!"}
              </p>
              <div>
                <TablePagination
                  component="div"
                  count={list?.count || 0}
                  page={searchFormik.values.page - 1}
                  onPageChange={handleChangePage}
                  rowsPerPage={searchFormik.values.list_per_page}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <PdfViewerFSModal
        fileUrl={pdf.url}
        title={pdf.title}
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
      {filterModal && (
        <SearchReportModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
        />
      )}
      <CustomDialog
        open={rejectPopup.show}
        handleClose={() => handleRejectPopupClose()}
      >
        <div className="vwDlgWrpr">
          <h4>Delete Report Request</h4>
          <form className="mt-4" onSubmit={deleteFormik.handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <CustomInput
                  label="Reason to delete"
                  name="reason"
                  onChange={deleteFormik.handleChange}
                  onBlur={deleteFormik.handleBlur}
                  formik={deleteFormik}
                  value={deleteFormik.values.reason}
                  multiline
                />
              </div>
              <div className="col-md-12 d-flex align-items-center justify-content-end">
                <CustomButton
                  label="Cancel"
                  btnType="secondary"
                  btnClass="me-3"
                  onClick={() => handleRejectPopupClose()}
                />
                <CustomButton label="Send Delete Request" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </CustomDialog>
      <CustomDialog
        open={infoPopup.show}
        handleClose={() =>
          setInfoPopup({
            data: null,
            show: false,
          })
        }
      >
        {infoPopup.data != null && (
          <div className="rjctRsnInfoPopup">
            <p>
              Your delete request has been rejected by the admin due to the
              following reason:
            </p>
            <p className="rjctRsn">{infoPopup.data.reject_reason}</p>
          </div>
        )}
      </CustomDialog>
    </>
  );
};

export default UploadedReports;
