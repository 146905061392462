import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { baseUrl } from "../../services/api/api";
import {
  useGetProSportsPersonsQuery,
  useGetDocSportsPersonsQuery,
  useGetSportsPersonsQuery,
} from "../../services/sportsPerson/sportsPersonApi";
import { getTransformedRoleLabel } from "../../Utils/Utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import SportPersons from "../SportPersons";

const Dashboard = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const matches = useMediaQuery("(max-width:1200px)");

  const { data: sportPerson } = useGetSportsPersonsQuery(
    { id: user?.id, type: "sport_person" },
    {
      skip: user?.account_type !== "team_manager",
      refetchOnFocus: true,
    }
  );
  const { data: docSportPerson } = useGetDocSportsPersonsQuery(
    { id: user?.id, type: "sport_person" },
    {
      skip: user?.account_type !== "doctor",
      refetchOnFocus: true,
    }
  );
  const { data: proSportPerson } = useGetProSportsPersonsQuery(
    { id: user?.id, type: "sport_person" },
    {
      skip: user?.account_type !== "professional",
      refetchOnFocus: true,
    }
  );

  const getSportsPersons = () => {
    switch (user?.account_type) {
      case "team_manager":
        return sportPerson;
      case "doctor":
        return docSportPerson;
      case "professional":
        return proSportPerson;

      default:
        return [];
    }
  };

  const sports_persons_list = getSportsPersons();

  return (
    <>
      <div className="mnDashboardView mngrDashWrpr">
        <div className="row">
          <div className="col-md-6">
            <div className="dshStCard d-flex">
              <div className="usrImgWrpr">
                <Avatar
                  src={user?.profile_image && baseUrl + user?.profile_image}
                  variant="rounded"
                />
              </div>
              <div className="rtTxtIfo d-flex flex-column justify-content-between">
                <div className="rtTpClmn">
                  <p>Welcome!</p>
                  <Link to="/profile">
                    <p className="usrNm">
                      {user?.first_name} {user?.last_name}
                    </p>
                  </Link>
                  <p className="usrRl text-capitalize">
                    {getTransformedRoleLabel(
                      user?.account_type,
                      user?.account_subtype
                    )}{" "}
                    | <span className="text-uppercase">Id: {user?.ref_no}</span>
                  </p>
                </div>
                {!matches && (
                  <Link to="/profile" className="d-flex align-items-center">
                    <span className="material-symbols-rounded me-2">edit</span>{" "}
                    Edit Profile
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dshStCard statsGrid">
              <h3>Connected to</h3>
              <hr />
              <div className="grdWrpr d-flex align-items-start justify-content-between">
                <div className="staCrd">
                  <img src="/assets/svg/sport_persons.svg" />
                  <h4>
                    {!!sports_persons_list?.length
                      ? sports_persons_list.length
                      : 0}
                  </h4>
                  <p>Sport Persons</p>
                </div>
                <div className="staCrd">
                  <img src="/assets/svg/professional.svg" />
                  <h4>0</h4>
                  <p>Professional</p>
                </div>
                <div className="staCrd">
                  <img src="/assets/svg/manager.svg" />
                  <h4>0</h4>
                  <p>Team Manager</p>
                </div>
                <div className="staCrd">
                  <img src="/assets/svg/hospital.svg" />
                  <h4>0</h4>
                  <p>Hospital</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <SportPersons type="dashboard" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
