import { appApi } from "../api/api";

export const AuthApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    // login user
    loginUser: builder.mutation({
      query: (values) => ({
        url: "/api/login",
        method: "POST",
        body: values,
        credentials: "include",
      }),
    }),

    //Two Factor
    twoFactorAuth: builder.mutation({
      query: (values) => ({
        url: "/api/Twofactorverification/",
        method: "POST",
        body: values,
        credentials: "include",
      }),
      invalidatesTags: ["User"],
    }),

    //forgot password
    forgotPassword: builder.mutation({
      query: (values) => ({
        url: "/api/forgot_password/",
        method: "POST",
        body: values,
        credentials: "include",
      }),
    }),

    //change password
    changePassword: builder.mutation({
      query: (values) => ({
        url: `/api/change_password/${values.var1}/${values.var2}/`,
        method: "POST",
        body: values,
        credentials: "include",
      }),
    }),

    // signup user
    signupUser: builder.mutation({
      query: (values) => ({
        url: "/api/users/register",
        method: "POST",
        body: values,
      }),
    }),

    // logout user
    logoutUser: builder.mutation({
      query: () => ({
        url: "/api/logout/",
        method: "DELETE",
        credentials: "include",
      }),
      invalidatesTags: ["User"],
    }),

    //current user
    getCurrentUser: builder.query({
      query: () => "/api/user",
      providesTags: ["User"],
    }),

    //GET user
    getUserById: builder.query({
      query: (id) => `/api/show_user/${id}`,
      providesTags: ["User"],
    }),

    //update User
    updateUser: builder.mutation({
      query: (values) => ({
        url: `/api/edit_users/${values.get("id")}`,
        method: "PATCH",
        body: values,
      }),
    }),

    //Delete User Profile
    deleteProfileImage: builder.mutation({
      query: (values) => ({
        url: `/api/profile_pic_delete/`,
        method: "GET",
        body: values,
      }),
    }),

    //Motive List signup screen
    getMotivesList: builder.query({
      query: () => `/api/motives`,
    }),
    //Team Manager List signup screen
    getTeamManagerList: builder.query({
      query: () => `/api/team_manager_index`,
    }),
    //Professional List signup screen
    getProfessionalList: builder.query({
      query: () => `/api/professional_index`,
    }),
    //Professional List signup screen
    getDoctorList: builder.query({
      query: () => `/api/doctor_index`,
    }),
    //resend otp
    resendOtp: builder.query({
      query: (id) => `/api/resend_otp/${id}`,
      invalidatesTags: ["OtpResend"],
    }),

    //Transactions Index
    getTransactions: builder.query({
      query: (id) => `/api/${id}/transactions`,
      providesTags: ["User"],
    }),

    //address Index
    getAddress: builder.query({
      query: (id) => `/api/${id}/shippings`,
      providesTags: ["UserAddress"],
    }),
    //Create Address
    createAddress: builder.mutation({
      query: (values) => ({
        url: `/api/${values.user_id}/shippings/new`,
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["UserAddress"],
    }),
    //update Address
    updateAddress: builder.mutation({
      query: (values) => ({
        url: `/api/shippings/${values.id}/update`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["UserAddress"],
    }),
    //delete Address
    deleteAddress: builder.mutation({
      query: (id) => ({
        url: `/api/shippings/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserAddress"],
    }),
    //makedefault
    makeDefaultAddress: builder.mutation({
      query: (id) => ({
        url: `/api/make_default/${id}`,
        method: "PATCH",
      }),
      invalidatesTags: ["UserAddress"],
    }),

    //makedefault
    makeMoulliePayment: builder.mutation({
      query: ({ url, id, payload }) => ({
        url: `/api/${url}/${id}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useTwoFactorAuthMutation,
  useLoginUserMutation,
  useSignupUserMutation,
  useLazyGetCurrentUserQuery,
  useGetCurrentUserQuery,
  useLogoutUserMutation,
  useForgotPasswordMutation,
  useChangePasswordMutation,
  useLazyResendOtpQuery,
  useUpdateUserMutation,
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetMotivesListQuery,
  useGetDoctorListQuery,
  useGetProfessionalListQuery,
  useGetTeamManagerListQuery,
  useGetAddressQuery,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useGetTransactionsQuery,
  useMakeDefaultAddressMutation,
  useDeleteProfileImageMutation,
  useMakeMoulliePaymentMutation,
} = AuthApi;
