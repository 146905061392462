import { appApi } from "../api/api";

export const InviteApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getInvitations: builder.query({
      query: (id) => `/api/invite_index/${id}`,
      providesTags: ["Invite"],
    }),

    getInvitationsBySearch: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/search_invite_index/${id}`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Invite"],
    }),

    //Invite people
    invitePeople: builder.mutation({
      query: (values) => ({
        url: "/api/invite/",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Invite"],
    }),
  }),
});

export const {
  useInvitePeopleMutation,
  useGetInvitationsQuery,
  useLazyGetInvitationsBySearchQuery,
} = InviteApi;
