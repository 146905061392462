import { appApi } from "../api/api";

export const ProductsApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: (id) => `/api/product/${id}`,
      providesTags: ["Products"],
    }),

    getProductsBySearch: builder.query({
      query: ({ id, ...values }) => ({
        url: `/api/sp_product_search/${id}`,
        method: "POST",
        body: values,
      }),
      providesTags: ["Products"],
    }),

    getUserProducts: builder.query({
      query: (id) => `/api/${id}/orders`,
      providesTags: ["Checkout", "Products"],
    }),

    getProductById: builder.query({
      query: (id) => `/api/products/${id}`,
      invalidatesTags: ["Products"],
    }),

    getCartList: builder.query({
      query: () => `/api/carts`,
      providesTags: ["Cart", "Checkout"],
    }),

    addItemToCart: builder.mutation({
      query: (values) => ({
        url: "/api/carts/new",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Cart"],
    }),

    addBulkProductsToCart: builder.mutation({
      query: (values) => ({
        url: "/api/bulk_cart",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Cart"],
    }),

    updateCartItem: builder.mutation({
      query: (values) => ({
        url: `/api/carts/${values.id}/update`,
        method: "PATCH",
        body: values.product,
      }),
      invalidatesTags: ["Cart"],
    }),
    removeCartItem: builder.mutation({
      query: (id) => ({
        url: `/api/carts/${id}/delete`,
        method: "DELETE",
      }),
      invalidatesTags: ["Cart"],
    }),
    createOrder: builder.mutation({
      query: (values) => ({
        url: "/api/orders/new",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Checkout"],
    }),

    createOrder: builder.mutation({
      query: (values) => ({
        url: "/api/orders/new",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Checkout"],
    }),

    createBulkProductOrder: builder.mutation({
      query: (values) => ({
        url: "/api/bulk_order_new",
        method: "POST",
        body: values,
      }),
      invalidatesTags: ["Checkout"],
    }),

    getUserOrderById: builder.query({
      query: (id) => `/api/orders/${id}`,
      providesTags: ["user"],
    }),

    getUsersOrderedProducts: builder.query({
      query: (id) => `/api/users/${id}/order_products`,
      providesTags: (result, error, arg) => [{ type: "Products", id: arg }],
    }),
    getTmOrderedProducts: builder.query({
      query: (id) => `/api/users/${id}/tm_order_products`,
      providesTags: (result, error, arg) => [{ type: "Products", id: arg }],
    }),

    getStripeIntent: builder.query({
      query: (id) => `/api/StripeIntentCreate/${id}`,
      providesTags: ["Checkout"],
    }),

    getCouponInfo: builder.query({
      query: (arg) => `/api/admin/coupon/${arg}`,
    }),

    getProductCategories: builder.query({
      query: () => `/api/admin/product_categories`,

      providesTags: (result, error, arg) =>
        result
          ? [
              "Products_Category",
              ...result.map(({ id }) => ({ type: "Products_Category", id })),
            ]
          : ["Products_Category"],
    }),
  }),
});

export const {
  useGetProductsQuery,
  useGetProductByIdQuery,
  useAddItemToCartMutation,
  useUpdateCartItemMutation,
  useRemoveCartItemMutation,
  useGetCartListQuery,
  useCreateOrderMutation,
  useGetUserProductsQuery,
  useGetUserOrderByIdQuery,
  useGetUsersOrderedProductsQuery,
  useGetTmOrderedProductsQuery,

  useLazyGetStripeIntentQuery,
  useLazyGetCouponInfoQuery,
  useAddBulkProductsToCartMutation,
  useCreateBulkProductOrderMutation,
  useGetProductCategoriesQuery,
  useLazyGetProductsBySearchQuery,
} = ProductsApi;
