import React, { useEffect, useState } from "react";
import "./PreBuy.css";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PreBuyFollowKit from "./PreBuyFollowkit";
import PreBuyCheckout from "./PreBuyCheckout";
import Stripe from "../../../Components/Organisms/Stripe";
import { Alert, Drawer, IconButton } from "@mui/material";
import Unauthorized from "../../ErrorScreens/Unauthorized";
import UploadedReports from "../../Users/Reports/UploadedReports";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { useGetCurrentUserQuery } from "../../../services/auth/authApi";
import Loaders from "../../../Components/Molecules/Loaders";
import CustomCheckBox from "../../../Components/Atoms/CustomCheckBox";
import { useGetStaticPagesQuery } from "../../../services/StaticPages/StaticPageApis";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserCredentials } from "../../../features/auth/authSlice";
import Mollie from "../../../Components/Organisms/Mollie/Mollie";

const steps = [
  "Order Followkits",
  "Checkout",
  "Upload Reports(optional)",
  "Payment",
];

const PreBuy = () => {
  const { id } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  const [refNo, setRefNo] = useState(null);
  const [concernedDialog, setConcernedDialog] = useState(false);
  const [userConcerned, setUserConcerned] = useState(false);
  const dispatch = useDispatch();
  const { data: user, isLoading } = useGetCurrentUserQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const { data: user_concern } = useGetStaticPagesQuery("user_concern");

  useEffect(() => {
    if (user) {
      dispatch(setUserCredentials(user));
    }
  }, [user]);

  const handleNext = () => {
    if (activeStep === 2 && !userConcerned)
      return toast.error("Please agree to the gutbiomics User Consent!");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep <= 0 ? prevActiveStep : prevActiveStep - 1
    );
  };

  const getStep = (index) => {
    switch (index) {
      case 0:
        return <PreBuyFollowKit handleNext={handleNext} />;
      case 1:
        return <PreBuyCheckout handleNext={handleNext} setRefNo={setRefNo} />;
      case 2:
        return (
          <>
            <UploadedReports type="prebuy" />
            <div className="d-flex align-items-center my-3">
              <CustomCheckBox
                value={userConcerned}
                onChange={() => setUserConcerned(!userConcerned)}
                label="Agree to the gutbiomics User Consent"
                containerClasses="mb-0"
                checked={userConcerned ? true : false}
              />
              <span
                className="material-symbols-rounded cursorPointer"
                onClick={() => setConcernedDialog(true)}
              >
                info
              </span>
            </div>
            <CustomButton label="Proceed to payment" onClick={handleNext} />
          </>
        );
      case 3:
        return refNo ? (
          <Mollie refNo={refNo} />
        ) : (
          <>
            <Alert sx={{ mb: 3 }} severity="error">
              Please check out cart before payment!
            </Alert>
            <CustomButton
              label="Order Followkits"
              onClick={() => setActiveStep(0)}
            />
          </>
        );
      default:
        return "something is fishy";
    }
  };

  if (isLoading) return <Loaders />;

  if (
    user?.account_type !== "sport_person" ||
    user?.initial_payment === true ||
    user?.id !== Number(id)
  )
    return <Unauthorized />;

  return (
    <>
      <div className="prbyWrpr">
        <div className="prByHdr">
          <img src="/assets/images/logo.png" />
          <h3>Welcome to Gutbiomics!</h3>
        </div>
        <div className="stprCntnr container">
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="sptVwCntnr">{getStep(activeStep)}</div>
          </Box>
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={concernedDialog}
        onClose={() => setConcernedDialog(false)}
        className="product_view_modal followReportDetails"
      >
        <div className="p-5 flwRptInrWrprDt">
          <IconButton
            className="clsIcnBtn"
            onClick={() => setConcernedDialog(false)}
          >
            <span className="material-symbols-rounded">clear</span>
          </IconButton>
          <div className="staticPageContainer">
            <div
              dangerouslySetInnerHTML={{ __html: user_concern?.content }}
              className="mt-3"
            ></div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default PreBuy;
