import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  useLazyGetEBookByIdQuery,
  useLazyGetEBooksQuery,
  useLazyGetRecipesQuery,
  useLazyGetSingleRecipeQuery,
} from "../../services/EbooksAndRecipes/EbooksAndRecipes";
import { urlBuilder } from "../../Utils/Utils";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import moment from "moment/moment";
import Loaders from "../../Components/Molecules/Loaders";
import { Button } from "@mui/material";
import Ebooks from "./Ebooks";
import Recipes from "./Recipes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EBooksRecipes = () => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [ebookModal, setEbookModal] = useState({ show: false, data: null });

  const [
    getEbooks,
    {
      data: ebook_list,
      isError: ebook_err,
      isLoading: ebook_loading,
      isFetching: ebook_fetching,
    },
  ] = useLazyGetEBooksQuery();

  const [
    getRecipes,
    {
      data: recipe_list,
      isError: recipe_err,
      isLoading: recipe_loading,
      isFetching: recipe_fetching,
    },
  ] = useLazyGetRecipesQuery();

  const ebooks = ebook_list?.report;
  const recipe = recipe_list?.report;

  console.log(ebooks, recipe);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [getEBookById, { isLoading }] = useLazyGetEBookByIdQuery();
  const [getSingleRecipe, { isLoading: recipeLoading }] =
    useLazyGetSingleRecipeQuery();

  useEffect(() => {
    if (ebookModal.data?.id != null && ebookModal.data?.id != undefined) {
      if (value === 0) {
        let currIndex = ebooks?.findIndex(
          (ele) => ele.id == ebookModal.data?.id
        );
        setCurrentIndex(currIndex);
      } else {
        let currIndex = recipe?.findIndex(
          (ele) => ele.id == ebookModal.data?.id
        );
        setCurrentIndex(currIndex);
      }
    } else {
      setCurrentIndex(0);
    }
  }, [ebookModal]);

  const handleNav = (type) => {
    if (value === 0) {
      if (type === "prev") {
        getIndividualDetails(ebooks[currentIndex - 1].id);
      } else if (type === "next") {
        getIndividualDetails(ebooks[currentIndex + 1].id);
      }
    } else {
      if (type === "prev") {
        getIndividualDetails(recipe[currentIndex - 1].id);
      } else if (type === "next") {
        getIndividualDetails(recipe[currentIndex + 1].id);
      }
    }
  };

  const getIndividualDetails = async (id) => {
    try {
      if (value === 0) {
        let res = await getEBookById(id);
        if (res.status == "fulfilled") {
          setEbookModal({
            show: true,
            data: {
              title: res.data.ebook_name,
              image: res.data.ebook_image,
              blog: res.data.ebook_blog,
              date: res.data.date,
              id: res.data.id,
            },
          });
        }
      } else if (value === 1) {
        let res = await getSingleRecipe(id);
        if (res.status == "fulfilled") {
          setEbookModal({
            show: true,
            data: {
              title: res.data.recipe_name,
              image: res.data.recipe_image,
              blog: res.data.recipe_blog,
              data: res.data.date,
              id: res.data.id,
            },
          });
        }
      }
    } catch (err) {
      console.log("err");
    }
  };

  return (
    <>
      {(isLoading || recipeLoading) && <Loaders />}
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <h5 className="d-flex align-items-center">
              <img src="/assets/svg/ebook.svg" className="me-2" />
              E-Books & Recipes
            </h5>
            <hr />
          </div>
          <div className="mt-1">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="E-Books" {...a11yProps(0)} />
                  <Tab label="Recipes" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Ebooks
                  getIndividualDetails={getIndividualDetails}
                  getEbooks={getEbooks}
                  isError={ebook_err}
                  isLoading={ebook_loading}
                  isFetching={ebook_fetching}
                  list={ebook_list}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Recipes
                  getIndividualDetails={getIndividualDetails}
                  list={recipe_list}
                  isError={recipe_err}
                  isLoading={recipe_loading}
                  isFetching={recipe_fetching}
                  getRecipes={getRecipes}
                />
              </TabPanel>
            </Box>
          </div>
        </div>
      </div>
      <CustomDialog
        open={ebookModal.show}
        handleClose={() => setEbookModal({ show: false, data: null })}
        maxWidth="lg"
        className="blogDialog"
      >
        <div className="text-center">
          <img src="/assets/svg/ebook-circle.svg" className="mb-3" />
          <h3 className="mb-4">{ebookModal.data?.title}</h3>
          <p className="blogDate">
            {moment(ebookModal.data?.date).format("MMMM Do YYYY")}
          </p>
          <div className="blgNav">
            <Button
              className="prvBtn"
              disabled={
                ebookModal.show &&
                (value === 0
                  ? ebooks[currentIndex]?.id === ebooks[0]?.id
                  : recipe[currentIndex]?.id === recipe[0]?.id)
              }
              onClick={() => handleNav("prev", ebookModal.data?.id)}
            >
              <span className="material-symbols-rounded">arrow_back_ios</span>
            </Button>
            <Button
              className="nxtBtn"
              disabled={
                ebookModal.show &&
                (value === 0
                  ? ebooks[currentIndex]?.id === ebooks[ebooks.length - 1]?.id
                  : recipe[currentIndex]?.id === recipe[recipe.length - 1]?.id)
              }
              onClick={() => handleNav("next", ebookModal.data?.id)}
            >
              <span className="material-symbols-rounded">
                arrow_forward_ios
              </span>
            </Button>
          </div>
        </div>
        <div className="blogCoverImage">
          {urlBuilder(ebookModal.data?.image) && (
            <img src={urlBuilder(ebookModal.data?.image)} alt="" />
          )}
        </div>
        <div className="mt-5 px-md-5">
          <div dangerouslySetInnerHTML={{ __html: ebookModal.data?.blog }} />
        </div>
      </CustomDialog>
    </>
  );
};

export default EBooksRecipes;
