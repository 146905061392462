import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import { toast } from "react-hot-toast";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomSelect from "../../Components/Atoms/CustomSelect";
import { useLazyGetUserByIdQuery } from "../../services/auth/authApi";
import { useFollowKitSubmitMutation } from "../../services/followKits/followKits";
import CustomButton from "../../Components/Atoms/CustomButton";
import * as yup from "yup";
import {
  useGetSportCategoryByIdQuery,
  useLazyGetSportCategoryByIdQuery,
} from "../../services/sportsPerson/sportsPersonApi";
import PhoneInput from "react-phone-input-2";
import { IconButton } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import ReportDialog from "../../Components/Organisms/ReportDialog";
import PdfViewer from "../../Components/Molecules/PdfViewer";

const FollowKitReport = ({ dialog, setDialog }) => {
  const [getUserById] = useLazyGetUserByIdQuery();
  const [followKitSubmit, { isLoading }] = useFollowKitSubmitMutation();
  const [pdfDialog, setPdfDialog] = useState(false);

  const [getSportCategoryById] = useLazyGetSportCategoryByIdQuery();

  useEffect(() => {
    (async () => {
      if (dialog.data?.user && dialog.type === "new") {
        await getUserById(dialog.data?.fkitorderitems[0]?.user)
          .unwrap()
          .then(async (report_user) => {
            formik.setFieldValue(
              "name",
              `${report_user?.first_name} ${report_user?.last_name}`
            );
            formik.setFieldValue("dob", report_user?.dob);
            formik.setFieldValue("gender", report_user?.gender);
            formik.setFieldValue("avr_weight", report_user?.weight);
            formik.setFieldValue("length", report_user?.height);
            formik.setFieldValue("email", report_user?.email);
            formik.setFieldValue("phone", report_user?.mobile_no);

            await getSportCategoryById(report_user?.sport_categeory)
              .unwrap()
              .then((category) => {
                return formik.setFieldValue(
                  "specific_sport",
                  category?.sport_name ?? ""
                );
              });
          });
      }
    })();
  }, [dialog]);

  const formik = useFormik({
    initialValues: dialog.data?.submitfollowupkit || {
      name: "",
      dob: "",
      gender: "",
      ethinicity: "",
      specific_sport: "",
      avr_weight: "",
      length: "",
      email: "",
      phone: "",
      test_sample: "",
      compliants: "",
      allergies: "",
      medications: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      compliants: yup.string().required(),
      allergies: yup.string().required(),
      medications: yup.string().required(),
    }),
    onSubmit: async (values, actions) => {
      const date = new Date().toISOString().substring(0, 10);
      try {
        const response = await followKitSubmit({
          ...values,
          date: date,
          fkitorder: dialog.data?.id,
        })
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Your Report submitted successfully.");
              handleCloseModal();
            }
          });
        return response;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong.");
        }
        console.log(error);
      }
    },
  });

  const handleCloseModal = () => {
    setDialog({ view: false, data: null, type: "new" });
    formik.resetForm();
  };

  return (
    <>
      <CustomDialog
        open={dialog.view}
        maxWidth="sm"
        handleClose={handleCloseModal}
      >
        <div className="d-flex align-items-center gap-2 mb-2">
          <p className="mb-0">Follow Kit Report - Multiomics Analysis</p>
          <IconButton onClick={() => setPdfDialog(true)}>
            <InfoOutlined />
          </IconButton>
        </div>

        <div className="flwKtWrpr">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <CustomInput
                  label="Name"
                  placeholder="Enter Name"
                  type="text"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="D.O.B"
                  type="date"
                  name="dob"
                  shrink={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dob}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              <div className="col-md-6">
                <CustomSelect
                  label="Gender"
                  placeholder="Enter email"
                  name="gender"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gender}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                  options={[
                    {
                      value: "male",
                      name: "Male",
                    },
                    {
                      value: "female",
                      name: "Female",
                    },
                    {
                      value: "others",
                      name: "Others",
                    },
                  ]}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Ethinicity"
                  placeholder="Enter ethinicity"
                  type="text"
                  name="ethinicity"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ethinicity}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Do you practice specific sport"
                  placeholder="Enter practice specific sport"
                  type="text"
                  name="specific_sport"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.specific_sport}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>

              <div className="col-md-6">
                <CustomInput
                  label="Average Weight"
                  placeholder="Enter Average Weight"
                  type="text"
                  name="avr_weight"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.avr_weight}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Length"
                  placeholder="Enter Length"
                  type="text"
                  name="length"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.length}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  label="Email"
                  placeholder="Enter email"
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>

              <div className="col-md-12">
                <div className="customInputWrpr">
                  <PhoneInput
                    country={"be"}
                    name="phone"
                    value={formik.values.phone}
                    onChange={(e) => {
                      formik.values.phone = e;
                    }}
                    onBlur={() => {
                      formik.setFieldTouched("phone", true);
                    }}
                    containerClass="intl_container"
                    inputClass="intl_input"
                    dropdownClass="intl_dropdown"
                    disabled={dialog.type === "view"}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <p className="errMsg">{formik.errors.phone}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-12">
                <CustomInput
                  label="What are your complaints"
                  placeholder="Enter complaints"
                  type="text"
                  name="compliants"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.compliants}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              <div className="col-md-12">
                <CustomInput
                  label="Do you have known allergies?"
                  placeholder="Enter allergies"
                  type="text"
                  name="allergies"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.allergies}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              <div className="col-md-12">
                <CustomInput
                  label="What medications and supplement are you taking"
                  placeholder="Enter medications and supplement"
                  type="text"
                  name="medications"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.medications}
                  formik={formik}
                  readOnly={dialog.type === "view"}
                />
              </div>
              {dialog.type !== "view" && (
                <div className="col-md-12">
                  <CustomButton
                    label={isLoading ? "Submit.." : "Submit"}
                    disabled={isLoading}
                    type="submit"
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </CustomDialog>

      <ReportDialog open={pdfDialog} handleClose={() => setPdfDialog(false)}>
        <PdfViewer fileUrl={"/assets/pdf/followKitForm.pdf"} />
      </ReportDialog>
    </>
  );
};

export default FollowKitReport;
