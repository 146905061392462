import {
  AddCircleOutline,
  Check,
  RemoveCircleOutline,
  Search,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  useAddBulkProductsToCartMutation,
  useGetProductsQuery,
} from "../../services/products/ProductsApi";
import { currencyFomatter } from "../../Utils/Utils";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import CustomButton from "../../Components/Atoms/CustomButton";
import {
  useGetDocSportsPersonsQuery,
  useGetProSportsPersonsQuery,
  useGetSportsPersonsQuery,
} from "../../services/sportsPerson/sportsPersonApi";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ProductSingleOrder = ({ mount, setDialog, setShowProduct }) => {
  const { user } = useSelector((state) => state.auth);
  const [query, setQuery] = useState("");
  const [orderType, setOrderType] = useState("sport_person");

  const navigate = useNavigate();

  const { data: man_person_list } = useGetSportsPersonsQuery(
    { id: user?.id, type: orderType },
    {
      skip: user?.account_type !== "team_manager" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: pro_persons_list } = useGetProSportsPersonsQuery(
    { id: user?.id, type: orderType },
    {
      skip: user?.account_type !== "professional" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: doc_person_list } = useGetDocSportsPersonsQuery(
    { id: user?.id, type: orderType },
    {
      skip: user?.account_type !== "doctor" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const sports_persons_list = useMemo(() => {
    switch (user?.account_type) {
      case "professional":
        return pro_persons_list;
      case "team_manager":
        return man_person_list;
      case "doctor":
        return doc_person_list;
      default:
        return [];
    }
  }, [user, orderType, doc_person_list, pro_persons_list, man_person_list]);

  const handleClose = () => {
    setDialog(false);
    formik.resetForm();
    setQuery("");
  };
  const [itemsToCart, { isLoading: bulkLoading }] =
    useAddBulkProductsToCartMutation();

  const formik = useFormik({
    initialValues: {
      user: null,
      products: [],
    },
    validationSchema: yup.object().shape({
      user: yup.object().required().typeError("Please select user!"),
    }),
    onSubmit: async (values, actions) => {
      if (!values.products.length)
        return toast.error("Please Select products!");

      const formValues = values.products.map((product, idx) => {
        return {
          user: values.user.id,
          product: product.id,
          quantity: 1,
          group: "",
        };
      });

      try {
        const res = await itemsToCart(formValues)
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Your order has been moved to cart.");
              navigate("/products/my-cart");
              handleClose();
            }
          });
        return res;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong.");
        }
        console.log(error);
      }
    },
  });

  const { data: products, isLoading } = useGetProductsQuery(
    formik.values.user?.id,
    {
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
      skip: !mount || !formik.values.user,
    }
  );

  useEffect(() => {
    formik.setFieldValue("user", null);
  }, [orderType]);

  useEffect(() => {
    formik.setFieldValue("products", []);
  }, [formik.values.user]);

  const keys = ["product_name", "product_type"];

  const filteredProducts = products?.filter((product, idx) => {
    return keys.some((key) =>
      product[key].toLowerCase().includes(query.toLowerCase())
    );
  });

  const productExists = ({ id }) =>
    formik.values.products?.find((el) => el.id === id);

  const addProductHandler = (product) => {
    if (productExists(product)) {
      formik.setFieldValue("products", [
        ...formik.values.products.filter((el) => el.id !== product.id),
      ]);
    } else {
      formik.setFieldValue("products", [
        ...formik.values.products,
        { ...product, quantity: 1 },
      ]);
    }
  };

  return (
    <CustomDialog avoidBG open={mount} handleClose={handleClose}>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className="row g-4">
            <h6>Single Product Order</h6>
            <div className="col-md-12">
              <div className="customInputWrpr mb-0">
                <FormControl className="text-center">
                  {/* <FormLabel>Choose Client Type</FormLabel> */}
                  <RadioGroup
                    value={orderType}
                    defaultValue="sport_person"
                    onChange={(e) => setOrderType(e.target.value)}
                    className="d-flex flex-row gap-3 flex-wrap"
                  >
                    <FormControlLabel
                      value="sport_person"
                      control={<Radio />}
                      checked={orderType === "sport_person"}
                      label="Sport Person"
                    />
                    {user?.account_type == "doctor" && (
                      <FormControlLabel
                        value="patient"
                        control={<Radio />}
                        checked={orderType === "patient"}
                        label="Patient"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="col-md-12">
              <Autocomplete
                disablePortal
                options={sports_persons_list ?? []}
                getOptionLabel={(option) =>
                  `${option?.first_name} ${option?.last_name} - ${option.ref_no}`
                }
                value={formik.values.user}
                onChange={(_, newValue) => {
                  formik.setFieldValue("user", newValue || null);
                }}
                isOptionEqualToValue={(option, value) =>
                  option?.id === value?.id
                }
                renderInput={(params) => (
                  <TextField
                    name="user"
                    {...params}
                    label="Enter Client Name or ID"
                  />
                )}
              />
            </div>
            <div className="bl_prducts_search">
              <TextField
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                placeholder="search products here"
              />
            </div>
            <div style={{ maxHeight: "450px", overflow: "auto" }}>
              {formik.values.user !== null && !!filteredProducts?.length ? (
                filteredProducts.map((product, idx) => (
                  <div
                    key={idx}
                    className="d-flex align-items-center mb-3 gap-3 border-bottom px-2 pb-2"
                  >
                    <Avatar
                      className="cursorPointer"
                      variant="rounded"
                      src={product?.productimages[0]?.product_image}
                      onClick={() =>
                        setShowProduct({
                          view: true,
                          id: product.id,
                        })
                      }
                    />
                    <p className="flex-fill mb-0">
                      <span
                        className="cursorPointer"
                        onClick={() =>
                          setShowProduct({
                            view: true,
                            id: product.id,
                          })
                        }
                      >
                        {product?.product_name}
                      </span>{" "}
                      <br />
                      <small className="text-muted ">
                        {currencyFomatter(product?.price)} | Qty:{" "}
                        {product?.available_qty}{" "}
                        {product?.product_type === "personalized" && (
                          <>| {product?.product_type}</>
                        )}
                      </small>
                    </p>

                    <IconButton onClick={() => addProductHandler(product)}>
                      {productExists(product) ? (
                        <RemoveCircleOutline color={"error"} />
                      ) : (
                        <AddCircleOutline color={"primary"} />
                      )}
                    </IconButton>
                  </div>
                ))
              ) : (
                <h6
                  className={`${
                    !formik.values.user ? "text-danger" : "text-muted"
                  } text-center`}
                >
                  {!formik.values.user
                    ? formik.errors.user
                    : "No products found!"}
                </h6>
              )}
            </div>
            <CustomButton
              label={bulkLoading ? "Adding to Cart.." : "Add to Cart"}
              type="submit"
              disabled={bulkLoading}
            />
          </div>
        </form>
      )}
    </CustomDialog>
  );
};

export default ProductSingleOrder;
