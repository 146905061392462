import { IconButton } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseUrl } from "../../services/api/api";
import {
  useAddItemToCartMutation,
  useGetCartListQuery,
} from "../../services/products/ProductsApi";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

function ProductCard({
  product_name,
  price,
  productimages,
  setShowProduct,
  id,
  product_type,
  available_qty,
}) {
  const { user } = useSelector((state) => state.auth);
  const [addItemToCart, { isLoading: isAddingProduct }] =
    useAddItemToCartMutation();
  const { data: cart } = useGetCartListQuery();
  const navigate = useNavigate();

  const handleAddItemToCart = async () => {
    try {
      await addItemToCart({
        product: id,
        quantity: 1,
        user: user.id,
      }).unwrap();
    } catch (error) {
      if (error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("something went wrong!");
      }
      console.log(error);
    }
  };

  const isCartItem = React.useMemo(() => {
    return cart?.filter((el) => el.product.id === id && el)?.length > 0
      ? true
      : false;
  }, [cart]);

  return (
    <div className="product_card">
      <div className="product_image_wrp">
        {product_type === "personalized" && (
          <span className="prsnlTag">Personalized</span>
        )}
        {!!productimages.length && (
          <img src={productimages[0]?.product_image} alt="" />
        )}
      </div>
      <div className="product_dtls px-3">
        <h5>{product_name}</h5>

        <div className="d-flex align-items-center justify-content-between ">
          {/* <h6>{price !== "" ? `${currencyFomatter(price)}` : "Not Given"}</h6> */}
          <Link
            onClick={() =>
              setShowProduct({
                id: id,
                view: true,
              })
            }
            to=""
          >
            View more
            <span className="material-symbols-rounded">trending_flat</span>
          </Link>
        </div>
      </div>
      {/* {!isCartItem ? (
        <IconButton disabled={isAddingProduct} onClick={handleAddItemToCart}>
          <span className="material-symbols-rounded">add_shopping_cart</span>
        </IconButton>
      ) : (
        <IconButton onClick={() => navigate("/products/my-cart")}>
          <span className="material-symbols-rounded">
            shopping_cart_checkout
          </span>
        </IconButton>
      )} */}
    </div>
  );
}

export default ProductCard;
