import { appApi } from "../api/api";

export const StaticPageApis = appApi.injectEndpoints({
  endpoints: (builder) => ({
    getStaticPages: builder.query({
      query: (type) => `/api/staticpages/${type}`,
      providesTags: ["StaticPages"],
    }),
  }),
});

export const { useGetStaticPagesQuery } = StaticPageApis;
