import { Tune } from "@mui/icons-material";
import { Badge, TablePagination } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomSearch from "../../Components/Atoms/CustomSearch/CustomSearch";
import { addSearchValue } from "../../features/search/searchSlice";
import {
  useGetSportCategoryQuery,
  useLazyGetDoctorSportPersonsListSearchQuery,
  useLazyGetProSportPersonsListSearchQuery,
  useLazyGetTMSportPersonsListSearchQuery,
} from "../../services/sportsPerson/sportsPersonApi";

import { handleSort } from "../../Utils/Utils";
import SportPersonSearchModal from "../SportPersons/SportPersonSearchModal";

const SportPersons = ({ type = "sport_persons" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // Reports list with filters

  const [getTmSportsPersons, { data: team_manager_list }] =
    useLazyGetTMSportPersonsListSearchQuery();

  const [getProfSportsPersons, { data: professional_list }] =
    useLazyGetProSportPersonsListSearchQuery();
  const [getDocSportsPersons, { data: doctor_list }] =
    useLazyGetDoctorSportPersonsListSearchQuery();

  const [filterModal, setFilterModal] = useState(false);
  const previousFilter = useSelector((state) => state.search.patient_search);

  const searchSubmission = (values) => {
    switch (user?.account_type) {
      case "team_manager":
        return getTmSportsPersons(values);
      case "doctor":
        return getDocSportsPersons(values);
      case "professional":
        return getProfSportsPersons(values);
      default:
        break;
    }
  };

  const searchFormik = useFormik({
    initialValues: {
      type: "patient",
      search: "",
      filter: {
        state: "",
        country: "",
        zipcode: "",
        gender: "",
        city: "",
      },
      list_per_page: 10,
      page: 1,
      sort: "",
    },
    onSubmit: async (values, actions) => {
      const notify = toast.loading("Getting Reports..");
      if (filterModal) setFilterModal(false);
      dispatch(addSearchValue({ values, name: "patient_search" }));
      try {
        const query = await searchSubmission({
          ...values,
          id: user?.id,
        })
          .unwrap()
          .then((res) => {
            toast.dismiss(notify);
          });
        return query;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail, {
            id: notify,
          });
        } else {
          toast.error("Query doesn't match with data!", {
            id: notify,
          });
        }
      }
    },
  });

  useEffect(() => {
    if (previousFilter) {
      searchFormik.setValues(previousFilter);
    }
  }, []);

  useEffect(() => {
    if (user?.account_type === "team_manager") {
      getTmSportsPersons(
        previousFilter
          ? { ...previousFilter, id: user?.id }
          : { ...searchFormik.values, id: user?.id }
      );
    } else if (user?.account_type === "doctor") {
      getDocSportsPersons(
        previousFilter
          ? { ...previousFilter, id: user?.id }
          : {
              ...searchFormik.values,
              id: user?.id,
            }
      );
    } else if (user?.account_type === "professional") {
      getProfSportsPersons(
        previousFilter
          ? { ...previousFilter, id: user?.id }
          : { ...searchFormik.values, id: user?.id }
      );
    }
  }, [user, previousFilter]);

  const handleChangePage = async (event, newPage) => {
    searchFormik.setFieldValue("page", newPage + 1);
    return searchFormik.handleSubmit();
  };

  const handleChangeRowsPerPage = async (event) => {
    const listCount = parseInt(event.target.value, 10);
    searchFormik.setFieldValue("list_per_page", listCount);
    searchFormik.setFieldValue("page", 1);
    return searchFormik.handleSubmit();
  };

  const handelBageContent = useMemo(() => {
    let count = 0;
    searchFormik.values.filter.country && count++;
    searchFormik.values.filter.state && count++;
    searchFormik.values.filter.city && count++;
    searchFormik.values.filter.zipcode && count++;
    searchFormik.values.filter.gender && count++;
    searchFormik.values.search.length && count++;
    searchFormik.values.sort.length && count++;
    return count;
  }, [searchFormik.values]);

  const getSportsPersonsByType = useMemo(() => {
    switch (user?.account_type) {
      case "team_manager":
        return team_manager_list;
      case "doctor":
        return doctor_list;
      case "professional":
        return professional_list;
      default:
        break;
    }
  }, [user, team_manager_list, doctor_list, professional_list]);

  const tableHeads = [
    { name: "S.No", sortable: false },
    { name: "Client ID", key: "ref_no", sortable: true },
    {
      name: "First Name",
      key: "first_name",
      sortable: true,
    },
    {
      name: "Last Name",
      key: "last_name",
      sortable: true,
    },
    { name: "Email", key: "email", sortable: true },
    { name: "Action", sortable: false },
  ];

  return (
    <>
      <div className="mnDashboardView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard sprtPrsnsLst">
              <div className="pgTopHdrWrpr d-flex align-items-center justify-content-between gap-2">
                {type === "patient" ? (
                  <h3>
                    <span className="">
                      <img src="/assets/svg/patient.svg" className="me-2" />
                    </span>
                    Patients
                  </h3>
                ) : (
                  <h3>
                    <span className="">
                      <img src="/assets/svg/patient.svg" className="me-2" />
                    </span>
                    Patients
                  </h3>
                )}
                {type === "dashboard" ? (
                  <CustomButton
                    label="View All"
                    onClick={() => navigate("/sportpersons")}
                  />
                ) : (
                  <>
                    <CustomSearch
                      inputName="search"
                      query={searchFormik.values.search}
                      handleChange={searchFormik.handleChange}
                      handleSubmit={(e) => {
                        e.preventDefault();
                        searchFormik.handleSubmit();
                        searchFormik.setFieldValue("page", 1);
                      }}
                      containerClasses="ms-md-auto small"
                    />

                    <Badge badgeContent={handelBageContent} color="secondary">
                      <CustomButton
                        size="small"
                        onClick={() => setFilterModal(true)}
                        label={
                          <>
                            <Tune className="me-2" />
                            Filter
                          </>
                        }
                      />
                    </Badge>
                  </>
                )}
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {tableHeads.map(
                        ({ sortable, key, name, hidden }, idx) => (
                          <th key={idx}>
                            <div
                              className={`${
                                hidden ? "d-none" : "d-flex "
                              } align-items-center ${
                                sortable ? "sortable" : ""
                              } ${
                                searchFormik.values.sort.includes(key)
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() =>
                                sortable && handleSort(searchFormik, key || "")
                              }
                            >
                              {name}
                              {sortable && (
                                <span className="material-symbols-rounded">
                                  {searchFormik.values.sort.includes(key)
                                    ? searchFormik.values.sort.includes(
                                        `-${key}`
                                      )
                                      ? "expand_more"
                                      : "expand_less"
                                    : "unfold_more"}
                                </span>
                              )}
                            </div>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {!!getSportsPersonsByType?.report?.length &&
                      getSportsPersonsByType.report.map((data, idx) => (
                        <tr key={idx} style={{ verticalAlign: "middle" }}>
                          <td>{idx + 1}</td>
                          <td>
                            <Link to={`/patients/view/${data.id}`}>
                              {data?.ref_no}
                            </Link>
                          </td>
                          <td>{data.first_name}</td>
                          <td>{data.last_name}</td>
                          <td>{data.email}</td>
                          {/* <td>04/12/2025</td>
                          <td>04/12/2025</td> */}
                          <td>
                            <CustomButton
                              onClick={() =>
                                navigate(`/patients/view/${data.id}`)
                              }
                              label={
                                <>
                                  <span className="material-symbols-rounded me-2">
                                    open_in_new
                                  </span>
                                  View
                                </>
                              }
                              btnType="secondary"
                              size="small"
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              {type !== "dashboard" && (
                <div className="tblFtrActs">
                  <div className="ms-auto">
                    <TablePagination
                      component="div"
                      count={getSportsPersonsByType?.count || 0}
                      page={searchFormik.values.page - 1}
                      onPageChange={handleChangePage}
                      rowsPerPage={searchFormik.values.list_per_page}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {filterModal && (
        <SportPersonSearchModal
          open={filterModal}
          formik={searchFormik}
          handleClose={() => setFilterModal(false)}
          patient
        />
      )}
    </>
  );
};

export default SportPersons;
