import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function CustomRadio(props) {
  return (
    <FormControl className="mb-3">
      {props.label && (
        <FormLabel id={`demo-row-radio-buttons-group-${props.label}`}>
          {props.label}
        </FormLabel>
      )}
      <RadioGroup
        row
        aria-labelledby={`radio-buttons-${props.name}-group`}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      >
        {props?.options.map(({ label, value }) => (
          <FormControlLabel
            disabled={props.disabled}
            value={value}
            control={<Radio />}
            label={label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
