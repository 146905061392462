import {
  AddCircleOutline,
  Check,
  RemoveCircleOutline,
  Search,
} from "@mui/icons-material";
import {
  Avatar,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetProductsQuery } from "../../services/products/ProductsApi";
import { currencyFomatter } from "../../Utils/Utils";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import CustomButton from "../../Components/Atoms/CustomButton";
import Loaders from "../../Components/Molecules/Loaders";

const ProductBulkModal = ({
  mount,
  user,
  formik,
  index,
  setDialog,
  setShowProduct,
}) => {
  const [query, setQuery] = useState("");

  const { data: products, isLoading } = useGetProductsQuery(user?.id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    skip: !mount || !user,
  });

  const keys = ["product_name", "product_type"];

  const filteredProducts = products?.filter((product, idx) => {
    return keys.some((key) =>
      product[key].toLowerCase().includes(query.toLowerCase())
    );
  });

  const [prodList, setProdlist] = useState([]);

  const productExists = ({ id }) => prodList?.find((el) => el.id === id);

  const addProductHandler = (product) => {
    if (productExists(product)) {
      setProdlist((pre) => {
        return [...pre.filter((el) => el.id !== product.id)];
      });
    } else {
      setProdlist((pre) => {
        return [...pre, { ...product, quantity: 1 }];
      });
    }
  };

  const handleProductSubmission = () => {
    formik.setFieldValue(`group_members.${index}.products`, prodList);
    handleClose();
  };
  const handleClose = () => {
    setDialog({ mount: false, user: undefined, index: undefined });
    setProdlist([]);
    setQuery("");
  };

  useEffect(() => {
    const products = formik.values.group_members?.[index]?.products;
    if (mount && products?.length > 0) {
      setProdlist(products);
    }
  }, [mount, formik]);

  return (
    <CustomDialog avoidBG open={mount} handleClose={handleClose}>
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <h6>
            Add Products to {user?.first_name} {user?.last_name}
          </h6>
          <div className="bl_prducts_search my-4">
            <TextField
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              placeholder="search products here"
            />
          </div>
          <div style={{ maxHeight: "450px", overflow: "auto" }}>
            {!!filteredProducts?.length ? (
              filteredProducts.map((product, idx) => (
                <div
                  key={idx}
                  className="d-flex align-items-center mb-3 gap-3 border-bottom px-2 pb-2"
                >
                  <Avatar
                    className="cursorPointer"
                    variant="rounded"
                    src={product?.productimages[0]?.product_image}
                    onClick={() =>
                      setShowProduct({
                        view: true,
                        id: product.id,
                      })
                    }
                  />
                  <p className="flex-fill mb-0">
                    <span
                      className="cursorPointer"
                      onClick={() =>
                        setShowProduct({
                          view: true,
                          id: product.id,
                        })
                      }
                    >
                      {product?.product_name}
                    </span>{" "}
                    <br />
                    <small className="text-muted ">
                      {currencyFomatter(product?.price)}{" "}
                      {product?.product_type === "personalized" && (
                        <>| {product?.product_type}</>
                      )}
                    </small>
                  </p>

                  <IconButton onClick={() => addProductHandler(product)}>
                    {productExists(product) ? (
                      <RemoveCircleOutline color={"error"} />
                    ) : (
                      <AddCircleOutline color={"primary"} />
                    )}
                  </IconButton>
                </div>
              ))
            ) : (
              <h6 className="text-muted my-4 text-center">
                No products found!
              </h6>
            )}
          </div>
          <CustomButton label={"Submit"} onClick={handleProductSubmission} />
        </div>
      )}
    </CustomDialog>
  );
};

export default ProductBulkModal;
