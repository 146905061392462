import React from "react";
import { Link } from "react-router-dom";
import { useGetSportCategoryQuery } from "../../services/sportsPerson/sportsPersonApi";

const ViewGroup = ({ data }) => {
  const { data: sport_category_list } = useGetSportCategoryQuery();
  return (
    <div className="text-center">
      <div className="circle_back">
        <span className="material-symbols-rounded">groups</span>
      </div>
      <h3 className="mb-4">{data?.group_name}</h3>
      <div className="row">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Client Name</th>
                <th>Client ID</th>
              </tr>
            </thead>
            <tbody>
              {!!data?.users?.length ? (
                data.users.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.first_name + " " + user.last_name}</td>
                    <td>
                      {user?.account_subtype === "patient" ? (
                        <Link to={`/patients/view/${user.id}`}>
                          {user?.ref_no}
                        </Link>
                      ) : (
                        <Link to={`/sportpersons/view/${user.id}`}>
                          {user?.ref_no}
                        </Link>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No Records Found!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ViewGroup;
