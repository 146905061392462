import { CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function AuthRequired() {
  const location = useLocation();
  const { auth } = useAuth();
  const { user } = useSelector((state) => state.auth);

  if (auth === undefined)
    return (
      <div
        style={{ height: "100vh" }}
        className="w-100 d-flex align-items-center justify-content-center"
      >
        <CircularProgress />
      </div>
    );

  return Boolean(user) ? (
    user.account_type === "sport_person" && user.initial_payment === false ? (
      <Navigate to={`/prebuy/${user?.id}`} state={{ from: location }} replace />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default AuthRequired;
