import React from "react";

import { Worker, Viewer } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { CloudDownloadOutlined } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PdfViewer = ({ fileUrl, reportOnly = false }) => {
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, CurrentScale } = zoomPluginInstance;
  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <div className="pdf_viewer_wrapper">
        {fileUrl && (
          <div className="pdf_viewer_header">
            <ZoomOutButton />
            <CurrentScale />
            <ZoomInButton />

            <Download>
              {(props) => (
                <Button
                  onClick={props.onClick}
                  size="small"
                  variant="contained"
                  color="secondary"
                  className="text-light"
                >
                  <CloudDownloadOutlined className="me-2" /> Download
                </Button>
              )}
            </Download>
          </div>
        )}
        {fileUrl ? (
          <Viewer
            fileUrl={fileUrl}
            plugins={[zoomPluginInstance, getFilePluginInstance]}
            withCredentials={true}
          />
        ) : (
          <h6 className="text-center text-muted">No Document</h6>
        )}
      </div>
    </Worker>
  );
};

export default PdfViewer;

export const PdfViewerFSModal = ({
  fileUrl,
  open,
  handleClose,
  title = "Report",
}) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className="pdf_viewer_modal"
    >
      <AppBar sx={{ position: "sticky", top: 0 }}>
        <Toolbar className="container">
          <Typography
            sx={{ ml: 2, flex: 1, color: "white" }}
            variant="h6"
            component="div"
          >
            {title}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <div className="inrContainer container">
        <PdfViewer fileUrl={fileUrl} />
      </div>
    </Dialog>
  );
};
