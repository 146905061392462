import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PdfViewer from "../../Components/Molecules/PdfViewer";
import { useGetMicrobiomeIndexQuery } from "../../services/reports/reportsApi";
import { urlBuilder } from "../../Utils/Utils";

const SpMircobiomeReport = () => {
  const { id } = useParams();
  const [activeMicReport, setActiveMicReport] = useState(0);

  const { data: Microbiome_report } = useGetMicrobiomeIndexQuery(id, {
    refetchOnFocus: true,
  });
  return (
    <div>
      <div className="rprtVwScrn">
        <div className="rprtVwPrt">
          <PdfViewer
            fileUrl={urlBuilder(Microbiome_report?.[activeMicReport]?.report)}
            reportOnly={true}
          />
        </div>
        <div className="archVwPrt">
          <h6>ARCHIVES</h6>
          <ul>
            {!!Microbiome_report?.length ? (
              Microbiome_report.map((data, index) => (
                <li
                  className={activeMicReport === index ? "active" : ""}
                  key={index}
                  onClick={() => setActiveMicReport(index)}
                >
                  <label>{data.report_name}</label>
                  <p>{data.date}</p>
                </li>
              ))
            ) : (
              <li>
                <label>{"No Records Found"}</label>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SpMircobiomeReport;
