import { createSlice } from "@reduxjs/toolkit";

const getStoredToken = () => {
  const token = window.localStorage.getItem("x-access-token");
  return token ? token : null;
};

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    accessToken: getStoredToken(),
  },

  reducers: {
    setUserCredentials: (state, { payload }) => {
      state.user = payload;
    },
    logOut: (state, { payload }) => {
      window.localStorage.removeItem("x-access-token");
      state.user = null;
      state.accessToken = null;
    },
    setAccessToken: (state, { payload }) => {
      window.localStorage.setItem("x-access-token", payload);
      state.accessToken = payload;
    },
  },
});

export const { setUserCredentials, setAccessToken, logOut } = authSlice.actions;

export default authSlice.reducer;
