import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserCredentials } from "../features/auth/authSlice";
import { baseUrl } from "../services/api/api";

function useAuth() {
  const [auth, setAuth] = useState(undefined);
  const { accessToken } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const verifyAuth = async () => {
    try {
      const result = await fetch(baseUrl + "/api/user", {
        mode: "cors",
        credentials: "include",
        headers: {
          authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      });
      if (result.status === 200) {
        const user = await result.json();
        return user;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    (async () => {
      const auth = await verifyAuth();
      console.log(auth);
      await dispatch(setUserCredentials(auth));
      return setAuth(auth);
    })();
  }, []);

  return { auth };
}

export default useAuth;
