import React, { useState } from "react";
import "./Layout.css";
import SideNav from "../SideNav";
import Header from "../Header";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  const [drawerExpand, setDrawerExpand] = useState(true);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div
      className={`${
        drawerExpand ? "expandedLayout" : "closedLayout"
      } layoutWrapper`}
    >
      <div className="sideBarWrapper">
        <SideNav
          setDrawerExpand={setDrawerExpand}
          state={state}
          toggleDrawer={toggleDrawer}
        />
      </div>
      <div className="viewPortWrapper">
        <div className="hdrWrapr">
          <Header
            toggleDrawer={toggleDrawer}
          />
        </div>
        <div className="pageViewPort">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
