import React from "react";
import "./Notification.css";
import { Avatar, Badge, Button, IconButton } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import CustomDialog from "../../Molecules/CustomDialog";
import CustomButton from "../../Atoms/CustomButton";
import { useSelector } from "react-redux";
import {
  useEditReceivedInviteMutation,
  useReceivedInviteQuery,
} from "../../../services/InvitedPeople/InvitedPeople";
import { baseUrl } from "../../../services/api/api";
import { toast } from "react-hot-toast";
import CustomCheckBox from "../../Atoms/CustomCheckBox";
import { Link } from "react-router-dom";
import { useGetStaticPagesQuery } from "../../../services/StaticPages/StaticPageApis";
import { values } from "lodash";

const Notification = () => {
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkedTerms, setCheckedTerms] = React.useState(false);

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [currentData, setCurrentData] = React.useState([]);

  const [invitePop, setInvitePop] = React.useState(false);

  const [userConcern, setUserConcern] = React.useState(false);

  const { data: invite_list } = useReceivedInviteQuery(user?.id || 0, {
    refetchOnFocus: true,
  });

  const { data: staticPage } = useGetStaticPagesQuery("user_concern", {
    refetchOnFocus: true,
  });

  const [editInvite, { isLoading }] = useEditReceivedInviteMutation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleInviteClick = (value) => {
    setCurrentData(value);
    setInvitePop(true);
    handleClose();
  };

  const handleInvite = async (type) => {
    let index = invite_list.findIndex((ele) => ele.id === currentData?.id);
    let payloadNew = {
      ...invite_list[index],
      invite_status: type === "reject" ? "Closed" : "Accept",
    };
    try {
      const response = await editInvite(payloadNew)
        .unwrap()
        .then((res) => {
          if (res) {
            if (type === "reject") {
              toast.error(res.message);
            } else {
              toast.success(res.message);
            }
            setInvitePop(false);
          }
        });
      return response;
    } catch (error) {
      console.log(error);
      if (error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("Something went wrong");
      }
      setInvitePop(false);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge
          badgeContent={
            invite_list?.filter((e) => e.invite_status == "Open")?.length || 0
          }
          color="secondary"
        >
          <img src="/assets/svg/notification.svg" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="notiWrapper">
          {invite_list?.length > 0 ? (
            invite_list
              ?.filter((e) => e.invite_status == "Open")
              ?.map((data, index) => (
                <Button
                  key={index}
                  className="invtBtn"
                  onClick={() =>
                    handleInviteClick({
                      id: data.id,
                      invited_person_name: data.invited_person_name,
                      invited_person_role: data.invited_person_role,
                      profile_url: data.profile_url,
                    })
                  }
                >
                  <div>
                    <span className="material-symbols-rounded">group_add</span>
                  </div>
                  <p className="text-start">
                    Hey! You have been invited by <br />
                    <b>{data.invited_person_name},</b>{" "}
                    <small>
                      {data.invited_person_role === "team_manager"
                        ? "Team Manager"
                        : data.invited_person_role === "sport_person"
                        ? "Sport Person"
                        : data.invited_person_role === "doctor"
                        ? "Doctor"
                        : "Professional"}
                    </small>
                  </p>
                </Button>
              ))
          ) : (
            <>
              <div className="text-center emptyNotiWrpr">
                <img src="/assets/svg/emptynote.svg" />
                <p>No Notifications Found</p>
              </div>
            </>
          )}
        </div>
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
      </Popover>
      {/* <div>Notification</div> */}
      {/* user?.profile_image && baseUrl + user?.profile_image */}
      <CustomDialog
        open={invitePop}
        className="invtdDialg"
        handleClose={() => setInvitePop(false)}
      >
        {Object.keys(currentData).length > 0 && (
          <div className="invtDlgWrpr text-center">
            <p className="text-center">Hi! You have been invited by</p>
            {console.log(
              "baseUrl + currentData.profile_url",
              baseUrl + currentData.profile_url
            )}
            <div className="mx-auto mb-2">
              <Avatar src={baseUrl + "/" + currentData.profile_url} />
            </div>
            <p className="text-center">
              <b>{currentData?.invited_person_name}</b> <br />
              <small style={{ textTransform: "capitalize" }}>
                {currentData.invited_person_role === "team_manager"
                  ? "Team Manager"
                  : currentData.invited_person_role === "sport_person"
                  ? "Sport Person"
                  : currentData.invited_person_role === "doctor"
                  ? "Doctor"
                  : "Professional"}
              </small>
            </p>
            <div>
              <CustomCheckBox
                checked={checkedTerms}
                name="agreeTerms"
                onChange={() => setCheckedTerms(!checkedTerms)}
                label={
                  <>
                    I agree to the{" "}
                    <Link to="" onClick={() => setUserConcern(true)}>
                      User Consent
                    </Link>
                  </>
                }
              />
            </div>
            <div className="btnWrpr d-flex align-items-center justify-content-center">
              <CustomButton
                btnType="secondary"
                label="Reject"
                onClick={() => handleInvite("reject")}
              />
              <CustomButton
                className="acptInvtBtn"
                disabled={!checkedTerms}
                label="Accept"
                onClick={() => handleInvite("accept")}
              />
            </div>
          </div>
        )}
      </CustomDialog>

      <CustomDialog
        open={userConcern}
        handleClose={() => setUserConcern(false)}
      >
        <div className="staticPageContainer mt-3">
          <div dangerouslySetInnerHTML={{ __html: staticPage?.content }}></div>
        </div>
        <CustomButton
          label="Close"
          onClick={() => setUserConcern(false)}
          className="mt-4"
        />
      </CustomDialog>
    </>
  );
};

export default Notification;
