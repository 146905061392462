import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "../Views/Dashboard";
import Login from "../Views/Login";
import TwoFactor from "../Views/Login/TwoFactor";
import ForgotPassword from "../Views/Login/ForgotPassword";
import Signup from "../Views/Signup";
import RegisterSuccess from "../Views/Signup/RegisterSuccess";
import SportPersons from "../Views/SportPersons";
import SportPersonView from "../Views/SportPersons/SportPersonView";
import Profile from "../Views/Profile";
import Invite from "../Views/Invite";
import Followkits from "../Views/Followkits";
import Grouping from "../Views/Grouping";
import UserDashboard from "../Views/Users/Dashboard";
import GutBiomicsReport from "../Views/Users/Reports/GutBiomicsReport";
import MicrobiomeReport from "../Views/Users/Reports/MicrobiomeReport";
import TolerenceReport from "../Views/Users/Reports/TolerenceReport";
import KpiReport from "../Views/Users/Reports/KpiReport";
import InnerhackReport from "../Views/Users/Reports/InnerhackReport";
import Layout from "../Components/Organisms/Layout";
import AuthRequired from "./AuthRequired";
import UploadedReports from "../Views/Users/Reports/UploadedReports";
import EBooksRecipes from "../Views/EbooksRecipes";
import Products from "../Views/Products";
import { useSelector } from "react-redux";
import AccessRoles from "./AccessRoles";
import Unauthorized from "../Views/ErrorScreens/Unauthorized";
import NotFound from "../Views/ErrorScreens/NotFound";
import TermsAndConditions from "../Views/Signup/TermsAndConditions";
import Cart from "../Views/Cart";
import Faq from "../Views/StaticPages/Faq";
import ChangePassword from "../Views/Login/ChangePassword";
import Checkout from "../Views/Checkout";
import ProductsPurchased from "../Views/ProfileDetails/ProductsPurchased";
import ProfileAddress from "../Views/ProfileDetails/ProfileAddress";
import Transactions from "../Views/ProfileDetails/Transactions";
import FollowCheckout from "../Views/Checkout/FollowCheckout";
import PreBuy from "../Views/Signup/PreBuy/PreBuy";
import Stripe from "../Components/Organisms/Stripe";
import Patients from "../Views/Patients";
import BulkFollowkitForm from "../Views/Followkits/BulkFollowkitForm";
import ProductBulkOrder from "../Views/Products/ProductBulkOrder";
import MultiLayout from "../Components/Layout/MultiLayout";
import Mollie from "../Components/Organisms/Mollie/Mollie";

const Router = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/twofactor" element={<TwoFactor />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            path="/api/change_password/:id/:token"
            element={<ChangePassword />}
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/prebuy/:id" element={<PreBuy />} />
          <Route path="/registerSuccess" element={<RegisterSuccess />} />
          <Route path="/terms" element={<TermsAndConditions />} />

          <Route element={<AuthRequired />}>
            <Route element={<Layout />}>
              <Route
                element={
                  <AccessRoles
                    allowedRoles={[
                      "sport_person",
                      "professional",
                      "team_manager",
                      "doctor",
                    ]}
                  />
                }
              >
                <Route
                  path="/"
                  element={
                    user?.account_type === "sport_person" ? (
                      <UserDashboard />
                    ) : (
                      <Dashboard />
                    )
                  }
                />
                <Route path="/followkits" element={<Followkits />} />
                <Route
                  path="/followkits/bulkorder"
                  element={<BulkFollowkitForm />}
                />
                <Route path="/infoPages/:type" element={<Faq />} />
                <Route element={<MultiLayout />}>
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/user/purchased"
                    element={<ProductsPurchased />}
                  />
                  <Route path="/user/address" element={<ProfileAddress />} />
                  <Route path="/user/transactions" element={<Transactions />} />
                </Route>
                <Route
                  path="/followkits/checkout"
                  element={<FollowCheckout />}
                />
                {/* <Route path="/payment/:id" element={<Stripe />} /> */}
                {/* <Route path="/payment/:type/:id" element={<Stripe />} /> */}
                <Route path="/payment/:id" element={<Mollie />} />
                <Route path="/payment/:type/:id" element={<Mollie />} />

                <Route path="/products/checkout" element={<Checkout />} />
                <Route path="/products/my-cart" element={<Cart />} />
              </Route>

              <Route
                element={
                  <AccessRoles
                    allowedRoles={["professional", "team_manager", "doctor"]}
                  />
                }
              >
                <Route path="/patients" element={<Patients />} />
                <Route
                  path="/patients/view/:id"
                  element={<SportPersonView />}
                />
                <Route path="/sportpersons" element={<SportPersons />} />
                <Route
                  path="/sportpersons/view/:id"
                  element={<SportPersonView />}
                />

                <Route path="/invite" element={<Invite />} />
                <Route path="/grouping" element={<Grouping />} />
                {/* <Route path="/managerDashboard" element={<Dashboard />} /> */}

                <Route
                  path="/products/bulkorder"
                  element={<ProductBulkOrder />}
                />

                <Route
                  path="/products/purchased"
                  element={<ProductsPurchased />}
                />
              </Route>

              <Route element={<AccessRoles allowedRoles={["sport_person"]} />}>
                {/* <Route path="/dashboard" element={<UserDashboard />} /> */}
                <Route
                  path="/gutbiomicsReport"
                  element={<GutBiomicsReport />}
                />
                <Route
                  path="/microbiomeReport"
                  element={<MicrobiomeReport />}
                />
                <Route path="/tolerenceReport" element={<TolerenceReport />} />
                <Route path="/kpiReport" element={<KpiReport />} />
                <Route path="/innerhacks" element={<InnerhackReport />} />
                <Route path="/reports" element={<UploadedReports />} />
                <Route path="/ebooks_recipes" element={<EBooksRecipes />} />
                <Route path="/products" element={<Products />} />
              </Route>
            </Route>
          </Route>

          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
