import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useGetAddressQuery,
  useDeleteAddressMutation,
  useMakeDefaultAddressMutation,
} from "../../services/auth/authApi";
import { useSelector } from "react-redux";
import "./ProfileDetails.css";
import MultiLayout from "../../Components/Layout/MultiLayout";
import { Button, IconButton, Tooltip } from "@mui/material";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import AddAddress from "./AddAddress";
import { toast } from "react-hot-toast";
import { Star, StarOutline } from "@mui/icons-material";

const ProfileAddress = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [addressModal, setAddressModal] = useState({
    data: null,
    type: "new",
    open: false,
  });

  const { data: address } = useGetAddressQuery(user?.id, {
    refetchOnFocus: true,
  });

  const [deleteAddress, { isLoading }] = useDeleteAddressMutation();
  const [makeDefault, { isLoading: defaultLoading }] =
    useMakeDefaultAddressMutation();

  const handleDelete = async (id) => {
    await deleteAddress(id)
      .unwrap()
      .then((res) => {
        if (res) {
          toast.success("Address Deleted Successfully!");
        }
      })
      .catch((error) => {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong");
        }
        console.log("rejected", error);
        throw new Error(error);
      });
  };

  const handleMakeDefault = async (id) => {
    try {
      await makeDefault(id)
        .unwrap()
        .then((res) => {
          if (res) {
            toast.success("Address changed to default");
          }
        });
    } catch (error) {
      if (error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <>
      <div className="dshStCard">
        <div className="pt-2 pb-2">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span className="material-symbols-rounded me-2">
                markunread_mailbox
              </span>
              <h5 className="mb-0">My Address</h5>
            </div>
            <Button
              className="adAdrsBtn"
              onClick={() =>
                setAddressModal({ open: true, data: null, type: "new" })
              }
            >
              Add Address
            </Button>
          </div>
          <hr />
        </div>
        <div className="addrsInfoWrpr">
          {address?.length > 0 &&
            address?.map((data, index) => (
              <div
                className={`adrsCard ${data?.is_default ? "dfltAdrs" : ""}`}
                key={index}
              >
                <h5 className="w-75">
                  {data.first_name}&nbsp;{data.last_name}
                </h5>
                <p>
                  <span>{data.address}</span>
                  <br />
                  <span>
                    {data.city},&nbsp;{data.state}, <br />
                    {data.country}&nbsp;{data.pincode}
                  </span>
                  <br />
                  Ph: {"+" + data.phone}
                  <br />
                  Email: {data.email}
                </p>
                <div className="adDltWrpr">
                  <Tooltip title="Make default address">
                    <IconButton onClick={() => handleMakeDefault(data.id)}>
                      <span className="material-symbols-rounded">done</span>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit address">
                    <IconButton
                      onClick={() =>
                        setAddressModal({
                          open: true,
                          data: address[index],
                          type: "edit",
                        })
                      }
                    >
                      <span className="material-symbols-rounded">edit</span>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete address">
                    <IconButton onClick={() => handleDelete(data.id)}>
                      <span className="material-symbols-rounded">delete</span>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
        <CustomDialog
          open={addressModal.open}
          handleClose={() => setAddressModal({ open: false, data: null })}
          maxWidth="md"
          className="blogDialog"
        >
          <AddAddress
            data={addressModal.data}
            type={addressModal.type}
            handleClose={() => setAddressModal({ open: false, data: null })}
          />
        </CustomDialog>
      </div>
    </>
  );
};

export default ProfileAddress;
