import React, { useEffect, useMemo, useState } from "react";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { FieldArray, FormikProvider, useFormik } from "formik";
import CustomCheckBox from "../../../Components/Atoms/CustomCheckBox";
import { toast } from "react-hot-toast";
import { currencyFomatter } from "../../../Utils/Utils";
import {
  useFollowKitRequestMutation,
  useGetFollowKitPricingsQuery,
} from "../../../services/followKits/followKits";

import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import IntakeReportDetails from "./IntakeReportDetails";

const PreBuyFollowKit = ({ handleNext }) => {
  const [reportPrice, setReportPrice] = useState(0);
  const [followKitRequest, { isLoading }] = useFollowKitRequestMutation();
  const [intakePopup, setIntakePopup] = useState(false);

  const { id } = useParams();
  const { data: reports } = useGetFollowKitPricingsQuery(undefined, {
    refetchOnFocus: true,
  });

  const orderValidation = Yup.object().shape({
    follow_kit: Yup.array().min(1, "Please select kit").required("Required"),
    pricing: Yup.array().min(1, "Please select reports").required("Required"),
  });

  const [pricingDetails, setPRicingDetails] = useState({
    data: null,
    title: null,
    mount: false,
  });

  const formik = useFormik({
    initialValues: {
      all: false,
      sport_person: null,
      follow_kit: [],
      pricing: [],
    },
    validationSchema: orderValidation,
    onSubmit: async (values, actions) => {
      if (!values.follow_kit.length) return toast.error("Please add followkit");

      const requested_Date = new Date().toISOString().slice(0, 10);
      const requested_Kit = new Array();

      values.follow_kit.map((kit) => {
        return requested_Kit.push({
          kit_name: kit,
          date: requested_Date,
          sample_type: kit,
          pricing: values.pricing,
        });
      });

      try {
        const response = await followKitRequest({
          id: id,
          requested_Kit: requested_Kit,
        })
          .unwrap()
          .then((res) => {
            if (res) {
              toast.success("Moved to checkout");
              handleNext();
            }
          });
        return response;
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Something went wrong.");
        }
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (formik.values.all === true) {
      formik.values.follow_kit = ["Faeces", "Urine", "Saliva", "Sweat"];
    } else {
      formik.values.follow_kit = [];
    }
  }, [formik.values.all]);

  const findPrice = () => {
    if (formik.values.pricing?.length === 0) {
      setReportPrice(0);
    } else {
      console.log("acc entered else block", formik.values.pricing);
      formik.values.pricing.reduce((acc, curr) => {
        console.log("acc", acc, curr);
        acc =
          parseInt(acc) +
          parseInt(reports[reports.findIndex((ele) => ele.id == curr)].price);
        setReportPrice(acc);
        return acc;
      }, 0);
    }
  };

  useEffect(() => {
    formik.setFieldValue("all", true);
    // if (reports?.length > 0) {
    //   reports.map((data) => {
    //     if (!singleOrderFormik.values.pricing.includes(data.id)) {
    //       singleOrderFormik.values.pricing.push(data.id);
    //     }
    //   });
    // }
  }, [reports?.length]);

  useEffect(() => {
    findPrice();
  }, [formik.values.pricing.length]);

  const vatSum = useMemo(() => {
    const amount = formik.values?.pricing
      ?.map((id) => reports.find((ele) => ele.id == id))
      .map((item) => (item?.price * item?.vat) / 100)
      .reduce((acc, curr) => acc + curr, 0);

    return Math.round(amount);
  }, [formik.values?.pricing]);
  return (
    <>
      <div className="text-center">
        <h3 className="mb-2">Order Follow Kits</h3>
      </div>
      <div className="preByInf d-flex align-items-start">
        <div className="infoAbtOrdr">
          <h5>Kind Attention:</h5>
          <p>The initial order consists of:</p>
          <ul>
            <li>All the kits</li>
            {/* <li>All the reports</li> */}
          </ul>
          <p className="mt-2 mb-0">
            <small>
              For more information:
              <br />
              <Link to="" onClick={() => setIntakePopup(true)}>
                Click here!
              </Link>
            </small>
          </p>
        </div>
        <div className="flwKtWrpr">
          <form className="" onSubmit={formik.handleSubmit}>
            <div className="row text-start">
              <div className="col-md-12">
                <label>Kit Type:</label>
                <div className="chkBxGrd">
                  <CustomCheckBox
                    name="all"
                    label="All"
                    onChange={formik.handleChange}
                    checked={formik.values.all === true}
                    disabled={true}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Faeces"
                    value="Faeces"
                    onChange={formik.handleChange}
                    checked={formik.values.follow_kit.includes("Faeces")}
                    disabled={formik.values.all}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Urine"
                    value="Urine"
                    onChange={formik.handleChange}
                    checked={formik.values.follow_kit.includes("Urine")}
                    disabled={formik.values.all}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Saliva"
                    value="Saliva"
                    onChange={formik.handleChange}
                    checked={formik.values.follow_kit.includes("Saliva")}
                    disabled={formik.values.all}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Sweat"
                    value="Sweat"
                    onChange={formik.handleChange}
                    checked={formik.values.follow_kit.includes("Sweat")}
                    disabled={formik.values.all}
                  />
                </div>
                {formik.touched["follow_kit"] && formik.errors["follow_kit"] ? (
                  <p className="mb-0 text-danger mt-2">
                    {formik.errors["follow_kit"]}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12 mt-4">
                <label>Reports:</label>
                <div className="rprtsWrpr">
                  {!!reports?.length &&
                    reports?.map((data, index) => (
                      <div key={index} className="d-flex align-items-center">
                        <CustomCheckBox
                          key={index}
                          name="pricing"
                          label={
                            <div className="d-flex justify-content-between w-100">
                              <span
                                className="rprtNae"
                                style={{ whiteSpace: "pre" }}
                              >
                                {data.report_name}
                              </span>
                            </div>
                          }
                          // checked={formik.values.pricing.includes(
                          //   data.id
                          // )}
                          disabled={false}
                          value={data.id}
                          onChange={formik.handleChange}
                        />
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <span
                            className="material-symbols-rounded cursorPointer"
                            onClick={() =>
                              setPRicingDetails({
                                data: data.report_details,
                                title: data.report_name,
                                mount: true,
                              })
                            }
                          >
                            info
                          </span>
                          <span className="rprtPricce">
                            {currencyFomatter(data.price)}
                          </span>
                        </div>
                      </div>
                    ))}
                  {/* <div className="d-flex align-items-center justify-content-between mt-2">
                    <p className="mb-0">Order Amount:</p>
                    <p className="mb-0">{currencyFomatter(reportPrice)}</p>
                  </div> */}
                  {/* <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="mb-0">BTW (Excl):</p>
                    <p className="mb-0">
                      {currencyFomatter(reportPrice)}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="mb-0">BTW (Incl):</p>
                    <p className="mb-0">
                      {currencyFomatter(reportPrice + Number(vatSum || 0))}
                    </p>
                  </div> */}
                  <div className="ttlPrice d-flex align-items-center justify-content-between">
                    <p className="mb-0">Total Price:</p>
                    <p className="mb-0 ttlPrVl">
                      {/* {currencyFomatter(reportPrice + Number(vatSum || 0))} */}

                      {currencyFomatter(reportPrice)}
                    </p>
                  </div>
                </div>
                {formik.touched["pricing"] && formik.errors["pricing"] ? (
                  <p className="mb-0 text-danger mt-2">
                    {formik.errors["pricing"]}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12 text-end mt-4">
                <CustomButton
                  label={isLoading ? "Order Kit..." : "Order Kit"}
                  type="submit"
                  disabled={isLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <Drawer
        anchor={"right"}
        open={pricingDetails.mount}
        onClose={() =>
          setPRicingDetails({
            data: null,
            title: null,
            mount: false,
          })
        }
        className="product_view_modal followReportDetails"
      >
        <div className="p-5 flwRptInrWrprDt">
          <IconButton
            className="clsIcnBtn"
            onClick={() =>
              setPRicingDetails({
                data: null,
                title: null,
                mount: false,
              })
            }
          >
            <span className="material-symbols-rounded">clear</span>
          </IconButton>
          <h4>{pricingDetails.title}</h4>
          <div dangerouslySetInnerHTML={{ __html: pricingDetails.data }}></div>
        </div>
      </Drawer>

      <IntakeReportDetails
        open={intakePopup}
        handleClose={() => setIntakePopup(false)}
      />
    </>
  );
};

export default PreBuyFollowKit;
