import React, { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomInput from "../../Components/Atoms/CustomInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGetUserUploadedReportsQuery } from "../../services/reports/reportsApi";
import {
  useGetProfessionalListQuery,
  useGetTeamManagerListQuery,
  useGetDoctorListQuery,
} from "../../services/auth/authApi";
import PdfViewer from "../../Components/Molecules/PdfViewer";
import { Avatar, CircularProgress } from "@mui/material";
import { baseUrl } from "../../services/api/api";
import { useGetUserByIdQuery } from "../../services/auth/authApi";
import { useGetFollowkitReportsQuery } from "../../services/followKits/followKits";
import moment from "moment";
import {
  useGetTmOrderedProductsQuery,
  useGetUserProductsQuery,
  useGetUsersOrderedProductsQuery,
} from "../../services/products/ProductsApi";
import { useGetSportCategoryByIdQuery } from "../../services/sportsPerson/sportsPersonApi";
import SpKpiReport from "./SpKpiReport";
import SpMircobiomeReport from "./SpMircobiomeReport";
import SpToleranceReport from "./SpToleranceReport";
import SpGutbiomicsReport from "./SpGutbiomicsReport";
import SpInnerhackReport from "./SpInnerhackReport";
import PhoneInput from "react-phone-input-2";

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SportPersonView = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const validationArray = Yup.object().shape({
    first_name: Yup.string(),
    last_name: Yup.string(),
    uname: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
    pass: Yup.string().min(2, "Too Short!"),
    rememberme: 0,
  });

  const { id } = useParams();

  const [activeUploadedReport, setActiveUploadedReport] = useState(0);
  const [activeFollowkitReport, setActiveFollowkitReport] = useState(0);

  const { data: sport_person, isLoading } = useGetUserByIdQuery(id, {
    refetchOnFocus: true,
  });

  const { data: uploaded_report } = useGetUserUploadedReportsQuery(id, {
    refetchOnFocus: true,
  });
  const { data: followkit_report } = useGetFollowkitReportsQuery(id, {
    refetchOnFocus: true,
  });

  const { data: products } = useGetUsersOrderedProductsQuery(id, {
    refetchOnFocus: true,
  });
  const { data: Tmproducts } = useGetTmOrderedProductsQuery(id, {
    refetchOnFocus: true,
  });

  const { data: professional_list } = useGetProfessionalListQuery();
  const { data: manager_list } = useGetTeamManagerListQuery();
  const { data: doctor_list } = useGetDoctorListQuery();

  const { data: user_sport_category } = useGetSportCategoryByIdQuery(
    sport_person?.sport_categeory,
    { skip: !sport_person?.sport_categeory }
  );

  const getCurrentReportUrl = (reports, idx) => {
    return !!reports?.length
      ? !!reports[idx]?.report?.length && `${baseUrl}${reports[idx]?.report}`
      : null;
  };

  const getSportPersonsAge = (sport_person_dob) => {
    var age = moment().diff(sport_person_dob, "years");

    return !!sport_person_dob?.length ? age : "";
  };

  const formik = useFormik({
    initialValues: !!followkit_report?.length
      ? followkit_report[activeFollowkitReport]
      : {
          name: "",
          dob: "",
          gender: "",
          ethinicity: "",
          specific_sport: "",
          avr_weight: "",
          length: "",
          email: "",
          phone: "",
          test_sample: "",
          compliants: "",
          allergies: "",
          medications: "",
        },
    enableReinitialize: true,
    onSubmit: (values) => {
      navigate("/registerSuccess");
    },
  });

  if (isLoading)
    return (
      <div className="ldnwpr">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className="mnDashboardView sportPersonView">
        <div className="row">
          <div className="col-md-12 ">
            <div className="dshStCard">
              <div className="mnDashboardView">
                <div className="spPrsTop d-flex">
                  <div className="usrPrflInfo">
                    <div className="usrImCntnr">
                      <Avatar
                        src={
                          !!sport_person?.profile_image
                            ? baseUrl + sport_person.profile_image
                            : ""
                        }
                      />
                    </div>
                    <div className="usrDtlTxt">
                      <h4 className="text-capitalize">
                        {sport_person?.first_name} {sport_person?.last_name}
                      </h4>
                      {window.location.pathname.includes("/patients/") ? (
                        <p>Client Id: {sport_person?.ref_no || "-"}</p>
                      ) : (
                        <p>
                          {user_sport_category?.sport_name || "-"} | Client Id:{" "}
                          {sport_person?.ref_no || "-"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="usrStatInfo d-flex flex-column justify-content-between align-items-end">
                    <div className="d-flex align-items-center justify-content-end">
                      <div className="usrTmLnks">
                        <p>
                          Professional:{" "}
                          <span>
                            {professional_list?.length > 0
                              ? professional_list?.filter(
                                  (ele) => ele.id == sport_person?.professional
                                )[0]?.first_name
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="usrTmLnks">
                        <p>
                          Team Manager:{" "}
                          <span>
                            {manager_list?.length > 0
                              ? manager_list?.filter(
                                  (ele) => ele.id == sport_person?.team_manager
                                )[0]?.first_name
                              : ""}
                          </span>
                        </p>
                      </div>
                      <div className="usrTmLnks">
                        <p>
                          Doctor:{" "}
                          <span>
                            {doctor_list?.length > 0
                              ? doctor_list?.filter(
                                  (ele) => ele.id == sport_person?.doctor
                                )[0]?.first_name
                              : ""}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="statInfoWrp d-flex align-items-center justify-content-between flex-fill">
                      <div className="stIfCd">
                        <img src="/assets/svg/age.svg" />
                        <div>
                          <label>Age</label>
                          <p>{getSportPersonsAge(sport_person?.dob) || "-"}</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/height.svg" />
                        <div>
                          <label>Height</label>
                          <p>{sport_person?.height || "-"}</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/gender.svg" />
                        <div>
                          <label>Gender</label>
                          <p>{sport_person?.gender || "-"}</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/weight.svg" />
                        <div>
                          <label>Weight</label>
                          <p>{sport_person?.weight || "-"}</p>
                        </div>
                      </div>
                      {/* <div className="stIfCd">
                        <img src="/assets/svg/urine.svg" />
                        <div className="urnRslt">
                          <label>Urine Test Result</label>
                          <p>Dehydrated</p>
                        </div>
                      </div>
                      <div className="stIfCd">
                        <img src="/assets/svg/stool.svg" />
                        <div className="stlRslt">
                          <label>Stool Test Result</label>
                          <p>Normal</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        variant="scrollable"
                      >
                        <Tab label="KPI Report" {...a11yProps(0)} />
                        <Tab label="Gutbiomics Report" {...a11yProps(1)} />
                        <Tab label="Mircobiome Report" {...a11yProps(2)} />
                        <Tab label="Tolerance Report" {...a11yProps(3)} />
                        <Tab label="Innerselfie Hacks" {...a11yProps(4)} />
                        <Tab label="Follow Kit Reports" {...a11yProps(5)} />
                        <Tab label="Products Purchased" {...a11yProps(6)} />
                        <Tab label="Uploaded Reports" {...a11yProps(7)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                      <SpKpiReport />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <SpGutbiomicsReport />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <SpMircobiomeReport />
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <SpToleranceReport />
                    </TabPanel>
                    <TabPanel value={value} index={4}>
                      <SpInnerhackReport />
                    </TabPanel>
                    <TabPanel value={value} index={5}>
                      <div className="rprtVwScrn">
                        <div className="rprtVwPrt">
                          {!!followkit_report?.length ? (
                            <form onSubmit={formik.handleSubmit}>
                              <div className="row">
                                <div className="col-md-6">
                                  <CustomInput
                                    label="Name"
                                    placeholder="Enter Name"
                                    type="text"
                                    name="name"
                                    value={formik.values.name}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    label="D.O.B"
                                    placeholder="Enter ethinicity"
                                    type="text"
                                    name="dob"
                                    shrink={true}
                                    value={formik.values.dob}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    label="Gender"
                                    placeholder="Enter ethinicity"
                                    type="text"
                                    name="gender"
                                    value={formik.values.gender}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    label="Ethinicity"
                                    placeholder="Enter ethinicity"
                                    type="text"
                                    name="ethinicity"
                                    value={formik.values.ethinicity}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    label="Do you practice specific sport"
                                    placeholder="Enter practice specific sport"
                                    type="text"
                                    name="specific_sport"
                                    value={formik.values.specific_sport}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    label="Average Weight"
                                    placeholder="Enter Average Weight"
                                    type="text"
                                    name="avr_weight"
                                    value={formik.values.avr_weight}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    label="Length"
                                    placeholder="Enter Length"
                                    type="text"
                                    name="length"
                                    value={formik.values.length}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <CustomInput
                                    label="Email"
                                    placeholder="Enter email"
                                    type="text"
                                    name="email"
                                    value={formik.values.email}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <div className="customInputWrpr">
                                    <PhoneInput
                                      country={"be"}
                                      name="phone"
                                      value={formik.values.phone}
                                      onChange={(e) => {
                                        formik.values.phone = e;
                                      }}
                                      onBlur={() => {
                                        formik.setFieldTouched("phone", true);
                                      }}
                                      containerClass="intl_container"
                                      inputClass="intl_input"
                                      dropdownClass="intl_dropdown"
                                      disabled={true}
                                    />
                                    {formik.touched.phone &&
                                    formik.errors.phone ? (
                                      <p className="errMsg">
                                        {formik.errors.phone}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-12">
                                  <CustomInput
                                    label="What are your complaints"
                                    placeholder="Enter complaints"
                                    type="text"
                                    name="compliants"
                                    value={formik.values.compliants}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <CustomInput
                                    label="Do you have known allergies?"
                                    placeholder="Enter allergies"
                                    type="text"
                                    name="allergies"
                                    value={formik.values.allergies}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <CustomInput
                                    label="What medications and supplement are you taking"
                                    placeholder="Enter medications and supplement"
                                    type="text"
                                    name="medications"
                                    value={formik.values.medications}
                                    formik={formik}
                                    readOnly={true}
                                  />
                                </div>
                              </div>
                            </form>
                          ) : (
                            <h6 className="text-muted text-center">
                              No Reports Found!
                            </h6>
                          )}
                        </div>
                        <div className="archVwPrt">
                          <h6>ARCHIVES</h6>
                          <ul>
                            {!!followkit_report?.length ? (
                              followkit_report?.map((data, index) => (
                                <li
                                  className={
                                    activeFollowkitReport === index
                                      ? "active"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() =>
                                    setActiveFollowkitReport(index)
                                  }
                                >
                                  <label>{data.followupkit?.kit_name}</label>
                                  <p>{data.date}</p>
                                </li>
                              ))
                            ) : (
                              <li>
                                <label>{"No Records Found"}</label>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={6}>
                      {Tmproducts?.data?.length ? (
                        <div className="prdtGrid">
                          {Tmproducts?.data?.map((el, idx) => (
                            <ProductCard
                              key={idx}
                              newDate={el.date}
                              {...el.product}
                            />
                          ))}
                        </div>
                      ) : (
                        <h6 className="text-muted text-center">
                          No Products Purchased!
                        </h6>
                      )}
                    </TabPanel>
                    <TabPanel value={value} index={7}>
                      <div className="rprtVwScrn">
                        <div className="rprtVwPrt">
                          <PdfViewer
                            fileUrl={getCurrentReportUrl(
                              uploaded_report,
                              activeUploadedReport
                            )}
                            reportOnly={true}
                          />
                        </div>
                        <div className="archVwPrt">
                          <h6>ARCHIVES</h6>
                          <ul>
                            {!!uploaded_report?.length ? (
                              uploaded_report?.map((data, index) => (
                                <li
                                  className={
                                    activeUploadedReport === index
                                      ? "active"
                                      : ""
                                  }
                                  key={index}
                                  onClick={() => setActiveUploadedReport(index)}
                                >
                                  <label>{data.report_name}</label>
                                  <p>{data.date}</p>
                                </li>
                              ))
                            ) : (
                              <li>
                                <label>{"No Records Found"}</label>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </TabPanel>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SportPersonView;

const ProductCard = ({
  product_name,
  date,
  productimages,
  product_type,
  newDate,
}) => {
  const img = productimages[0]?.product_image;

  return (
    <div className="productCard border shadow-sm rounded">
      {product_type === "personalized" && (
        <span className="prsnlTag shadow-sm">Personalized</span>
      )}
      <div className="pdtImgWrpr">
        <img src={img} alt="" />
      </div>
      <div className="pdtInfo px-3">
        <h4>{product_name}</h4>
        <p>Bought on: {newDate}</p>
      </div>
    </div>
  );
};
