import React from "react";
import "./MultiLineGraph.css";

export default function MultiLineBarGraph({ data, heading }) {
  const arrowPosition = (val) => {
    return (val / 10) * 100 - (val === 0 ? 2 : 4);
  };

  return (
    <>
      <div className="MChartLine">
        <h2>{heading}</h2>
      </div>

      <div className="MultiBar">
        {data?.map((element, index) => (
          <div key={index}>
            <div className="multiLineBarhead">
              <h4>
                {index + 1},{element.name}
              </h4>
            </div>
            <div className="labels">
              <div className="labelscont">
                <div className="labelred">Low</div>
                <div className="labelyellow">Medium</div>
                <div className="labelgreen">Good</div>
              </div>
            </div>
            <div className="multiProgBar">
              <div className="linebar">
                <div className="red">
                  <div className="yellow">
                    <div className="green"></div>
                  </div>
                </div>
                <div
                  className="arrow"
                  style={{ left: `${arrowPosition(element.value)}%` }}
                ></div>
              </div>
            </div>
            <div className="MutichartCont">
              <p>{element?.content}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
