import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import "./errorscreen.css";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="error_screen_wrapper">
      <div className="error_container">
        <img src="/assets/svg/404.svg" alt="404" />
        <h2>Page Not Found</h2>
        <p>
          Sorry, the page you requested could not be found. <br /> Please check
          the URL and try again.
        </p>
        <div className="btn_cntr">
          <CustomButton
            btnType="secondary"
            label="Go Back"
            onClick={() => navigate(-1)}
          />
          <CustomButton label="Return home" onClick={() => navigate("/")} />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
