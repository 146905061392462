import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PdfViewer from "../../Components/Molecules/PdfViewer";
import {
  useGetGutbiomicsIndexQuery,
  useGetLastGutReportQuery,
  useLazyGetReportByIdQuery,
} from "../../services/reports/reportsApi";
import { urlBuilder } from "../../Utils/Utils";
import { a11yProps, TabPanel } from "./SportPersonView";
import MeterGraph from "../../Components/Molecules/Graph/MeterGraph/MeterGraph";
import LineBar from "../../Components/Molecules/Graph/LineBarGraph/LineBar";
import MultiLineBarGraph from "../../Components/Molecules/Graph/MultiLineBarGraph/MultiLineGraph";

const SpGutbiomicsReport = () => {
  const { id } = useParams();
  const [activeGutbiReport, setActiveGutbiReport] = useState(0);

  const [subValue, setSubValue] = React.useState(0);
  const handleSubChange = (event, newValue) => {
    setSubValue(newValue);
  };

  const { data: Gutbiomics_report } = useGetGutbiomicsIndexQuery(id, {
    refetchOnFocus: true,
  });

  const [reportId, setReportId] = useState("");

  useEffect(() => {
    if (Gutbiomics_report?.length > 0) {
      setReportId(Gutbiomics_report[0]?.id);
    }
  }, [Gutbiomics_report]);

  /* EXCEL REPORT */

  const [getReportById, { data: lastReport }] = useLazyGetReportByIdQuery(
    reportId,
    {
      skip: reportId === "",
    }
  );

  useEffect(() => {
    (async () => {
      if (reportId === "") {
        return;
      }
      await getReportById(reportId);
    })();
  }, [reportId]);
  return (
    <div>
      <div className="rprtVwScrn">
        <div className="rprtVwPrt d-block">
          <Tabs
            value={subValue}
            onChange={handleSubChange}
            aria-label="basic tabs example"
            variant="scrollable"
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Food" {...a11yProps(1)} />
            <Tab label="Supplements" {...a11yProps(2)} />
            <Tab label="Exercise" {...a11yProps(3)} />
            <Tab label="Cognitive" {...a11yProps(4)} />
            <Tab label="Wellbeing" {...a11yProps(5)} />
            <Tab label="Superorganisms" {...a11yProps(6)} />
          </Tabs>
          <TabPanel value={subValue} index={0}>
            {/* <PdfViewer
              fileUrl={urlBuilder(
                Gutbiomics_report?.[activeGutbiReport]?.summary_report
              )}
              reportOnly={true}
            /> */}
            {/* EXCEL REPORT */}

            {lastReport?.summary_phenotype ||
            lastReport?.summary_phenotype_elements ? (
              <>
                {lastReport?.summary_phenotype && (
                  <>
                    <MeterGraph
                      heading={lastReport?.summary_phenotype?.heading}
                      Graph_value={lastReport?.summary_phenotype?.data[0]}
                    />
                    <LineBar
                      heading={lastReport?.summary_phenotype?.heading}
                      Graph_value={lastReport?.summary_phenotype?.data[1]}
                    />
                  </>
                )}
                {lastReport?.summary_phenotype_elements && (
                  <MultiLineBarGraph
                    data={lastReport?.summary_phenotype_elements?.data}
                    heading={lastReport?.summary_phenotype_elements?.heading}
                  />
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                No data found
              </div>
            )}
          </TabPanel>
          <TabPanel value={subValue} index={1}>
            {/* <PdfViewer
              fileUrl={urlBuilder(
                Gutbiomics_report?.[activeGutbiReport]?.food_report
              )}
              reportOnly={true}
            /> */}

            {/* EXCEL REPORT */}

            {lastReport?.food_phenotype ||
            lastReport?.food_phenotype_elements ? (
              <>
                {lastReport?.food_phenotype && (
                  <>
                    <MeterGraph
                      heading={lastReport?.food_phenotype?.heading}
                      Graph_value={lastReport?.food_phenotype?.data[0]}
                    />
                    <LineBar
                      heading={lastReport?.food_phenotype?.heading}
                      Graph_value={lastReport?.food_phenotype?.data[1]}
                    />
                  </>
                )}
                {lastReport?.food_phenotype_elements && (
                  <MultiLineBarGraph
                    data={lastReport?.food_phenotype_elements?.data}
                    heading={lastReport?.food_phenotype_elements?.heading}
                  />
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                No data found
              </div>
            )}
          </TabPanel>

          <TabPanel value={subValue} index={2}>
            {/* <PdfViewer
              fileUrl={urlBuilder(
                Gutbiomics_report?.[activeGutbiReport]?.supplement_report
              )}
              reportOnly={true}
            /> */}

            {/* EXCEL REPORT */}

            {lastReport?.supplement_phenotype ||
            lastReport?.supplement_phenotype_elements ? (
              <>
                {lastReport?.supplement_phenotype && (
                  <>
                    <MeterGraph
                      heading={lastReport?.supplement_phenotype?.heading}
                      Graph_value={lastReport?.supplement_phenotype?.data[0]}
                    />
                    <LineBar
                      heading={lastReport?.supplement_phenotype?.heading}
                      Graph_value={lastReport?.supplement_phenotype?.data[1]}
                    />
                  </>
                )}
                {lastReport?.supplement_phenotype_elements && (
                  <MultiLineBarGraph
                    data={lastReport?.supplement_phenotype_elements?.data}
                    heading={lastReport?.supplement_phenotype_elements?.heading}
                  />
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                No data found
              </div>
            )}
          </TabPanel>
          <TabPanel value={subValue} index={3}>
            {/* <PdfViewer
              fileUrl={urlBuilder(
                Gutbiomics_report?.[activeGutbiReport]?.exercise_report
              )}
              reportOnly={true}
            /> */}

            {/* EXCEL REPORT */}

            {lastReport?.exercise_recovery_phenotype ||
            lastReport?.exercise_recovery_phenotype_elements ? (
              <>
                {lastReport?.exercise_recovery_phenotype && (
                  <>
                    <MeterGraph
                      heading={lastReport?.exercise_recovery_phenotype?.heading}
                      Graph_value={
                        lastReport?.exercise_recovery_phenotype?.data[0]
                      }
                    />
                    <LineBar
                      heading={lastReport?.exercise_recovery_phenotype?.heading}
                      Graph_value={
                        lastReport?.exercise_recovery_phenotype?.data[1]
                      }
                    />
                  </>
                )}
                {lastReport?.exercise_recovery_phenotype_elements && (
                  <MultiLineBarGraph
                    data={
                      lastReport?.exercise_recovery_phenotype_elements?.data
                    }
                    heading={
                      lastReport?.exercise_recovery_phenotype_elements?.heading
                    }
                  />
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                No data found
              </div>
            )}
          </TabPanel>
          <TabPanel value={subValue} index={4}>
            {/* <PdfViewer
              fileUrl={urlBuilder(
                Gutbiomics_report?.[activeGutbiReport]?.cognitive_report
              )}
              reportOnly={true}
            /> */}

            {/* EXCEL REPORT */}

            {lastReport?.cognitive_phenotype ||
            lastReport?.cognitive_phenotype_elements ? (
              <>
                {lastReport?.cognitive_phenotype && (
                  <>
                    <MeterGraph
                      heading={lastReport?.cognitive_phenotype?.heading}
                      Graph_value={lastReport?.cognitive_phenotype?.data[0]}
                    />
                    <LineBar
                      heading={lastReport?.cognitive_phenotype?.heading}
                      Graph_value={lastReport?.cognitive_phenotype?.data[1]}
                    />
                  </>
                )}
                {lastReport?.cognitive_phenotype_elements && (
                  <MultiLineBarGraph
                    data={lastReport?.cognitive_phenotype_elements?.data}
                    heading={lastReport?.cognitive_phenotype_elements?.heading}
                  />
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                No data found
              </div>
            )}
          </TabPanel>
          <TabPanel value={subValue} index={5}>
            {/* <PdfViewer
              fileUrl={urlBuilder(
                Gutbiomics_report?.[activeGutbiReport]?.wellbeing_report
              )}
              reportOnly={true}
            /> */}

            {/* EXCEL REPORT */}

            {lastReport?.wellbeing_phenotype ||
            lastReport?.wellbeing_phenotype_elements ? (
              <>
                {lastReport?.wellbeing_phenotype && (
                  <>
                    <MeterGraph
                      heading={lastReport?.wellbeing_phenotype?.heading}
                      Graph_value={lastReport?.wellbeing_phenotype?.data[0]}
                    />
                    <LineBar
                      heading={lastReport?.wellbeing_phenotype?.heading}
                      Graph_value={lastReport?.wellbeing_phenotype?.data[1]}
                    />
                  </>
                )}
                {lastReport?.wellbeing_phenotype_elements && (
                  <MultiLineBarGraph
                    data={lastReport?.wellbeing_phenotype_elements?.data}
                    heading={lastReport?.wellbeing_phenotype_elements?.heading}
                  />
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                No data found
              </div>
            )}
          </TabPanel>
          <TabPanel value={subValue} index={6}>
            {/* <PdfViewer
              fileUrl={urlBuilder(
                Gutbiomics_report?.[activeGutbiReport]?.superorganism_report
              )}
              reportOnly={true}
            /> */}

            {/* EXCEL REPORT */}

            {lastReport?.super_organism_phenotype ||
            lastReport?.super_organism_elements ? (
              <>
                {lastReport?.super_organism_phenotype && (
                  <>
                    <MeterGraph
                      heading={lastReport?.super_organism_phenotype?.heading}
                      Graph_value={
                        lastReport?.super_organism_phenotype?.data[0]
                      }
                    />
                    <LineBar
                      heading={lastReport?.super_organism_phenotype?.heading}
                      Graph_value={
                        lastReport?.super_organism_phenotype?.data[1]
                      }
                    />
                  </>
                )}
                {lastReport?.super_organism_elements && (
                  <MultiLineBarGraph
                    data={lastReport?.super_organism_elements?.data}
                    heading={lastReport?.super_organism_elements?.heading}
                  />
                )}
              </>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                No data found
              </div>
            )}
          </TabPanel>
        </div>
        <div className="archVwPrt">
          <h6>ARCHIVES</h6>
          <ul>
            {!!Gutbiomics_report?.length ? (
              Gutbiomics_report.map((data, index) => (
                <li
                  className={activeGutbiReport === index ? "active" : ""}
                  key={index}
                  onClick={() => {
                    setActiveGutbiReport(index);
                    setSubValue(0);
                    setReportId(data?.id);
                  }}
                >
                  <label>{data.report_name}</label>
                  <p>{data.date}</p>
                </li>
              ))
            ) : (
              <li>
                <label>{"No Records Found"}</label>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SpGutbiomicsReport;
