import { appApi } from "../api/api";

export const ReceivedInviteApi = appApi.injectEndpoints({
  endpoints: (builder) => ({
    receivedInvite: builder.query({
      query: (id) => `/api/invite_received_index/${id}`,
      providesTags: ["Invite"],
    }),

    //Invite people
    editReceivedInvite: builder.mutation({
      query: (values) => ({
        url: `/api/change_invite_status/${values.id}`,
        method: "PATCH",
        body: values,
      }),
      invalidatesTags: ["Invite"],
    }),
  }),
});

export const { useEditReceivedInviteMutation, useReceivedInviteQuery } =
  ReceivedInviteApi;
