import React, { useState } from "react";
import "./Mollie.css";
import useMollie from "../../../hooks/useMollie";
import CustomButton from "../../Atoms/CustomButton";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { CreditCard } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useMakeMoulliePaymentMutation } from "../../../services/auth/authApi";

const Mollie = ({ refNo }) => {
  const { mollie } = useMollie((mollie) => {
    const cardElements = document.querySelectorAll(
      "#mouli-card .mollie-card-component"
    );

    var cardComponent = mollie.createComponent("card");

    if (cardElements.length !== 4) {
      cardComponent.mount("#mouli-card");
    }

    return () => {
      cardComponent.unmount("#mouli-card");
    };
  }, []);

  const { id, type } = useParams();
  const navigate = useNavigate();
  const [makeMouliPayment, { isLoading: paymentProcessing }] =
    useMakeMoulliePaymentMutation();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading || paymentProcessing) return;

    setIsLoading(true);
    var { token, error } = await mollie.createToken();

    if (error) {
      if (error.message === false) {
        console.log(error);
      } else {
        toast.error(error?.message);
      }
      setIsLoading(false);
      return;
    }

    try {
      await makeMouliPayment({
        url: type === "bulk" ? "bulk_m_payment" : "single_m_payment",
        id: refNo ? refNo : id,
        payload: { cardtoken: token },
      })
        .unwrap()
        .then((res) => {
          console.log(res);
          if (res?.id?.status === "open") {
            window.location.replace(res?.id?._links?.checkout?.href);
          } else if (res?.id?.status === "paid") {
            toast.success("Payment Completed Successfully!");
            navigate(id.includes("GP") ? "/user/purchased" : "/followkits");
          }
        });
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mollie_wrapper">
      <div className="mollie_conainer shadow">
        <h6 className="text-center mb-4">Enter Your Card Details!</h6>

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div id="mouli-card"></div>
            </div>
            <div className="col-12">
              <CustomButton
                btnClass="mt-3"
                label={
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <>
                        <CreditCard className="me-2" /> Pay Now
                      </>
                    )}
                  </>
                }
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Mollie;
