import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import {
  useCreateBulkProductOrderMutation,
  useCreateOrderMutation,
  useGetCartListQuery,
  useLazyGetCouponInfoQuery,
  useRemoveCartItemMutation,
  useUpdateCartItemMutation,
} from "../../services/products/ProductsApi";
import { currencyFomatter, urlBuilder } from "../../Utils/Utils";
import "./cart.css";

import { Delete } from "@mui/icons-material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useGetAddressQuery } from "../../services/auth/authApi";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import AddAddress from "../ProfileDetails/AddAddress";

const BulkProductCart = () => {
  const navigate = useNavigate();
  const [vatSum, setVatSum] = useState(0);
  const {
    data: cart,
    isFetching,
    isLoading,
  } = useGetCartListQuery(undefined, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const [removeItemToCart, { isLoading: removingCartItem }] =
    useRemoveCartItemMutation();
  const [updateItemToCart, { isLoading: updatingCartItem }] =
    useUpdateCartItemMutation();

  const handleRemoveItemToCart = async ({ id }) => {
    try {
      await removeItemToCart(id).unwrap();
    } catch (error) {
      console.log(error);
      if (error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("Failed to remove item from cart!");
      }
    }
  };

  const handleQuantity = async ({ id }, qty) => {
    try {
      await updateItemToCart({ id, product: { quantity: qty } }).unwrap();
    } catch (error) {
      if (error?.data?.detail) {
        toast.error(error.data.detail);
      } else {
        toast.error("Failed to edit quantity!");
      }
      console.error(error);
    }
  };

  const groupedList = useMemo(() => {
    return cart?.reduce((acc, product, idx) => {
      const user = acc.findIndex((el) => el.user === product.receiver_user);
      user >= 0
        ? (acc[user] = {
            ...acc[user],
            products: [...acc[user].products, product],
            total_price: (acc[user].total_price += product.price),
          })
        : acc.push({
            products: [product],
            user: product.receiver_user,
            rc_first_name: product.rc_first_name,
            rc_last_name: product.rc_last_name,
            rc_profile: product.rc_profile,
            rc_ref_no: product.rc_ref_no,
            rc_email: product.rc_email,
            total_price: product.price,
          });

      return acc;
    }, []);
  }, [cart]);

  const { user } = useSelector((state) => state.auth);
  const [createOrder, { isLoading: orderLoadgin }] = useCreateOrderMutation();
  const [createBulkOrder, { isLoading: bulkLoading }] =
    useCreateBulkProductOrderMutation();

  const [addressModal, setAddressModal] = useState({
    data: null,
    type: "new",
    open: false,
  });

  const { data: address } = useGetAddressQuery(user?.id, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const validationArray =
    user.account_type !== "sport_person"
      ? yup.object().shape({
          shipping: yup.string().when("separate_shipping", {
            is: false,
            then: yup.string().required("Address is a required field"),
          }),
          payment_method: yup
            .string()
            .required("Plese choose the Payment method."),
          separate_shipping: yup
            .boolean()
            .required("Plese choose the shipping method"),
        })
      : yup.object().shape({
          shipping: yup.string().required("Address is a required field"),
          payment_method: yup
            .string()
            .required("Plese choose the Payment method."),
          separate_shipping: yup
            .boolean()
            .required("Plese choose the shipping method"),
        });

  const formik = useFormik({
    initialValues: {
      shipping: "",
      payment_method: "online",
      coupon_code: "",
      separate_shipping: false,
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      if (user?.account_type !== "sport_person") {
        try {
          await createBulkOrder({
            ...values,
            separate_shipping:
              values.separate_shipping == "true" ? true : false,
            coupon_code: values.coupon_code
              ? values.coupon_code.coupon_code
              : "",
          })
            .unwrap()
            .then((res) => {
              if (res) {
                if (values.payment_method !== "online") {
                  toast.success("Order Placed Successfully!");
                }
                if (
                  values.coupon_code?.percentage != 100 &&
                  values.payment_method === "online"
                ) {
                  navigate(`/payment/bulk/${res?.product_group_id}`);
                } else {
                  navigate(`/products/purchased`);
                }
              }
            });
        } catch (error) {
          if (error?.data?.msg) {
            toast.error(error.data.msg);
            return;
          } else if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          throw new Error(error);
        }
      } else {
        try {
          await createOrder({
            shipping: values.shipping,
            payment_method: values.payment_method,
            coupon_code: values.coupon_code
              ? values.coupon_code.coupon_code
              : "",
          })
            .unwrap()
            .then((res) => {
              if (res) {
                toast.success("Order Placed Successfully!");
                if (
                  values.coupon_code?.percentage != 100 &&
                  values.payment_method === "online"
                ) {
                  navigate(
                    `/payment/${res?.data ? res?.data?.ref_no : res?.ref_no}`
                  );
                } else {
                  navigate(`/user/purchased`);
                }
              }
            });
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong");
          }
          console.log("rejected", error);
          throw new Error(error);
        }
      }
    },
  });

  useEffect(() => {
    if (address?.length > 0) {
      const defaultAddress = address.findIndex(
        (address) => address.is_default === true
      );
      formik.setFieldValue(
        "shipping",
        address[defaultAddress !== -1 ? defaultAddress : 0]?.id
      );
    }
  }, [address]);

  useEffect(() => {
    const { percentage } = formik.values.coupon_code;
    if (percentage == 100) {
      formik.setFieldValue("payment_method", "offline");
    }
  }, [formik.values.coupon_code]);

  const [getCouponInfo, { isLoading: couponLoading, isError: couponError }] =
    useLazyGetCouponInfoQuery();

  const couponFormik = useFormik({
    initialValues: {
      coupon_code: "",
    },
    validationSchema: yup.object().shape({
      coupon_code: yup.string().required("please provide valid coupon code!"),
    }),
    onSubmit: async (values) => {
      try {
        await getCouponInfo(values.coupon_code)
          .unwrap()
          .then((res) => {
            if (
              res?.user?.id === user?.id &&
              res?.amount < orderInfo.subTotal
            ) {
              formik.setFieldValue("coupon_code", res);
              toast.success("Coupon applied successfully!");
            } else {
              formik.setFieldValue("coupon_code", "");
              toast.error("Invalid Coupon!");
            }
          });
      } catch (error) {
        if (error?.data?.detail) {
          toast.error(error.data.detail);
        } else {
          toast.error("Invalid Coupon!");
        }
        formik.setFieldValue("coupon_code", "");
        console.log("rejected", error);
        throw new Error(error);
      }
    },
  });

  const orderSummaryHandler = () => {
    const subTotal = cart?.reduce((subTotal, product, index) => {
      subTotal += product.product.price * product.quantity;
      return subTotal;
    }, 0);

    const discount = formik.values.coupon_code
      ? formik.values.coupon_code?.type === "percentage"
        ? subTotal -
          (
            subTotal -
            (subTotal * formik.values.coupon_code?.percentage) / 100
          ).toFixed(2)
        : formik.values.coupon_code?.amount
      : 0;

    const total = subTotal - discount;

    return { subTotal, discount, total };
  };

  const orderInfo = useMemo(orderSummaryHandler, [
    cart,
    formik.values.coupon_code,
  ]);

  useEffect(() => {
    if (cart?.length > 0 && !cart[0]?.cart?.is_vat_include) return;
    const orders = [...new Set(cart?.map((data) => data.receiver_user))];

    const vat = orders
      .map((data) => cart.filter((el) => el.receiver_user === data))
      .map((data) =>
        data.map((el) => (el.product.price * el.product.vat) / 100)
      )
      .map((data) => data.reduce((acc, curr) => acc + curr, 0))
      .map((data) => Math.round(data))
      .reduce((acc, curr) => acc + curr, 0);

    const subTotal = cart?.reduce((subTotal, product, index) => {
      subTotal += product.quantity;
      return subTotal;
    }, 0);

    const totalVat = subTotal * vat;

    setVatSum(totalVat);
  }, [cart]);

  if (isLoading)
    return (
      <div className="w-100 d-flex justify-content-center">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <div className="mnDashboardView">
        <div className="dshStCard">
          <div className="pt-2 pb-2">
            <div className="d-flex align-items-center">
              <span className="material-symbols-rounded me-2 text-muted">
                shopping_cart
              </span>

              <h5 className="mb-0">My Cart</h5>
            </div>
            <hr />
          </div>

          <div className="row g-3">
            {!!cart?.length ? (
              <>
                <div className="col-lg-7">
                  {user?.account_type === "sport_person"
                    ? cart?.map((data) => (
                        <div className="chckOtCard">
                          <Tooltip
                            style={{ top: "10px", right: "10px" }}
                            className="position-absolute"
                            title="Remove Product"
                          >
                            <IconButton
                              color="error"
                              onClick={() => handleRemoveItemToCart(data)}
                              disabled={removingCartItem}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                          <div className="imgCntnr">
                            <img
                              src={
                                data?.product?.productimages?.[0]?.product_image
                              }
                            />
                          </div>
                          <div className="pdtDtlsCnrt">
                            <h5>{data?.product.product_name}</h5>
                            <div className="d-flex flex-fill justify-content-between flex-wrap gap-3">
                              <div>
                                <p>
                                  <span>Price:</span>{" "}
                                  {currencyFomatter(data?.product.price)}
                                </p>
                                <p>
                                  <span>Total Price:</span>{" "}
                                  <b>
                                    {currencyFomatter(
                                      data?.product.price * data?.quantity
                                    )}
                                  </b>
                                </p>
                              </div>

                              <div className="tb_qty_wrp">
                                <Button
                                  disabled={
                                    data.quantity <= 1 || updatingCartItem
                                  }
                                  onClick={() => handleQuantity(data, -1)}
                                  color="inherit"
                                >
                                  <span className="material-symbols-rounded">
                                    expand_more
                                  </span>
                                </Button>
                                <div className="tb_qty">{data.quantity}</div>
                                <Button
                                  color="inherit"
                                  disabled={updatingCartItem}
                                  onClick={() => handleQuantity(data, 1)}
                                >
                                  <span className="material-symbols-rounded">
                                    expand_less
                                  </span>
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : groupedList?.map((user, idx) => (
                        <>
                          <div className="bl_user_header mb-3">
                            <div className="bl_user_header_profile">
                              <Avatar
                                sx={{ width: 50, height: 50 }}
                                src={urlBuilder(user?.rc_profile)}
                              />
                              <h6 className="mb-0">
                                {user?.rc_first_name} {user?.rc_last_name}
                                <br />
                                <small className="text-muted">
                                  {user?.rc_ref_no} | {user?.rc_email}
                                </small>
                              </h6>
                            </div>
                          </div>
                          <div>
                            {user.products?.map((data) => (
                              <div className="chckOtCard">
                                <Tooltip
                                  style={{ top: "10px", right: "10px" }}
                                  className="position-absolute"
                                  title="Remove Product"
                                >
                                  <IconButton
                                    color="error"
                                    onClick={() => handleRemoveItemToCart(data)}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                                <div className="imgCntnr">
                                  <img
                                    src={
                                      data?.product?.productimages?.[0]
                                        ?.product_image
                                    }
                                  />
                                </div>
                                <div className="pdtDtlsCnrt">
                                  <h5>{data?.product.product_name}</h5>

                                  <p>
                                    <span>Price:</span>{" "}
                                    {currencyFomatter(data?.product.price)}
                                  </p>
                                  <div className="tb_qty_wrp my-3">
                                    <Button
                                      disabled={
                                        data.quantity <= 1 || updatingCartItem
                                      }
                                      onClick={() => handleQuantity(data, -1)}
                                      color="inherit"
                                    >
                                      <span className="material-symbols-rounded">
                                        expand_more
                                      </span>
                                    </Button>
                                    <div className="tb_qty">
                                      {data.quantity}
                                    </div>
                                    <Button
                                      color="inherit"
                                      disabled={updatingCartItem}
                                      onClick={() => handleQuantity(data, 1)}
                                    >
                                      <span className="material-symbols-rounded">
                                        expand_less
                                      </span>
                                    </Button>
                                  </div>
                                  <p>
                                    <span>Total Price:</span>{" "}
                                    <b>
                                      {currencyFomatter(
                                        data?.product.price * data?.quantity
                                      )}
                                    </b>
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </>
                      ))}
                </div>
                <div className="col-lg-5 ">
                  <div className="pdtActnInfos sticky-top">
                    <form
                      onSubmit={couponFormik.handleSubmit}
                      className="coupon_wrapper"
                    >
                      <CustomInput
                        label="Have an Coupon"
                        placeholder="Enter Coupon Here"
                        type="text"
                        name="coupon_code"
                        onChange={couponFormik.handleChange}
                        onBlur={couponFormik.handleBlur}
                        value={couponFormik.values.coupon_code}
                        formik={couponFormik}
                      />

                      <CustomButton
                        // disabled={isLoading}
                        label={couponLoading ? "Appling.." : "Apply"}
                        type="submit"
                      />
                    </form>

                    <div className="bg-light p-3 rounded mb-3">
                      <h5 className="">Order Payment Details</h5>
                      <hr />

                      <div className="d-flex align-items-center justify-content-between">
                        <p>Order Amount</p>
                        <p>
                          <b>{currencyFomatter(orderInfo.subTotal)}</b>
                        </p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between">
                        <p>
                          Discount
                          {formik.values.coupon_code && (
                            <>
                              <br />
                              <small className="text-muted">
                                Coupon - (
                                {formik.values.coupon_code?.coupon_code})
                              </small>
                            </>
                          )}
                        </p>
                        <p>
                          <b>-{currencyFomatter(orderInfo.discount)}</b>
                        </p>
                      </div>
                      {cart?.length > 0 && cart[0]?.cart?.is_vat_include && (
                        <>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>BTW (Excl):</p>
                            <p>
                              <b>{currencyFomatter(orderInfo.total)}</b>
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>BTW (Incl):</p>
                            <p>
                              <b>
                                {currencyFomatter(
                                  orderInfo.total + Number(vatSum)
                                )}
                              </b>
                            </p>
                          </div>
                        </>
                      )}
                      <hr />
                      <div className="d-flex align-items-center justify-content-between ">
                        <p>Order Total</p>
                        <p>
                          <b>
                            {currencyFomatter(
                              cart?.length > 0 && cart[0].cart.is_vat_include
                                ? orderInfo.total + Number(vatSum)
                                : orderInfo.total
                            )}
                          </b>
                        </p>
                      </div>
                    </div>

                    <div className="customInputWrpr">
                      <FormControl>
                        <FormLabel>Payment Method</FormLabel>
                        <RadioGroup
                          defaultValue={formik.values.payment_method}
                          name="payment_method"
                          onChange={formik.handleChange}
                          className="d-flex flex-row gap-3 flex-wrap"
                        >
                          <FormControlLabel
                            value="online"
                            control={
                              <Radio
                                disabled={
                                  formik.values.coupon_code?.percentage == 100
                                }
                                checked={
                                  formik.values.payment_method === "online"
                                }
                              />
                            }
                            label="Online"
                          />
                          <FormControlLabel
                            value="offline"
                            control={
                              <Radio
                                checked={
                                  formik.values.payment_method === "offline"
                                }
                              />
                            }
                            label="Offline"
                          />
                        </RadioGroup>
                      </FormControl>
                      {formik.touched?.payment_method &&
                      formik.errors?.payment_method ? (
                        <p className="errMsg">{formik.errors.payment_method}</p>
                      ) : (
                        ""
                      )}
                    </div>

                    {user?.account_type !== "sport_person" && (
                      <div className="customInputWrpr">
                        <FormControl>
                          <FormLabel>Shipping Method</FormLabel>
                          <RadioGroup
                            defaultValue={formik.values.separate_shipping}
                            name="separate_shipping"
                            onChange={formik.handleChange}
                            className="d-flex flex-row gap-3 flex-wrap"
                          >
                            <FormControlLabel
                              value={false}
                              control={<Radio />}
                              label="My Address"
                            />
                            <FormControlLabel
                              value={true}
                              control={<Radio />}
                              label="Client's Respective Address"
                            />
                          </RadioGroup>
                        </FormControl>
                        {formik.touched?.separate_shipping &&
                        formik.errors?.separate_shipping ? (
                          <p className="errMsg">
                            {formik.errors.separate_shipping}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    )}

                    <div
                      className={`shpInfo d-flex align-items-center justify-content-between gap-2 flex-wrap ${
                        user?.account_type !== "sport_person" &&
                        formik.values.separate_shipping == "true" &&
                        "d-none"
                      }`}
                    >
                      <h5 className="actnInfoTtle">Shipping Address</h5>
                      <Button
                        onClick={() =>
                          setAddressModal({
                            open: true,
                            data: null,
                            type: "new",
                          })
                        }
                      >
                        Add New Address
                      </Button>
                    </div>
                    <hr />
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row mt-4">
                        <div
                          className={`adrwLstWrpr mb-3 ${
                            user?.account_type !== "sport_person" &&
                            formik.values.separate_shipping == "true" &&
                            "d-none"
                          }`}
                        >
                          {address?.length > 0 &&
                            address?.map((data, index) => (
                              <div
                                className={`adrsSlct ${
                                  data?.is_default ? "dfltAdrs" : ""
                                }`}
                                key={index}
                              >
                                <input
                                  id={data.id}
                                  type="radio"
                                  hidden
                                  name="shipping"
                                  onChange={formik.handleChange}
                                  value={data.id}
                                  checked={
                                    Number(formik.values.shipping) ===
                                    Number(data.id)
                                  }
                                  onBlur={() => {
                                    formik.setFieldTouched("shipping", true);
                                  }}
                                />
                                <label for={data.id}>
                                  <h5 className="w-75">
                                    {data.first_name}&nbsp;{data.last_name}
                                  </h5>
                                  <p>
                                    <span>{data.address}</span>
                                    <br />
                                    <span>
                                      {data.city},&nbsp;{data.state},
                                      {data.country}
                                      &nbsp;
                                      {data.pincode}
                                    </span>
                                    <br />
                                    Ph: {"+" + data.phone}
                                  </p>
                                </label>
                              </div>
                            ))}
                          {formik.touched.shipping && formik.errors.shipping ? (
                            <p className="errMsgs">{formik.errors.shipping}</p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <CustomButton
                          disabled={orderLoadgin || bulkLoading}
                          label={
                            orderLoadgin || bulkLoading
                              ? "Redirecting to payment..."
                              : "Proceed to Pay"
                          }
                          type="submit"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center">
                {isLoading || isFetching ? (
                  <div className="w-100 d-flex justify-content-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="emptyCartWrapper">
                    <img src="/assets/svg/emptycart.svg" />
                    <p className="my-4">Oops! your cart seems to be empty!</p>
                    <Button
                      onClick={() =>
                        navigate(
                          user?.account_type === "sport_person"
                            ? "/products"
                            : "/products/purchased"
                        )
                      }
                      size="large"
                      className="brdrBtn"
                    >
                      Browse Products
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {addressModal.open && (
        <CustomDialog
          open={addressModal.open}
          handleClose={() => setAddressModal({ open: false, data: null })}
          maxWidth="md"
          className="blogDialog"
        >
          <AddAddress
            data={addressModal.data}
            type={addressModal.type}
            handleClose={() => setAddressModal({ open: false, data: null })}
          />
        </CustomDialog>
      )}
    </>
  );
};

export default BulkProductCart;
