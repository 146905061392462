import React, { useEffect } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import "./App.css";
import Router from "./Router";
import { Toaster } from "react-hot-toast";

const theme = createTheme({
  palette: {
    primary: {
      main: "#52a5b1",
    },
    secondary: {
      main: "#DEB45B",
    },
  },
  typography: {
    fontFamily: "var(--primFont)",
  },
});

function App() {
  // useEffect(() => {
  //   console.log("localStorage changed");
  // }, [localStorage]);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Toaster position="top-right" reverseOrder={false} />
        <Router />
      </ThemeProvider>
    </div>
  );
}

export default App;
