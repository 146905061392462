import React, { useCallback, useEffect, useState } from "react";

let molliePromise;

const useMollie = (effect, deps) => {
  const [mollie, setMollie] = useState();
  const mollieCb = useCallback((mollie) => effect(mollie), deps);

  const testmode = process.env.REACT_APP_PAYMENT_MODE;

  useEffect(() => {
    if (!molliePromise) {
      molliePromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://js.mollie.com/v1/mollie.js";
        script.addEventListener("load", () => {
          resolve(
            window.Mollie(process.env.REACT_APP_MOLLIE_PROFILE_ID, {
              locale: "en_US",
              testmode: testmode === "test" ? true : false,
            })
          );
        });
        document.body.appendChild(script);
      });
    }

    molliePromise.then((mollie) => setMollie(mollie));
  }, []);

  useEffect(() => {
    if (mollie) {
      mollieCb(mollie);
    }
  }, [mollie, mollieCb]);

  return { mollie };
};

export default useMollie;
