import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

function AccessRoles({ allowedRoles }) {
  const location = useLocation();

  const { user } = useSelector((state) => state.auth);

  return allowedRoles.find((role) => user?.account_type?.includes(role)) ? (
    <Outlet />
  ) : user !== null ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default AccessRoles;
