import { createSlice } from "@reduxjs/toolkit";
import { AuthApi } from "../../services/auth/authApi";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    ebooks_search: null,
    recipe_search: null,
    gutbiomics_report_search: null,
    innerhack_report_search: null,
    kpi_report_search: null,
    microbiome_report_search: null,
    tolerance_report_search: null,
    user_report_search: null,
  },

  reducers: {
    addSearchValue: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.values,
      };
    },
    removeSearchValue: (state, action) => {
      return {
        ...state,
        [action.payload.name]: null,
      };
    },
  },
});

export default searchSlice.reducer;

export const { addSearchValue, removeSearchValue } = searchSlice.actions;
