import React, { useState } from "react";
import "../../Dashboard/Dashboard.css";
import "swiper/css";
import { useNavigate, Link } from "react-router-dom";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { Avatar, Button } from "@mui/material";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../services/api/api";
import {
  useGetInnerHacksReportsQuery,
  useGetKpiReportReportsQuery,
  useGetLastInnerhackReportQuery,
  useGetLastKpiReportQuery,
} from "../../../services/reports/reportsApi";
import PdfViewer, {
  PdfViewerFSModal,
} from "../../../Components/Molecules/PdfViewer";
import { useGetProductsQuery } from "../../../services/products/ProductsApi";
import ProductCard from "../../Products/ProductCard";
import ViewProduct from "../../Products/ViewProduct";
import "../../Products/product.css";
import { useGetFollowKitsQuery } from "../../../services/followKits/followKits";
import useMediaQuery from "@mui/material/useMediaQuery";
import { urlBuilder, followStatus } from "../../../Utils/Utils";

const UserDashboard = () => {
  const navigate = useNavigate();
  const [previewKpiReport, setPreviewKpiReport] = useState(false);
  const [previewInnerhackReport, setPreviewInnerhackReport] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const mobmatches = useMediaQuery("(max-width:700px)");
  const [showProduct, setShowProduct] = useState({
    view: false,
    id: null,
  });

  const { data: followkit_report } = useGetFollowKitsQuery(user?.id, {
    refetchOnFocus: true,
  });

  const { data: products } = useGetProductsQuery(user?.id, {
    refetchOnFocus: true,
  });

  const { data: getLastInnerhackReport } = useGetLastInnerhackReportQuery(
    user?.id,
    {
      skip: !user?.id,
      refetchOnFocus: true,
    }
  );

  const { data: getLastKpiReport } = useGetLastKpiReportQuery(user?.id, {
    skip: !user?.id,
    refetchOnFocus: true,
  });

  const findPercentage = (data) => {
    let percent =
      ((followStatus.findIndex((ele) => ele.id == data.follow_status) + 1) /
        followStatus.length) *
      100;
    return percent;
  };
  return (
    <>
      <div className="mnDashboardView userDashboardView">
        <div className="row">
          <div className="col-md-6 initWrprFlx">
            <div className="topUsrDtls dshStCard d-flex justify-content-between mb-3">
              <div className="lftInfo d-flex  align-items-center p-2 flex-fill">
                <div className="mnuserImg me-3">
                  <Avatar
                    src={user?.profile_image && baseUrl + user?.profile_image}
                  />
                </div>
                <div className="txtVwIfo flex-fill">
                  <div className="d-flex align-items-center justify-content-between w-100 mb-2 flex-wrap gap-2">
                    <p className="mb-0">Welcome!</p>
                    <span>
                      <small>Client Id: {user?.ref_no}</small>
                    </span>
                  </div>
                  <Link to="/profile">
                    <h2 className="mb-0">{`${user?.first_name} ${user?.last_name}`}</h2>
                  </Link>
                </div>
              </div>
              <div className="rtInfo d-flex align-items-start">
                <div className="vwPssBtn">
                  <img src="/assets/images/passport.png" />
                </div>
              </div>
            </div>
            <div className="dshStCard kpiPdfWrpr">
              <div className="d-flex align-items-center justify-content-between">
                <h3>
                  <span className="">
                    <img src="/assets/svg/kpi.svg" className="me-2" />
                  </span>
                  KPI Report
                </h3>
                <CustomButton
                  label="View All"
                  onClick={() => navigate("/kpiReport")}
                />
              </div>
              <hr />
              <div className="pdfViewWrpr">
                <div className="overflow-auto h-100">
                  <PdfViewer
                    fileUrl={urlBuilder(getLastKpiReport?.report)}
                    reportOnly={true}
                  />
                  <PdfViewerFSModal
                    fileUrl={urlBuilder(getLastKpiReport?.report)}
                    title={getLastKpiReport?.report_name}
                    open={previewKpiReport}
                    handleClose={() => setPreviewKpiReport(false)}
                  />
                </div>
                {!!getLastKpiReport?.report && (
                  <Button
                    onClick={() => setPreviewKpiReport(true)}
                    className="vwFlsScrn"
                  >
                    <span className="material-symbols-rounded">fullscreen</span>
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="dshStCard">
              <div className="d-flex align-items-center justify-content-between">
                <h3>
                  <span className="">
                    <img src="/assets/svg/innerhacks.svg" className="me-2" />
                  </span>
                  Innerselfie Hacks
                </h3>
                <CustomButton
                  label="View All"
                  onClick={() => navigate("/innerhacks")}
                />
              </div>
              <hr />
              <div className="pdfViewWrpr">
                <div className="overflow-auto h-100">
                  <PdfViewer
                    fileUrl={urlBuilder(getLastInnerhackReport?.report)}
                    reportOnly={true}
                  />
                  <PdfViewerFSModal
                    fileUrl={urlBuilder(getLastInnerhackReport?.report)}
                    title={getLastInnerhackReport?.report_name}
                    open={previewInnerhackReport}
                    handleClose={() => setPreviewInnerhackReport(false)}
                  />
                </div>
                {!!getLastInnerhackReport?.report && (
                  <Button
                    onClick={() => setPreviewInnerhackReport(true)}
                    className="vwFlsScrn"
                  >
                    <span className="material-symbols-rounded">fullscreen</span>
                  </Button>
                )}
              </div>
            </div>
            <div className="dshStCard mt-3">
              <div className="d-flex align-items-center justify-content-between">
                <h3>
                  <span className="">
                    <img src="/assets/svg/followkits.svg" className="me-2" />
                  </span>
                  Follow Kits
                </h3>
                <CustomButton
                  label="Request New Kit"
                  onClick={() => navigate("/followKits")}
                />
              </div>
              <hr />
              <div className="sldrWrpr rqstKtSlider">
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  // spaceBetween={10}
                  // slidesPerView={3}
                  navigation
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    1600: {
                      slidesPerView: 3,
                      spaceBetween: 10,
                    },
                  }}
                >
                  {!!followkit_report?.length ? (
                    followkit_report.map((el, index) => (
                      <SwiperSlide key={index} virtualIndex={index}>
                        <div className="flwKitWrpr">
                          <div className="kitBlg">
                            <label>Kit Types</label>
                            <p>
                              {el.fkitorderitems.map((fdata, index) => {
                                return (
                                  <span key={index}>
                                    {fdata.followkit.kit_name}
                                    {index < el.fkitorderitems?.length - 1 &&
                                      ", "}
                                  </span>
                                );
                              })}
                            </p>
                          </div>
                          <div className="kitBlg">
                            <label>Requested Date</label>
                            <p>{el.date}</p>
                          </div>
                          <div
                            // className="kitBlg"
                            className={`kitStWrp ${
                              el.payment_status === "unpaid"
                                ? "ppnd"
                                : findPercentage(el) <= 25
                                ? "yellow"
                                : findPercentage(el) <= 50
                                ? "orange"
                                : findPercentage(el) <= 75
                                ? "lightGreen"
                                : "green"
                            }`}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <label>Status</label>
                              <label>
                                {el.payment_status === "unpaid"
                                  ? "Payment Pending"
                                  : followStatus[
                                      followStatus.findIndex(
                                        (ele) => ele.id == el.follow_status
                                      )
                                    ]?.name}
                              </label>
                            </div>
                            <div className="prgrsWrapper">
                              <div
                                className="prgrsBr"
                                style={{
                                  width:
                                    el.payment_status === "unpaid"
                                      ? 0
                                      : `${findPercentage(el)}% `,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))
                  ) : (
                    <h6 className="text-center text-muted">
                      No FollowKits Found
                    </h6>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="dshStCard mt-3">
              <div className="d-flex align-items-center justify-content-between">
                <h3>
                  <span className="">
                    <img src="/assets/svg/products.svg" className="me-2" />
                  </span>
                  Products
                </h3>
                {user?.country === "BE" ? (
                  ""
                ) : (
                  <CustomButton
                    label="View All"
                    onClick={() => navigate("/products")}
                  />
                )}
              </div>
              <hr />
              {user?.country !== "BE" && products?.length > 0 ? (
                <div className="sldrWrpr">
                  <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    // spaceBetween={10}
                    // slidesPerView={5}
                    navigation
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                      100: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                      },
                      800: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                      },
                      1200: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                      },
                      1500: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                      },
                      1900: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                      },
                    }}
                  >
                    <>
                      {!!products?.length &&
                        products.map((el, idx) => (
                          <SwiperSlide key={idx}>
                            <ProductCard
                              {...el}
                              setShowProduct={setShowProduct}
                            />
                          </SwiperSlide>
                        ))}
                    </>
                  </Swiper>
                </div>
              ) : (
                <h6 className="text-center text-muted">No Products Listed</h6>
              )}
            </div>
          </div>
        </div>
      </div>
      {showProduct?.view && (
        <ViewProduct
          showProduct={showProduct.view}
          setShowProduct={setShowProduct}
          id={showProduct.id}
        />
      )}
    </>
  );
};

export default UserDashboard;
