import React, { useEffect, useMemo, useState } from "react";
import "./SideNav.css";
import { NavLink } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {
  useGetProSportsPersonsQuery,
  useGetSportsPersonsQuery,
} from "../../../services/sportsPerson/sportsPersonApi";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNav = (props) => {
  const [open, setOpen] = React.useState(true);
  const [purchaseTab, setPurchaseTab] = React.useState(true);
  const [userType, setUserType] = useState(null);

  const { user } = useSelector((state) => state.auth);
  const matches = useMediaQuery("(max-width:1200px)");

  const handleDrawerOpen = () => {
    setOpen(true);
    props.setDrawerExpand(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    props.setDrawerExpand(false);
  };

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      let themeValue = JSON.parse(localStorage.getItem("theme"));
      document.documentElement.style.setProperty(
        "--primColor",
        themeValue.prim
      );
      document.documentElement.style.setProperty("--secColor", themeValue.sec);
      document.documentElement.style.setProperty("--primFont", themeValue.font);
    }
  }, []);

  const handleMenuClick = () => {
    setPurchaseTab(!purchaseTab);
  };

  useEffect(() => {
    if (window.location.pathname.includes("/puchase")) {
      setPurchaseTab(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (purchaseTab === true) {
      setOpen(true);
    }
  }, [purchaseTab]);

  useEffect(() => {
    let userValue = localStorage.getItem("utype");
    setUserType(userValue);
  }, []);

  useEffect(() => {
    if (props.drawerExpand == true) {
      setOpen(true);
    } else if (props.drawerExpand == false) {
      setOpen(false);
    }
  }, [props.drawerExpand]);

  const { data: tm_list } = useGetSportsPersonsQuery(
    { id: user?.id, type: "patient" },
    {
      skip: user?.account_type !== "team_manager" || !user,
      refetchOnFocus: true,
    }
  );

  const { data: professional_list } = useGetProSportsPersonsQuery(
    { id: user?.id, type: "patient" },
    {
      skip: user?.account_type !== "professional" || !user,
      refetchOnFocus: true,
    }
  );
  const patients_list = useMemo(() => {
    switch (user?.account_type) {
      case "team_manager":
        return tm_list;
      case "professional":
        return professional_list;
      default:
        break;
    }
  }, [user, professional_list, tm_list]);
  console.log(patients_list);

  const drawerValue = (
    <>
      <div className="sideNavHdr">
        <img
          className={`mnLogo ${open ? "" : "lgoSmll"}`}
          src="/assets/images/logo.png"
        />
        {!matches && (
          <IconButton
            className="toglNvBtn"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        )}
      </div>
      <Divider />
      {user?.account_type !== "sport_person" ? (
        <List className="pt-4">
          <ListItem key={"Dashboard"} disablePadding sx={{ display: "block" }}>
            <NavLink to="/" className="navLink">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/dashboard.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem
            key={"Sport Persons"}
            disablePadding
            sx={{ display: "block" }}
          >
            <NavLink className="navLink" to="/sportpersons">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/sport_persons.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Sport Persons"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>

          {user?.account_type === "doctor" ? (
            <ListItem key={"Patients"} disablePadding sx={{ display: "block" }}>
              <NavLink className="navLink" to="/patients">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img src="/assets/svg/patient.svg" />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Patients"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </NavLink>
            </ListItem>
          ) : (
            user?.account_type !== "doctor" &&
            patients_list?.length > 0 && (
              <ListItem
                key={"Patients"}
                disablePadding
                sx={{ display: "block" }}
              >
                <NavLink className="navLink" to="/patients">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <img src="/assets/svg/patient.svg" />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Patients"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </NavLink>
              </ListItem>
            )
          )}

          <ListItem key={"Followkits"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/followkits">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <span className="material-symbols-rounded">
                    medical_services
                  </span>
                </ListItemIcon>
                <ListItemText
                  primary={"Follow kits"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"products"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/products/purchased">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/products.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Products"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem
            key={"Invite People"}
            disablePadding
            sx={{ display: "block" }}
          >
            <NavLink className="navLink" to="/invite">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/invitegray.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Invite People"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem
            key={"Manager Profile"}
            disablePadding
            sx={{ display: "block" }}
          >
            <NavLink className="navLink" to="/profile">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <span className="material-symbols-rounded">
                    account_circle
                  </span>
                  {/* <img src="/assets/svg/hospital.svg" /> */}
                </ListItemIcon>
                <ListItemText
                  primary={"Profile"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"Grouping"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/grouping">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <span className="material-symbols-rounded">groups</span>
                </ListItemIcon>
                <ListItemText
                  primary={"Grouping"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      ) : (
        <List className="pt-4">
          <ListItem key={"Dashboard"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/dashboard.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Dashboard"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem
            key={"Gutbiomics Report"}
            disablePadding
            sx={{ display: "block" }}
          >
            <NavLink className="navLink" to="/gutbiomicsReport">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/gutbiomics.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Gutbiomics Report"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem
            key={"Microbiome Report"}
            disablePadding
            sx={{ display: "block" }}
          >
            <NavLink className="navLink" to="/microbiomeReport">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/microbiome.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Microbiome Report"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem
            key={"Tolerence Report"}
            disablePadding
            sx={{ display: "block" }}
          >
            <NavLink className="navLink" to="/tolerenceReport">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/tolerence.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Tolerence Report"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"KPI Report"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/kpiReport">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/kpi.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"KPI Report"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"Innerhacks"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/innerhacks">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/innerhacks.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Innerselfie Hacks"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem
            key={"Follow Kits"}
            disablePadding
            sx={{ display: "block" }}
          >
            <NavLink className="navLink" to="/followKits">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/followkits.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Follow Kits"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"Ebooks"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/ebooks_recipes">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/ebook.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"E-Books & Recipes"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"products"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/products">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/products.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Products"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"profile"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/profile">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/profile.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Profile"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem key={"reports"} disablePadding sx={{ display: "block" }}>
            <NavLink className="navLink" to="/reports">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <img src="/assets/svg/reports.svg" />
                </ListItemIcon>
                <ListItemText
                  primary={"Upload Report"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      )}
      <Divider />
      <List className="pt-4">
        <ListItem key={"disclaimer"} disablePadding sx={{ display: "block" }}>
          <NavLink className="navLink" to="/infoPages/disclaimer">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <span className="material-symbols-rounded">
                  data_info_alert
                </span>
              </ListItemIcon>
              <ListItemText
                primary={"Disclaimer"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </NavLink>
        </ListItem>
        <ListItem key={"privacy"} disablePadding sx={{ display: "block" }}>
          <NavLink className="navLink" to="/infoPages/privacy">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <span className="material-symbols-rounded">privacy_tip</span>
              </ListItemIcon>
              <ListItemText
                primary={"Privacy Policy"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </NavLink>
        </ListItem>
        <ListItem key={"terms"} disablePadding sx={{ display: "block" }}>
          <NavLink className="navLink" to="/infoPages/terms">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <span className="material-symbols-rounded">assignment</span>
              </ListItemIcon>
              <ListItemText
                primary={"Terms & Conditions"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </NavLink>
        </ListItem>
      </List>
    </>
  );

  return (
    <>
      {!matches ? (
        <div className="sideNavComp">
          <Drawer variant="permanent" open={open}>
            {drawerValue}
          </Drawer>
        </div>
      ) : (
        <SwipeableDrawer
          anchor={"left"}
          open={props.state["left"]}
          onClose={props.toggleDrawer("left", false)}
          onOpen={props.toggleDrawer("left", true)}
        >
          <div
            className="sideNavComp px-1"
            onClick={props.toggleDrawer("left", false)}
          >
            {drawerValue}
          </div>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default SideNav;
