import React, { useEffect, useMemo, useState } from "react";

import { useFormik } from "formik";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import { useSelector } from "react-redux";
import CustomCheckBox from "../../Components/Atoms/CustomCheckBox";
import { toast } from "react-hot-toast";
import { currencyFomatter } from "../../Utils/Utils";
import {
  useBulkFollowKitRequestMutation,
  useFollowKitRequestMutation,
  useGetFollowKitPricingsQuery,
} from "../../services/followKits/followKits";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import {
  useGetDocSportsPersonsQuery,
  useGetProSportsPersonsQuery,
  useGetSportsPersonsQuery,
} from "../../services/sportsPerson/sportsPersonApi";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import CustomButton from "../../Components/Atoms/CustomButton";

const FollowkitForm = ({ dialog, setDialog }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [vatSum, setVatSum] = useState(0);
  const [pricingDetails, setPRicingDetails] = useState({
    data: null,
    title: null,
    mount: false,
  });
  const [orderType, setOrderType] = useState("sport_person");

  const [followKitRequest, { isLoading }] = useFollowKitRequestMutation();

  const [bulkFollowKitRequest, { isLoading: bulkLoading }] =
    useBulkFollowKitRequestMutation();

  const { data: man_person_list } = useGetSportsPersonsQuery(
    { id: user?.id, type: orderType },
    {
      skip: user?.account_type !== "team_manager" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: pro_persons_list } = useGetProSportsPersonsQuery(
    { id: user?.id, type: orderType },
    {
      skip: user?.account_type !== "professional" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const { data: doc_person_list } = useGetDocSportsPersonsQuery(
    { id: user?.id, type: orderType },
    {
      skip: user?.account_type !== "doctor" || !user,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const sports_persons_list = useMemo(() => {
    switch (user?.account_type) {
      case "professional":
        return pro_persons_list;
      case "team_manager":
        return man_person_list;
      case "doctor":
        return doc_person_list;
      default:
        return [];
    }
  }, [user, orderType, doc_person_list, pro_persons_list, man_person_list]);

  const { data: reports } = useGetFollowKitPricingsQuery(undefined, {
    refetchOnFocus: true,
  });

  const orderValidation = Yup.object().shape({
    follow_kit: Yup.array().min(1, "Please select kit").required("Required"),
    pricing: Yup.array().min(1, "Please select reports").required("Required"),
  });

  const singleOrderFormik = useFormik({
    initialValues: {
      all: false,
      sport_person: null,
      follow_kit: [],
      pricing: [],
    },
    validationSchema: orderValidation,
    onSubmit: async (values, actions) => {
      if (!values.sport_person && user?.account_type !== "sport_person")
        return toast.error("Please select client to continue!");
      if (!values.follow_kit.length) return toast.error("Please add followkit");

      const requested_Date = new Date().toISOString().slice(0, 10);
      const requested_Kit = new Array();

      if (user?.account_type === "sport_person") {
        values.follow_kit.map((kit) => {
          return requested_Kit.push({
            kit_name: kit,
            date: requested_Date,
            sample_type: kit,
            pricing: values.pricing,
          });
        });
        try {
          const response = await followKitRequest({
            id:
              user?.account_type === "sport_person"
                ? user?.id
                : values.sport_person?.id,
            requested_Kit: requested_Kit,
          })
            .unwrap()
            .then((res) => {
              if (res) {
                // toast.success("Your order has been placed successfully.");
                handleCloseModal();
                navigate("/followKits/checkout");
              }
            });
          return response;
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong.");
          }
          console.log(error);
        }
      } else {
        const transformed_data = () => {
          const data = new Array();

          values.follow_kit.map((kit) => {
            return data.push({
              kit_name: kit,
              date: requested_Date,
              sample_type: kit,
              pricing: values.pricing,
              user: values.sport_person?.id,
              group: "",
            });
          });

          return data;
        };
        try {
          const response = await bulkFollowKitRequest({
            id: user?.id,
            requested_bulk_Kit: transformed_data(),
          })
            .unwrap()
            .then((res) => {
              if (res) {
                // toast.success("Your order has been placed successfully.");
                handleCloseModal();
                navigate("/followKits/checkout");
              }
            });
          return response;
        } catch (error) {
          if (error?.data?.detail) {
            toast.error(error.data.detail);
          } else {
            toast.error("Something went wrong.");
          }
          console.log(error);
        }
      }
    },
  });

  useEffect(() => {
    if (singleOrderFormik.values.all === true) {
      singleOrderFormik.values.follow_kit = [
        "Faeces",
        "Urine",
        "Saliva",
        "Sweat",
      ];
    } else {
      singleOrderFormik.values.follow_kit = [];
    }
  }, [singleOrderFormik.values.all]);

  const handleCloseModal = () => {
    setDialog(false);
    singleOrderFormik.resetForm();
  };

  const findPriceHandler = (values, reports) => {
    return values?.reduce((acc, curr, index) => {
      acc =
        parseInt(acc) +
        parseInt(reports?.[reports.findIndex((ele) => ele.id == curr)]?.price);
      return acc;
    }, 0);
  };

  const reportPrice = useMemo(
    () => findPriceHandler(singleOrderFormik.values.pricing, reports),
    [singleOrderFormik.values.pricing]
  );

  // useEffect(() => {
  //   if (dialog && reports?.length) {
  //     singleOrderFormik.setFieldValue(
  //       "pricing",
  //       reports.map((report) => report.id.toString())
  //     );
  //   }
  // }, [dialog]);

  useEffect(() => {
    if (!reports) return;
    const dat = singleOrderFormik.values.pricing
      .map((el) => reports.find((report) => report.id === Number(el)))
      .map((val) => (val.price * val.vat) / 100)
      .reduce((acc, curr) => acc + curr, 0);
    setVatSum(Math.round(dat));
  }, [reports, singleOrderFormik.values.pricing]);

  useEffect(() => {
    singleOrderFormik.setFieldValue("sport_person", null);
  }, [orderType]);

  return (
    <>
      <CustomDialog open={dialog} maxWidth="sm" handleClose={handleCloseModal}>
        <div className="text-center">
          <img src="/assets/svg/fk_icon.svg" className="mb-3" />
          <h3 className="mb-2">Order Follow Kits</h3>
          {user?.account_type !== "sport_person" && (
            <>
              <p className="">Order followkits for your team members</p>
            </>
          )}
        </div>
        <div
          className={`flwKtWrpr ${
            user?.account_type !== "sport_person" && "pt-0"
          }`}
        >
          <form className="" onSubmit={singleOrderFormik.handleSubmit}>
            <div className="row text-start">
              {user?.account_type !== "sport_person" && (
                <>
                  <div className="col-md-12">
                    <div className="customInputWrpr">
                      <FormControl className="text-center">
                        {/* <FormLabel>Choose Client Type</FormLabel> */}
                        <RadioGroup
                          value={orderType}
                          defaultValue="sport_person"
                          onChange={(e) => setOrderType(e.target.value)}
                          className="d-flex flex-row gap-3 flex-wrap justify-content-center"
                        >
                          <FormControlLabel
                            value="sport_person"
                            control={<Radio />}
                            checked={orderType === "sport_person"}
                            label="Sport Person"
                          />
                          {user?.account_type == "doctor" && (
                            <FormControlLabel
                              value="patient"
                              control={<Radio />}
                              checked={orderType === "patient"}
                              label="Patient"
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md-12 ">
                    <div className="customInputWrpr">
                      <Autocomplete
                        disablePortal
                        options={sports_persons_list ?? []}
                        getOptionLabel={(option) =>
                          `${option?.first_name} ${option?.last_name} - ${option.ref_no}`
                        }
                        value={singleOrderFormik.values.sport_person}
                        onChange={(_, newValue) => {
                          singleOrderFormik.setFieldValue(
                            "sport_person",
                            newValue ?? null
                          );
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                        renderInput={(params) => (
                          <TextField
                            name="sport_person"
                            {...params}
                            label="Enter Client Name or ID"
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-12">
                <label>Kit Type:</label>
                <div className="chkBxGrd">
                  <CustomCheckBox
                    name="all"
                    label="All"
                    onChange={singleOrderFormik.handleChange}
                    checked={singleOrderFormik.values.all === true}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Faeces"
                    value="Faeces"
                    onChange={singleOrderFormik.handleChange}
                    checked={singleOrderFormik.values.follow_kit.includes(
                      "Faeces"
                    )}
                    disabled={singleOrderFormik.values.all}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Urine"
                    value="Urine"
                    onChange={singleOrderFormik.handleChange}
                    checked={singleOrderFormik.values.follow_kit.includes(
                      "Urine"
                    )}
                    disabled={singleOrderFormik.values.all}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Saliva"
                    value="Saliva"
                    onChange={singleOrderFormik.handleChange}
                    checked={singleOrderFormik.values.follow_kit.includes(
                      "Saliva"
                    )}
                    disabled={singleOrderFormik.values.all}
                  />
                  <CustomCheckBox
                    name="follow_kit"
                    label="Sweat"
                    value="Sweat"
                    onChange={singleOrderFormik.handleChange}
                    checked={singleOrderFormik.values.follow_kit.includes(
                      "Sweat"
                    )}
                    disabled={singleOrderFormik.values.all}
                  />
                </div>
                {singleOrderFormik.touched["follow_kit"] &&
                singleOrderFormik.errors["follow_kit"] ? (
                  <p className="mb-0 text-danger mt-2">
                    {singleOrderFormik.errors["follow_kit"]}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12 mt-4">
                <label>Reports:</label>
                <div className="rprtsWrpr">
                  {!!reports?.length &&
                    reports?.map((data, index) => (
                      <div className="d-flex align-items-center">
                        <CustomCheckBox
                          key={index}
                          name="pricing"
                          containerClasses="flex-fill w-75"
                          label={
                            <div className="d-flex justify-content-between">
                              <span
                                className="rprtNae"
                                style={{
                                  wordBreak: "break-word",
                                }}
                              >
                                {data.report_name}
                              </span>
                            </div>
                          }
                          value={data.id}
                          checked={
                            singleOrderFormik.values.pricing.find(
                              (el) => el == data.id
                            )
                              ? true
                              : false
                          }
                          onChange={singleOrderFormik.handleChange}
                        />
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: "120px" }}
                        >
                          <span
                            className="material-symbols-rounded cursorPointer"
                            onClick={() =>
                              setPRicingDetails({
                                data: data.report_details,
                                title: data.report_name,
                                mount: true,
                              })
                            }
                          >
                            info
                          </span>
                          <span className="rprtPricce">
                            {currencyFomatter(data.price)}
                          </span>
                        </div>
                      </div>
                    ))}
                  {/* <div className="d-flex align-items-center justify-content-between mt-2">
                    <p className="mb-0">Order Amount:</p>
                    <p className="mb-0">{currencyFomatter(reportPrice)}</p>
                  </div> */}
                  {/* <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="mb-0">BTW (Excl):</p>
                    <p className="mb-0">{currencyFomatter(reportPrice)}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="mb-0">BTW (Incl):</p>
                    <p className="mb-0">{currencyFomatter(reportPrice + Number(vatSum))}</p>
                  </div> */}
                  <div className="ttlPrice d-flex align-items-center justify-content-between">
                    <p className="mb-0">Total Price:</p>
                    <p className="mb-0 ttlPrVl">
                      {/* {currencyFomatter(reportPrice + Number(vatSum))} */}
                      {currencyFomatter(reportPrice)}
                    </p>
                  </div>
                </div>
                {singleOrderFormik.touched["pricing"] &&
                singleOrderFormik.errors["pricing"] ? (
                  <p className="mb-0 text-danger mt-2">
                    {singleOrderFormik.errors["pricing"]}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-12 text-end mt-4">
                <CustomButton
                  label={isLoading ? "Order Kit..." : "Order Kit"}
                  type="submit"
                  disabled={isLoading}
                />
              </div>
            </div>
          </form>
        </div>
      </CustomDialog>

      <Drawer
        anchor={"right"}
        open={pricingDetails.mount}
        onClose={() =>
          setPRicingDetails({
            data: null,
            title: null,
            mount: false,
          })
        }
        className="product_view_modal followReportDetails"
      >
        <div className="p-5 flwRptInrWrprDt">
          <IconButton
            className="clsIcnBtn"
            onClick={() =>
              setPRicingDetails({
                data: null,
                title: null,
                mount: false,
              })
            }
          >
            <span className="material-symbols-rounded">clear</span>
          </IconButton>
          <h4>{pricingDetails.title}</h4>
          <div dangerouslySetInnerHTML={{ __html: pricingDetails.data }}></div>
        </div>
      </Drawer>
    </>
  );
};

export default FollowkitForm;
